.main-loading-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.font-family-prompt {
  font-family: "Prompt", sans-serif !important;
}

.font-family-maven {
  font-family: "Maven Pro" !important;
}

.char-count-wrap {
  position: relative;
}
.char-count {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 8px;
}

/* chips */

.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.chipsInput {
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  border-radius: 0.2rem;
  -webkit-appearance: none;
}

.chipsInput:focus {
  outline: none;
}

.chipsInput.chips-has-error {
  border-color: tomato;
}

.chips-error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.chip-tag-item {
  background-color: #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 1rem 0.3rem 0.3rem 0;
}

.chip-tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-inline-align {
  display: flex;
  margin-left: 24px;
}

.switch-animation-disable > div.react-switch-handle {
  transition: background-color 0s ease 0s, transform 0s ease 0s, box-shadow 0s ease 0s !important;
}
.switch-animation-disable > div.react-switch-bg > div:first-child,
.switch-animation-disable > div.react-switch-bg > div:nth-child(2) {
  transition: background-color 0s ease 0s, transform 0s ease 0s, box-shadow 0s ease 0s !important;
}

.icon-toggle::before {
  display: inline-block;
  font-family: feather;
  content: "\e120";
}

.transition-all-1 {
  transition: all 0.1s;
}
