.search-input-group {
  display: table;
  border-collapse: separate;
  width: 100%;
  border: 1px solid #e1e7ec;
  border-radius: 22px;
  background-color: #ffffff;
}
.search-input-group > div {
  display: table-cell;
  vertical-align: middle; /* needed for Safari */
}
.search-input-group-icon {
  color: #bbb;
  padding-left: 14px;
  padding-right: 8px;
  /* border: 1px solid red; */
}
.search-input-group-area {
  width: 100%;
}
.search-input-group input {
  display: block;
  width: 100%;
  padding: 8px;
  border: 0;
  border-radius: 22px;
}

.search-input-group input::placeholder {
  color: #bbb;
  opacity: 1; /* Firefox */
}

.search-input-group input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbb;
}

.search-input-group input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbb;
}

.search-input-group input:focus {
  outline: none;
}
