.members-mailling-lists-container {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 100px;

        .button-create-importCSV {
            display: flex;
            gap: 20px;
        }
}

.input-upload-csv {
    border: 1.5px solid #dfdfdf;
    outline: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-weight: 600;  
    color: rgb(88, 88, 88);
}

input::file-selector-button {
    visibility: hidden;
    color: white;
    background-color: #32cd80;
    padding: 9px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;

    &:active {
        background-color: #1f8e56;
        transition: all .3s ease;
    }
}

.container-upload-csv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    
        .upload-csv {
            width: 100%;
            height: 100%;
            position: absolute;
            color: transparent;
        }
    
        .text-upload-csv {
            text-align: center;
            font-weight: 600;  
            color: rgb(148, 148, 148);
            margin-bottom: 10px;
        }
        
    }
    
    .button-upload-csv {
        color: white;
        background-color: #1890ff;
        padding: 7px 15px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 600;
        transition: all .3s ease-in-out;
        box-shadow: 0px 10px 20px #007bff5e;
        // box-shadow: 0px 10px 20px rgba(159, 255, 212, 0.51);
    
        &:hover {
            background-color: #007bff;
            // background-color: #1f8e56;
            transition: all .3s ease-in-out;
            // box-shadow: 0px 10px 20px #007bff5e;
        }

    }

    .name-csv {
        margin-top: 20px;
        font-weight: 600; 
        color: rgb(88, 88, 88); 
    }
}

.button-prev-next {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}