/* ##################### Product List ##################### */
.prod-grid {
  display: grid;
  gap: 0.75rem 0.75rem;
  justify-items: center;
}

.product-sort-wrapper {
  border-radius: 8px;
  cursor: grab;
}

@media (min-width: 320px) {
  .prod-grid-responsive {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 640px) {
  .prod-grid-responsive {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) {
  .prod-grid-responsive {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1280px) {
  .prod-grid-responsive {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1600px) {
  .prod-grid-responsive {
    grid-template-columns: repeat(5, 1fr);
  }
}

.prod-cover {
  position: relative;
}

.prod-card {
  width: 300px;
  height: 520px;
  padding: 0;
  transition: 0.5s all;
  position: relative;
}

.prod-card-image-cover {
  width: 100%;
  height: 160px;
  padding: 16px;
}

.prod-card-image {
  max-height: 128px;
  height: auto;
  width: auto;
}

.prod-card-feature {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.prod-card-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.prod-card-table-detail>thead>tr>th {
  text-align: center;
  border-top: 2px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.prod-card-table-detail>thead:before {
  line-height: 1em;
  content: ".";
  color: white;
  display: block;
}

.prod-card-table-detail>tbody>tr>td {
  text-align: center;
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
}

.prod-card-table-price {
  table-layout: fixed;
}

.prod-card-table-price .label-cell {
  width: 22%;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
}

.prod-card-table-price .price-cell {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
}

.prod-card-select>label::before,
.prod-card-select>label::after {
  cursor: pointer;
  width: 36px;
  height: 36px;
}

.prod-buffer-card-select>label::before,
.prod-buffer-card-select>label::after {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.prod-menu-btn>div {
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #606975 !important;
}

.prod-menu-btn-sm>div {
  font-size: 14px;
}

.prod-menu-btn-selected-border-bt {
  border-bottom: 2px solid #0da9ef;
}

.prod-menu-btn-selected-color {
  color: #0da9ef;
}

.prod-card-btn-info {
  border: 1px solid #dee2e6;
  border-radius: 22px;
  width: 90px;
  height: 32px;
  margin-top: 0px;
}

.prod-card-btn-info>.img-border {
  border-right-color: #dee2e6;
  border-right-style: solid;
  border-right-width: 1px;
}

.prod-card-btn-info>div>img {
  width: 16px;
  height: auto;
  margin-left: 8px;
  margin-right: 5px;
  margin-top: 5px;
}

.prod-card-btn-info:hover {
  -webkit-filter: brightness(75%);
  filter: brightness(75%);
}

/* ##################### Product Edit ##################### */
@media screen and (max-width: 1449px) {
  .productedit-scroll {
    overflow: auto;
  }

  .productedit-panel {
    width: 1449px;
  }
}

/* ##################### Group Selection ##################### */

.list-group-item {
  border-radius: 0px !important;
  padding: 0;
  border: 1px solid #eee !important;
  cursor: pointer;
}

.list-group-item:hover::before {
  position: absolute;
  top: 0;
  left: -1px;
  width: 2px;
  height: 100%;
  background-color: #0da9ef;
  content: "";
}

.list-group-item:hover::after {
  position: absolute;
  top: 0;
  right: -1px;
  width: 2px;
  height: 100%;
  background-color: #0da9ef;
  content: "";
}

.prod-selc-list {
  height: 48vh;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.prod-selc-btn-clear {
  position: relative;
  left: -8px;
  font-size: 16px;
}

.prod-selc-empty-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-overflow: clip;
  text-align: center;
  width: 400px;
  font-size: 16px;
}

.prod-selc-listitem-image {
  width: 100px;
  height: 80px;
}

.prod-selc-listitem-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* ##################### Sort Categories ##################### */
.sort-grid-category {
  display: grid;
  gap: 10px 10px;
  justify-items: center;
}

.sort-grid-product {
  display: grid;
  gap: 10px 10px;
  justify-items: center;
}

@media (min-width: 312px) {
  .sort-grid-category-responsive {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 624px) {
  .sort-grid-category-responsive {
    grid-template-columns: repeat(2, 1fr);
  }

  .sort-grid-product-responsive {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 936px) {
  .sort-grid-category-responsive {
    grid-template-columns: repeat(3, 1fr);
  }

  .sort-grid-product-responsive {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1248px) {
  .sort-grid-category-responsive {
    grid-template-columns: repeat(4, 1fr);
  }

  .sort-grid-product-responsive {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* ##################### Product Sorting ##################### */

@media screen and (max-width: 420px) {
  .product-sorting-scroll {
    overflow: auto;
  }

  .product-sorting-panel {
    width: 420px;
  }
}

.prod-card-1-cover {
  position: relative;
  top: 0;
  left: 0;
  padding: 0;
  background-color: #fff;
  width: 420px;
  height: 100px;
}

.prod-card-1-container,
.prod-card-1-container-hot-hover {
  border-radius: 8px;
  border: 1px solid #e1e7ec;
}

.prod-card-1-container:hover,
.prod-card-1-container:focus-within {
  border: 2px solid #e8c629 !important;
}

.sort-item-card,
.sort-item-card-not-hover {
  position: relative;
  top: 0;
  left: 0;
  padding: 0;
  background-color: #fff;
  width: 420px;
  height: 100px;
}

.sort-item-card>div {
  border-radius: 8px;
  border: 1px solid #e1e7ec;
}

.sort-item-card>div:hover {
  border: 2px solid #e8c629 !important;
}

.sort-item-card>div:focus-within {
  border: 2px solid #e8c629 !important;
}

.sort-item-card-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.sort-item-card-panel-top-right {
  position: absolute;
  top: 0px;
  right: 1px;
}

.sort-item-card-panel-bottom-right {
  position: absolute;
  bottom: 0px;
  right: 4px;
}

.sort-item-card-btn-badge>i {
  cursor: pointer;
  color: #aaaaaa;
}

.sort-item-card-btn-badge>i:hover {
  -webkit-filter: brightness(60%);
  filter: brightness(60%);
}

.sort-item-card-drag:hover {
  cursor: grab;
}

.dropdown-goto-item {
  border-radius: 0px !important;
  padding: 0;
  border: 1px solid #fff !important;
  cursor: pointer;
}

.prod-btn-set-status-feature {
  background-color: #e8c629;
}

.prod-btn-set-status-feature:hover {
  background-color: #d0ae16;
}

.prod-btn-set-status-feature:active,
.prod-btn-set-status-feature:focus {
  background-color: #b99a13 !important;
  border-color: #b99a13 !important;
}

.prod-cate-card {
  width: 260px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 7px;
  background-color: white;
}

.prod-tag-card {
  width: 520px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 7px;
  background-color: white;
}

/* 
.flag-corner-system-tag-bg {
  border-bottom: 34px solid #D0D0CF;
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
  height: 0;
  width: 130px;
  position: absolute;
  right: -42px;
  bottom: 6px;
  transform: rotate(-225deg);
}

.flag-corner-system-tag-label{
  position: absolute;
  color: #6c757d;
  transform: rotate(-45deg);
  font-weight: bold;
  right: -1px;
  bottom: 12px;
} */

.prod-card-selectable {
  border: 1px solid transparent !important;
  border-bottom: 1px solid rgb(222, 226, 230) !important;
  transition: .2s all;
  cursor: pointer;
}

.prod-card-selectable:hover {
  border: 1px solid #0da9ef !important;
}

.prod-tootlip-container {
  width: 280px;
  min-height: 100px;
  border-radius: 7px;
}

@media (min-width: 840px) {
  .prod-tootlip-container {
    width: 30vw;
  }
}

.prod-tootlip-container>div>h1,
.prod-tootlip-container>div>h2,
.prod-tootlip-container>div>h3,
.prod-tootlip-container>div>h4,
.prod-tootlip-container>div>h5,
.prod-tootlip-container>div>h6,
.prod-tootlip-container>div>p {
  margin: 0;
  margin-bottom: 5px;
}

.prod-tootlip-container>div>h1 {
  font-size: 2em;
}

.prod-tootlip-container>div>h2 {
  font-size: 1.5em;
}

.prod-tootlip-container>div>h3 {
  font-size: 1.17em;
}

.prod-tootlip-container>div>h4 {
  font-size: 1em;
}

.prod-tootlip-container>div>h5 {
  font-size: 0.83em;
}

.prod-tootlip-container>div>h6 {
  font-size: 0.67em;
}

.prod-children-form-selector>.ant-select-selector {
  height: 50px !important;
  border-radius: 8px !important;
}

.prod-radio-buttons>label.ant-radio-button-wrapper-checked {
  background: #0da9ef !important;
}

.prod-radio-buttons>label:first-child {
  border-radius: 20px 0px 0px 20px;
}

.prod-radio-buttons>label:last-child {
  border-radius: 0px 20px 20px 0px;
}

.prod-radio-buttons > label > span {
  line-height: 40px;
}