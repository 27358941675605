.ql-toolbar {
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-color: #fff;
}

.ql-container {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
}

.ql-editor {
  min-height: 200px;
  font-family: "Prompt", sans-serif !important;
}


.ql-editor > h1,
.ql-editor > h2,
.ql-editor > h3,
.ql-editor > h4,
.ql-editor > h5,
.ql-editor > h6,
.ql-editor > p {
  margin-bottom: 5px;
}

.ql-insertBullet:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  transform: translate(0, 0);

  content: "▶";
}
