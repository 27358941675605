.ck-toolbar{
    border-top-left-radius: 22px !important;
    border-top-right-radius: 22px !important;
    background-color: #fff !important;
}

.ck-editor__editable{
    border-bottom-left-radius: 22px !important;
    border-bottom-right-radius: 22px !important;
}
    