.pdpa-card {
    border: 1px solid rgb(184, 184, 184);
    // width: 150px;
    // margin: 0px 8px 16px 8px;
    // // border: 1px solid #e1e7ec;
    // border-radius: 8px;
}

.pdpa {
    .ant-btn-upload-excel {
        background-color:rgb(236, 165, 43) ;
        color: white;
    }
    .ant-btn-upload-excel:disabled {
        background-color: rgb(245, 245, 245);
        color: rgb(184, 184, 184);
    }
}
.boxBAID{
    border: 1px solid rgb(184, 184, 184);
    padding: 5px;
    margin: 5px;
    text-align: center;
    width: 150px;
    cursor: pointer;
    color: rgb(66, 139, 202);
}

.baIdContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
.baIdBox {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.baIdBox:hover {
    background-color: #dcdcdc;
}

.custom-file-upload {
    display: inline-block;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    background-color: #e1e6ea;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    margin-right: 10px;
  }
  .custom-file-upload:hover {
    background-color: #e1e6ea;
  }
  
  .custom-file-upload input[type="file"] {
    display: none; // Hide the file input
  }
  