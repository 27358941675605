
@media screen and (max-width: 1140px) {
    .ps1-settings-scroll {
      overflow: auto;
    }
  
    .ps1-settings-panel {
      width: 1140px;
    }
  }
  