.c-able-selc__control {
  height: 44px;
  border-radius: 22px !important;
}

.c-able-selc__control--is-focused {
  border-width: 1px !important;
  border-color: #0da9ef !important;
  box-shadow: 0 0 0 1px #0da9ef !important;
}

.c-able-selc__value-container {
  margin-left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-notable-selc__control {
  height: 44px;
  border-radius: 22px !important;
}

.c-notable-selc__value-container {
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}