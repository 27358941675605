.vtc-dd-panel-dropdown {
  position: absolute;
  top: 17px;
  opacity: 0;
  max-height: 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.vtc-dd-panel:hover > .vtc-dd-panel-dropdown {
  top: 6.75rem;
  opacity: 1;
  max-height: 200px;
}
