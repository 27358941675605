#popover-contained {
  max-width: 400px;
  width: 400px;
}

#popover-contained > div[class="arrow"] {
  display: none;
}

.input-text-dropdown {
  color: #606975;
  font-size: 16px;
  font-weight: lighter;
}

.input-text-dropdown-button {
  border: 0;
  cursor: default;
}

.input-text-dropdown-button > span[class="label"] {
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.input-text-dropdown-button > span[class="label"]:hover {
  border-bottom: 2px solid #606975;
}

.input-text-dropdown-button > span[class="value"] {
  border-bottom: 2px solid #0da9ef;
  color: #0da9ef;
}

.input-text-dropdown-contain {
  width: 100%;
}

.input-text-dropdown-textbox {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px;
}

.input-text-dropdown-textbox:focus {
  border: 1px solid #dbe2e8;
  border-right: 0px;
}

.input-text-dropdown-btn-apply,
.input-text-dropdown-btn-cancel {
  padding: 0px;
  border: 1px solid #dbe2e8;
  border-left: 0px;
}

.input-text-dropdown-btn-apply {
  border-radius: 0px !important;
  color: #0da9ef;
  border-right: 0px;
}

.input-text-dropdown-btn-cancel {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
