/* QRShipment.css */

.table-container {
    margin-top: 0px; /* Add space above the table */
    border-radius: 8px; /* Round corners */
    overflow: hidden; /* Prevent overflow */
    font-size: 20px; /* Set font size */
  }
  
  .table-header {
    background-color: #E9F1FA; /* Light grey background for header */
    text-align: center; /* Center-align text */
  }
  .table-header-history {
    background-color: #FFF1DC; /* Light grey background for header */
    text-align: center; /* Center-align text */
  }

  .table-row td:last-child {
    border-bottom: none; /* Remove bottom border for the last row */

  }
  
  .table-cell {
    width: 33.33%; /* Each column takes up one-third of the width */
    padding: 16px; /* Optional padding */
    text-align: center; /* Center-align text */
    font-size: 12px !important; /* Set font size */
  }

  .table-cell-head {
    width: 33.33%; /* Each column takes up one-third of the width */
    padding: 16px; /* Optional padding */
    text-align: center !important; /* Center-align text */
    font-size: 12px !important; /* Set font size */
    text-wrap: nowrap;
  }

  .table-cell-center {
    width: 33.33%; /* Each column takes up one-third of the width */
    padding: 16px; /* Optional padding */
    text-align: center !important; /* Center-align text */
    font-size: 12px !important; /* Set font size */
  }

  .table-cell-history {
    width: 25%; /* Each column takes up one-third of the width */
    padding: 16px; /* Optional padding */
    text-align: center; /* Center-align text */
    font-size: 12px !important; /* Set font size */
    text-wrap: nowrap;
    color: #003B6F !important;
  }

  .table-cell-head-history {
    width: 25%; /* Each column takes up one-third of the width */
    padding: 16px; /* Optional padding */
    text-align: center !important; /* Center-align text */
    font-size: 12px !important; /* Set font size */
    text-wrap: nowrap;
    color: #003B6F !important;
  }

  .table-cell-center-history {
    width: 25%; /* Each column takes up one-third of the width */
    padding: 16px; /* Optional padding */
    text-align: center !important; /* Center-align text */
    font-size: 12px !important; /* Set font size */
  }
  
  .table-row:hover {
    /* background-color: #e0f7fa;  */
    /* Change background on hover */
  }
  
  .table-row:nth-of-type(even) {
    /* background-color: #fafafa;  */
    /* Alternate row color */
  }

  .qrShipment .MuiFormHelperText-root.Mui-error {
    color: red;
    font-size: 12px;
    text-align: center;
  }

  .qrShipment-table-pagination .MuiTablePagination-selectLabel{
    font-size: 12px;
    margin: 0px;
  }

  .qrShipment-table-pagination .MuiTablePagination-displayedRows{
    font-size: 12px;
    margin: 0px;
  }
  
  .qrShipAlink{
    color: #003B6F;
    text-decoration: none;
  }