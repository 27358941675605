.dl-file-backdrop {
  background-color: rgba(51, 51, 51);
}

.dl-file-checkbox-label::after,
.dl-file-checkbox-label::before {
  top: 4px;
}

.dl-file-dialog-box-topic {
  border-bottom: 1px solid #dee2e6;
}

.dl-file-card {
  width: 390px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 7px;
  background-color: white;
}

.dl-file-card-box-content {
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 7px 0px 0px 7px;
  font-family: Prompt;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.dl-file-card-box-ctrl {
  background-color: #eef0f2;
  color: #384250;
}

.dl-file-icon-download:hover {
  cursor: pointer;
  -webkit-filter: brightness(180%);
  filter: brightness(180%);
}

.dl-file-icon-drag:hover {
  cursor: grab;
}

.dl-file-card-icon-select > label::before,
.dl-file-card-icon-select > label::after {
  cursor: pointer;
  width: 36px;
  height: 36px;
  top: -7px;
  left: -7px;
}

.dl-file-card-icon-select > label::before {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}
