.app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    width: 90%;
    margin: 0 auto;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;

        h2 {
            margin: 0;
            padding: 0;
        }
}

.header-mailing-list-group-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;

        h2 {
            margin: 0;
            padding: 0;
        }
}

