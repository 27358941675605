.wrapper-mobi {
    .mbsc-input {
        input.mbsc-control {
            height: 35px;
            padding: 0 18px 3px;
            border: 1px solid #dbe2e8;
            border-radius: 5px;
            color: #606975;
            text-align: center;
        }
    }
    .mbsc-ios.mbsc-control-w:before,
    .mbsc-ios.mbsc-control-w:after {
        border-top: none;
    }
    .mbsc-ios .mbsc-select-ic {
        top: 0.575em
    }

    .mbsc-ios.mbsc-input input:disabled,
    .mbsc-ios.mbsc-input textarea:disabled,
    .mbsc-ios.mbsc-input .mbsc-control:disabled ~ input,
    .mbsc-ios.mbsc-input .mbsc-control:disabled ~ .mbsc-ic {
        color: #9ea9b9;
        background-color: #f5f5f5;
        border: 1px solid #e3e8ed;
        opacity: 1;
    }
    .mbsc-ios.mbsc-ltr.mbsc-ic-right .mbsc-input-wrap{
        padding-right: 0;
    }

}

.upload-book-bank-enroll-indo {
    .mbsc-sc-itm-ml {
        font-size: 18px !important;
    }
}
.next-run-date-input-autoship {
    .mbsc-ios.mbsc-input {
        border-radius: 7px;
    }
}

.section-form.delivery {
    .mbsc-textarea {
        border: 1px solid #e3e8ed !important;
        border-radius: 7px;
        font-size: 16px;
        padding: 1em;
        background-color: #f5f5f5 !important;
        &:before,&:after{
            border: 0;
        }
        .mbsc-input-wrap {
            display: none;
        }
        .mbsc-label{
            overflow: visible;
            white-space: normal;
            line-height: normal;
            display: contents;
        }
    }
}

.disabled-handmake {
    .mbsc-ios.mbsc-ltr.mbsc-select input{
        background-color: rgb(245,245,245);
        cursor: not-allowed;
    }
}
