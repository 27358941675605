.start-flex-end {
  display: flex;
  justify-content: flex-end;
}
.enroll-table-data {
  .ant-table-cell {
    text-align: center;
  }
}

.table-show-detail-enroll {
  td {
    text-align: center;
  }
}

.date-picker-input {
  input {
    border-radius: 7px;
    height: 45px;
    border-color: rgb(219, 226, 232);
    border-style: solid;
  }
}

.modal-date-picker-today {
  text-align: center;
}

.modal-date-picker-clear {
  color: red !important;
  left: 25%;
  position: absolute;
}

.button-upload-size {
  button {
    width: 220px;
    border-radius: 7px !important;
  }
}

.modal-footer-upload-image {
  
  .btn-primary.disabled,
  .btn-primary:disabled {
    background-color: #ccc;
    border-color: #ccc;
  }

  .btn-success.disabled,
  .btn-success:disabled {
    background-color: #ccc;
    border-color: #ccc;
  }
  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
  }
}
.save-btn-bank {
  .btn-primary.disabled,
  .btn-primary:disabled {
    background-color: #ccc;
    border-color: #ccc;
  }
}

.block-detail-img:first-child {
  justify-content: center;
}

.block-detail-img {
  
  width: 35%;
  display: flex;
  align-items: center;
  .img-detail {
    display: flex;
    border-radius: 7px;
    box-shadow: 0px 0px 0px 2px #fff;
    filter: drop-shadow(0px 0px 2px rgba(68, 68, 78, 0.6));
  }
  .ant-image-img {
    border-radius: 7px;
  }
  .ant-image-mask {
    border-radius: 7px;
  }
  @media (min-width: 1200px) {
    width: 20%;
  }
}

.modal-detail-enroll {
  .ant-modal-content {
    border-radius: 7px;
  }

  .ant-modal-header {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
}

.card-action-approved-reject {
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc;
  }
}
