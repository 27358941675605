.sortablegrid-items-sm,
.sortablegrid-items-md,
.sortablegrid-items-lg,
.sortablegrid-items-xl {
  display: grid;
  gap: 0px 0px;
  justify-items: center;
}

@media (min-width: 576px) {
  .sortablegrid-items-sm,
  .sortablegrid-items-md,
  .sortablegrid-items-lg,
  .sortablegrid-items-xl {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 768px) {
  .sortablegrid-items-md,
  .sortablegrid-items-lg,
  .sortablegrid-items-xl {
    grid-template-columns: repeat(1, 1fr);
  }
  .sortablegrid-items-sm {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .sortablegrid-items-lg,
  .sortablegrid-items-xl {
    grid-template-columns: repeat(1, 1fr);
  }
  .sortablegrid-items-md {
    grid-template-columns: repeat(2, 1fr);
  }
  .sortablegrid-items-sm {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .sortablegrid-items-xl {
    grid-template-columns: repeat(1, 1fr);
  }
  .sortablegrid-items-lg {
    grid-template-columns: repeat(2, 1fr);
  }
  .sortablegrid-items-md {
    grid-template-columns: repeat(3, 1fr);
  }
  .sortablegrid-items-sm {
    grid-template-columns: repeat(4, 1fr);
  }
}
