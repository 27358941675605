.input-tags-container {
    display: flex;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    /* padding-left: 14px; */

    padding: 0 18px 3px;
    border: 1px solid #dbe2e8;
    border-radius: 22px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.input-tags-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.input-tags-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.input-tags-container input {
    width: 100%;
    min-width: 50%;
    border: none;
    border-radius: 5px;
    padding: 12px;
    padding-left: 6px;
    outline: none;
    overflow-x: hidden;
    font-size: 14px;
    color: #606975;
}

.input-tags-container .tag {
    display: flex;
    align-items: center;
    margin: 10px 0 7px 0;
    margin-right: 10px;
    padding: 0 10px;
    padding-right: 5px;
    border: 1px solid #A2D2FF;
    border-radius: 5px;
    background-color: #A2D2FF;
    white-space: nowrap;
    color: white !important;
}

.input-tags-container .tag button {
    display: flex;
    padding: 6px;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: white;
}