.tag-picker-cover {
    border: 1px solid #dbe2e8;
    border-radius: 22px;
}

.tag-picker {
    border-radius: 22px;
}

.tag-picker > .ant-select-selector {
    border: 0 !important;
    background-color: transparent !important;
    padding: 3px 3px 3px 10px !important;
}

.tag-picker > .ant-select-selector > .ant-select-selection-item {
    border-radius: 22px;
}

.tag-picker-btn-clear {
    margin: 0;
    border-radius: 0px 22px 22px 0px !important;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    border-left: 1px solid #dbe2e8;
    cursor: pointer;
    color: #606975;
    font-size: 12px;
    font-weight: 500;
}

.tag-picker-btn-clear:hover {
    border-color: #0da9ef !important;
    background-color: #0da9ef !important;
    color: #fff !important;
}
