.pg-bar-overlay-dialog {
  width: 70%;
}

@media (min-width: 768px) {
  .pg-bar-overlay-dialog {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .pg-bar-overlay-dialog {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .pg-bar-overlay-dialog {
    width: 40%;
  }
}
