/* ##################### Banner List ##################### */
.banner-grid {
  display: grid;
  gap: 16px 16px;
  justify-items: center;
}

.product-cover {
  position: relative;
}

.banner-cover-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.banner-center-icon {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 8em;
  color: rgba(255, 255, 255, 0.6);
}

.banner-cover-overlay:hover {
  cursor: grab;
}

.banner-card {
  /* cursor: pointer; */
}

.banner-card-selected {
  box-shadow: 0px 0px 10px 1px #606975;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

@media (min-width: 320px) {
  .banner-grid-responsive {
    grid-template-columns: repeat(1, 1fr);
  }

  .banner-card {
    width: 300px;
    min-height: 490px;
  }

  .banner-card-v2 {
    width: 300px;
  }
}

@media (min-width: 640px) {
  .banner-grid-responsive {
    grid-template-columns: repeat(1, 1fr);
  }

  .banner-card {
    width: 400px;
    min-height: 490px;
  }

  .banner-card-v2 {
    width: 400px;
  }
}

@media (min-width: 960px) {
  .banner-grid-responsive {
    grid-template-columns: repeat(2, 1fr);
  }

  .banner-card {
    width: 400px;
    min-height: 490px;
  }

  .banner-card-v2 {
    width: 400px;
  }
}

@media (min-width: 1280px) {
  .banner-grid-responsive {
    grid-template-columns: repeat(3, 1fr);
  }

  .banner-card {
    width: 400px;
    min-height: 490px;
  }

  .banner-card-v2 {
    width: 400px;
  }
}

@media (min-width: 1600px) {
  .banner-grid-responsive {
    grid-template-columns: repeat(3, 1fr);
  }

  .banner-card {
    width: 400px;
    min-height: 490px;
  }

  .banner-card-v2 {
    width: 400px;
  }
}

.banner-card-image {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
  height: 300px;
  width: 100%;
}

.banner-card-fgimage {
  position: absolute;
  max-width: 260px;
  max-height: 180px;
  width: auto;
  height: auto;
  z-index: 1;
  /* left: 0;
  right: 0;
  top: 13%;
  margin: auto; */
}

/* ##################### Banner Edit ##################### */

@media screen and (max-width: 936px) {
  .banneredit-scroll {
    overflow: auto;
  }

  .banneredit-panel {
    width: 936px;
  }
}

/* ##################### Banner Preview ##################### */

.bnr-preview .modal-content {
  background-color: transparent !important;
  border: 0;
  /* border: 1px solid lightskyblue; */
  width: 95vw;
  height: 90vh;
}

.bnr-preview-backdrop {
  background-color: rgba(51, 51, 51);
}

.bnr-preview-icon-lannguage {
  cursor: pointer;
  height: 3rem;
  width: auto;
  -webkit-filter: contrast(40%);
  filter: contrast(40%);
}

.bnr-preview-icon-device {
  font-size: 2.5rem;
  border: 0;
  background-color: transparent;
  color: #444;
  -webkit-filter: contrast(70%);
  filter: contrast(70%);
}

.bnr-preview-icon-close {
  font-size: 2.5rem;
  border: 0;
  background-color: transparent;
  color: white;
  -webkit-filter: contrast(90%);
  filter: contrast(90%);
}

.bnr-preview-icon-lannguage:hover,
.bnr-preview-icon-device:hover,
.bnr-preview-icon-close:hover {
  -webkit-filter: contrast(200%);
  filter: contrast(200%);
}

.bnr-preview-selected {
  -webkit-filter: contrast(180%) drop-shadow(0px 0px 4px #0da9ef);
  filter: contrast(180%) drop-shadow(0px 0px 4px #0da9ef);
}

.bnr-preview-selected:hover {
  -webkit-filter: contrast(180%) drop-shadow(0px 0px 4px #0da9ef);
  filter: contrast(180%) drop-shadow(0px 0px 4px #0da9ef);
}

.bnr-cover-laptop {
  position: absolute;
  top: 15%;
  left: -0.2%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 74.3%;
  z-index: 2;
  height: 22vw;
}

.bnr-cover-mobile {
  position: absolute;
  top: 15%;
  left: 0.8%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 91%;
  z-index: 2;
  height: 230px;
}

.bnr-content-laptop {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.bnr-content-mobile {
  margin: 0;
  position: absolute;
  bottom: 10%;
  width: 100%;
  text-align: center;
}

.bnr-title-mobile {
  margin: 2%;
  font-weight: bold;
  font-size: 3vh;
}

.bnr-subtitle-mobile {
  margin: 1%;
  font-weight: bold;
  font-size: 2.7vh;
}

.bnr-body-mobile {
  margin: 1%;
  font-weight: bold;
  font-size: 1.4vh;
}

.bnr-button-mobile {
  margin: 2%;
  border-radius: 0.4vh !important;
  height: 3vh;
  border: 0;
  padding-left: 2.2vh;
  padding-right: 2.2vh;
  font-weight: bold;
  font-size: 1.1vh;
}

.bnr-button-mobile a {
  position: relative;
  bottom: -0.1vh;
}

.bnr-foreground-laptop {
  max-width: 14vw;
  max-height: 14vw;
  width: auto;
  height: auto;
}

.bnr-foreground-mobile {
  max-width: 20vh;
  max-height: 15vh;
  width: auto;
  height: auto;
}

.bnr-preview-icons {
  position: fixed;
  right: 0%;
  top: 0%;
  background-color: rgba(255, 0, 0, 0.4);
}

.bnr-preview-area {
  width: 95vw;
  height: 90vh;
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(0, 255, 0, 0.4);
}

.bnr-preview-click-area {
  background-color: rgba(0, 0, 255, 0.4);
}

.bnr-preview-keep-ratio {
  position: relative;
  height: 85vh;
  width: auto;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

.bnr-border {
  position: relative;
  height: 100%;
  width: 100%;
  left: 50%;
  transform: translate(-50%);
}

.bnr-arrow-laptop-left {
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translate(0%, -50%);
}

.bnr-arrow-laptop-right {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translate(0%, -50%);
}

.bnr-arrow-mobile-left {
  position: absolute;
  top: 40%;
  left: 7%;
  transform: translate(0%, -50%);
}

.bnr-arrow-mobile-right {
  position: absolute;
  top: 40%;
  right: 7%;
  transform: translate(0%, -50%);
}

@media (max-aspect-ratio: 5/3) {
  .bnr-close-area1-laptop {
    position: absolute;
    top: 0%;
    left: 50%;
    height: 30vh;
    width: 100%;
    transform: translate(-50%, 0%);
  }

  .bnr-close-area2-laptop {
    position: absolute;
    bottom: 0%;
    left: 50%;
    height: 26vh;
    width: 100%;
    transform: translate(-50%, 0%);
  }

  .bnr-device-landscape {
    position: relative;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bnr-device-portrait {
    position: relative;
    height: 100%;
    left: 0;
    right: 0;
  }

  .bnr-screen-laptop {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -3;
    background-color: whitesmoke;
    width: 72vw;
    height: 47vw;
  }

  .bnr-screen-mobile {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -3;
    background-color: whitesmoke;
    width: 39vh;
    height: 89%;
  }

  .bnr-block-laptop {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;
    width: 71vw;
    height: 23.66vw;
  }

  .bnr-block-mobile {
    position: absolute;
    top: 49.5%;
    left: 50.1%;
    transform: translate(-50%, -50%);
    z-index: -2;
    width: 38vh;
    height: 88%;
  }

  .bnr-title-laptop {
    margin: 2%;
    font-weight: bold;
    font-size: 2.2vw;
  }

  .bnr-subtitle-laptop {
    margin: 1%;
    font-weight: bold;
    font-size: 1.9vw;
  }

  .bnr-body-laptop {
    margin: 1%;
    font-weight: bold;
    font-size: 1vw;
  }

  .bnr-button-laptop {
    margin: 2%;
    border-radius: 0.3vw !important;
    height: 2.2vw;
    border: 0;
    padding-left: 1.6vw;
    padding-right: 1.6vw;
    font-weight: bold;
    font-size: 0.8vw;
  }

  .bnr-button-laptop a {
    position: relative;
    bottom: -0.1vw;
  }

  .bnr-arrow-laptop-left {
    font-size: 2.4vw;
  }

  .bnr-arrow-laptop-right {
    font-size: 2.4vw;
  }

  .bnr-arrow-mobile-left {
    font-size: 8vw;
  }

  .bnr-arrow-mobile-right {
    font-size: 8vw;
  }
}

@media (min-aspect-ratio: 5/3) {
  .bnr-close-area1-laptop {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 13vw;
  }

  .bnr-close-area2-laptop {
    position: absolute;
    top: 0%;
    right: 0%;
    height: 100%;
    width: 13vw;
  }

  .bnr-close-area1-mobile {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 35vw;
  }

  .bnr-close-area2-mobile {
    position: absolute;
    top: 0%;
    right: 0%;
    height: 100%;
    width: 35vw;
  }

  .bnr-device-landscape {
    position: relative;
    height: 100%;
  }

  .bnr-device-portrait {
    position: relative;
    height: 100%;
  }

  .bnr-screen-laptop {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -3;
    background-color: whitesmoke;
    width: 111vh;
    height: 72vh;
  }

  .bnr-screen-mobile {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -3;
    background-color: whitesmoke;
    width: 39vh;
    height: 77vh;
  }

  .bnr-block-laptop {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;
    width: 111vh;
    height: 37vh;
  }

  .bnr-block-mobile {
    position: absolute;
    top: 49.5%;
    left: 50.1%;
    transform: translate(-50%, -50%);
    z-index: -2;
    width: 38vh;
    height: 75vh;
  }

  .bnr-title-laptop {
    margin: 2%;
    font-weight: bold;
    font-size: 3vh;
  }

  .bnr-subtitle-laptop {
    margin: 1%;
    font-weight: bold;
    font-size: 2.7vh;
  }

  .bnr-body-laptop {
    margin: 1%;
    font-weight: bold;
    font-size: 1.4vh;
  }

  .bnr-button-laptop {
    margin: 2%;
    border-radius: 0.4vh !important;
    height: 3vh;
    border: 0;
    padding-left: 2.2vh;
    padding-right: 2.2vh;
    font-weight: bold;
    font-size: 1.1vh;
  }

  .bnr-button-laptop a {
    position: relative;
    bottom: -0.1vh;
  }

  .bnr-arrow-laptop-left {
    font-size: 3.4vh;
  }

  .bnr-arrow-laptop-right {
    font-size: 3.4vh;
  }

  .bnr-arrow-mobile-left {
    font-size: 4.6vh;
  }

  .bnr-arrow-mobile-right {
    font-size: 4.6vh;
  }
}

.bnr-bg-laptop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.bnr-bg-mobile {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.bnr-header-laptop {
  position: absolute;
  top: -9%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.bnr-header-mobile {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.bnr-box-laptop-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  height: 100%;
  width: 42%;
}

.bnr-box-mobile-center {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  height: 41%;
  width: 100%;
}

.bnr-box-laptop-left {
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translate(0%, -50%);
  z-index: 0;
  height: 100%;
  width: 42%;
}

.bnr-box-mobile-left {
  position: absolute;
  top: 40%;
  left: 4%;
  transform: translate(0%, -50%);
  z-index: 0;
  height: 41%;
  width: 95%;
}

.bnr-box-laptop-right {
  position: absolute;
  top: 50%;
  right: 4%;
  transform: translate(0%, -50%);
  z-index: 0;
  height: 100%;
  width: 42%;
}

.bnr-box-mobile-right {
  position: absolute;
  top: 40%;
  right: 4%;
  transform: translate(0%, -50%);
  z-index: 0;
  height: 41%;
  width: 95%;
}
