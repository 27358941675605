.btn-no-border {
  border: 0;
  cursor: pointer;
}

.btn-no-border > span {
  color: #606975;
  font-size: 16px;
  font-weight: lighter;
}

.btn-no-border:hover > span {
  border-bottom: 2px solid #606975;
}
