.margin-between-text {
    margin-bottom: 20px;
}

.ant-switch-checked {
    background-color: rgb(75,212,99);
}


@media only screen and (min-width: 1023px) and (max-width: 1920px) {
    .btn-position-save {
        display: flex;
        justify-content: flex-end;
    }
    .mar-bot-status {
        margin-bottom: 5px;
    }
    .padding-in-col {
        padding-left: 75px;
    }
}

@media only screen and (min-width: 415px) and (max-width: 1024px) {
    .btn-position-save {
        display: flex;
        justify-content: flex-end;
    }
    .mar-bot-status {
        margin-bottom: 5px;
    }
    .padding-in-col {
        padding-left: 75px;
    }
}

@media only screen and (min-width: 1px) and (max-width: 414px) {
    .btn-position-save {
        display: flex;
        justify-content: center;
    }
    .btn-position-cancel {
        display: flex;
        justify-content: center;
    }
}
