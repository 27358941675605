

.avatar-approval-wrapper .ant-modal-content,
.avatar-approval-wrapper .ant-modal-header {
    border-radius: 10px;
}

.search-box .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 2px 2px 0 !important;
}

.search-box .button-circle {
    background-color: transparent;
}

.search-box .button-circle > i {
    font-size: 1em;
}

.avatar-approval-wrapper .ant-modal-header {
    text-align: center;
}


.avatar-approval-wrapper {

    .ant-modal-body {
        .modal-image-list {
            display: grid;
            justify-content: center;
            align-items: center;
            grid-template-columns: repeat(1, 320px);
    
            &.grid2columns {
                grid-template-columns: repeat(2, 320px);
            }
    
            &.grid3columns {
                grid-template-columns: repeat(3, 320px);
            }
        }
    }

    .buttons-actions {
        display: grid;
        grid-template-columns: repeat(3, 320px);
        justify-content: center;
        gap: 20px;

        > div {
            display: flex;
            justify-content: center;

            &:first-child {
                justify-content: flex-start;
            }
            &:last-child {
                justify-content: flex-end;
            }
            button {
                margin-left: 0px;
                margin-right: 0px;
            }
        }
    }
}