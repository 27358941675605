.hr-alert-error {
    border-bottom: 1px dashed red;
}

.hr-alert-error:last-child {
    border-bottom: none;
}
.error-validate-box {
    padding: 15px;
    width: 40%;
    text-align: center;
    color: #fe5d7c;
    border: 1px solid #ffe7eb;
    background-color: #ffe7eb;
}