
@media screen and (max-width: 920px) {
    .feelgreat-settings-edit-scroll {
      overflow: auto;
    }
  
    .feelgreat-settings-edit-panel {
      width: 920px;
    }
  }
  