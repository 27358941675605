.checkbox-combine-label > div.label {
    border-left: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
}

.checkbox-combine-label > div.checkbox {
    border: 1px solid #dee2e6;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
}
