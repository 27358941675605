.datepicker span.mbsc-ios {
    width: 210px;
    border: 1px solid #d9d9d9;
}
.datepicker input.mbsc-ios {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    height: 30px;
}
.tablejpx tr{
    /* cursor: pointer; */
}
.txtAutoShipMsgT{
    color: green !important;
}
.txtAutoShipMsgF{
    color: red !important;
}