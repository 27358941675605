.react-datepicker {
  padding: 10px;
  border-radius: 8px !important;
}

.react-datepicker__header {
  padding: 0 !important;
  background-color: white !important;
  border-color: #d6d6d6 !important;
}

.react-datepicker__current-month {
  font-size: 14px !important;
  font-weight: lighter !important;
  font-family: "Maven Pro" !important;
}

.react-datepicker__navigation {
  top: 14px !important;
}

.react-datepicker__day-name {
  font-family: "Maven Pro" !important;
  color: #606975 !important;
}

.react-datepicker__day {
  font-family: "Maven Pro" !important;
  font-size: 14px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.8rem !important;
}

.rdrInputRanges {
  padding-top: 4px !important;
  padding-bottom: 0px !important;
}
.rdrInputRanges > div:nth-child(2) {
  display: none;
}

.rdrMonth {
  padding-bottom: 0px !important;
}

.rdrStaticRange {
  border-radius: 0px !important;
  margin-right: 0.6em;
}
