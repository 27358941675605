.warning {
    background-color: #ff9800;
    color: white;
}

/* Orange */
.warning:hover {
    background: #e68a00;
    color: white;
}

.warning:disabled {
    background-color: #e68a00;
    color: white;
}

.commit-detail {

    table,
    th,
    td {
        border: 1px solid;
    }

    th {
        width: 20%;
    }
}

.autoship-bg-red {
    background: rgb(240, 20, 20);
}