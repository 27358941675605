.page-wrapper {
  transition: all 0.1s ease 0ms;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.page-push {
  -webkit-transform: translate(250px) !important;
  transform: translate(250px) !important;
  -webkit-transform: translate3d(250px, 0, 0) !important;
  transform: translate3d(250px, 0, 0) !important;
}

.push-overlay {
  left: 250px;
}

.site-footer {
  margin-top: 0px !important;
}

.page-content {
  min-height: calc(100vh - 148px);
}

.navbar-sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.float-panel-right {
  position: fixed;
  top: 280px;
  right: 0;

  transition: right 0.1s ease-in-out;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}
