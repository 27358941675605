.d-range-picker {
  color: #606975;
  font-size: 16px;
  font-weight: lighter;
}

.d-range-picker-toggle {
  cursor: pointer;
}

.d-range-picker-popver {
  max-width: 577px;
  width: 577px;
}

.d-range-picker-popver > div[class="arrow"] {
  display: none;
}

.d-range-picker-toggle:hover > span[class="label"] {
  border-bottom: 2px solid #606975;
}

.d-range-picker-toggle > span[class="value"] {
  border-bottom: 2px solid #0da9ef;
  color: #0da9ef;
}

.d-range-picker-btn-apply,
.d-range-picker-btn-cancel {
  padding: 0px;
  border: 0;
  border-left: 0px;
}

.d-range-picker-btn-apply {
  border-radius: 0px !important;
  color: #0da9ef;
  border-right: 0px;
}

.d-range-picker-btn-cancel {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.d-range-picker-clear {
  border: 0;
  border-bottom: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  color: #606975 !important;
}

.d-range-picker-clear:hover {
  color: #0da9ef !important;
}
