.topbar {
  display: flex;
}

.topbar > .left,
.topbar > .right {
  flex: 0.5;
}

.topbar > .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cus-icon-first {
  margin-left: 10px;
  margin-right: 15px;
  color: #606975;
}

.cus-link {
  color: #606975;
  text-decoration: none !important;
}

.cus-icon {
  margin-right: 15px;
  color: #606975;
}

.topbar > .right > .popover-panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.topbar > .right > .popover-panel > img {
  height: 20px;
  cursor: pointer;
}

.topbar > .right > .popover-panel > span {
  padding: 4px 0 4px 12px;
  color: #606975;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.Popover {
  z-index: 1000;
}

.Popover-tip {
  fill: white;
}

.Popover-body {
  background-color: white;
  border-radius: 3px;
  width: 228px;
  padding: 20px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2);
  border: 1px solid #e1e7ec;
}

.language-selector {
  margin-top: 10px;
}

.language-selector:hover {
  cursor: pointer;
  text-shadow: 2px 2px 12px #17a2b8;
}

.language-selector > img {
  width: 20px;
  margin-right: 10px;
}

.country-select {
  margin-bottom: 15px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menubar-crtl-panel {
  position: absolute;
  right: 0px;
  top: -1px;
  height: 100%;
  z-index: 998;
}

.ctrl-toggle {
  width: 56px;
  height: 50px;
  top: 13px;
  right: 0px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: #374250;
  color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 999;
  -webkit-animation: fadein 0.2s;
  -moz-animation: fadein 0.2s;
  -ms-animation: fadein 0.2s;
  -o-animation: fadein 0.2s;
  animation: fadein 0.2s;
}

.ctrl-toggle > i {
  transition: opacity 0.35s;
  font-size: 24px;
  line-height: 45px;
}

.ctrl-actions {
}
