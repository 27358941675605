.custom-ddl2 {
    width: 100%;
}

.custom-ddl2 > .ant-select-selector {
    border-color: #dbe2e8;
    padding-left: 1em !important;
}

.custom-ddl2-danger > .ant-select-selector {
    border-color: red !important;
}

.custom-ddl2 > .ant-select-selector > .ant-select-selection-item {
    color: #606975;
}

.custom-ddl2 > .ant-select-selector > .ant-select-selection-search{
    right: 11px !important;
}

.custom-ddl2-sm > .ant-select-selector {
    height: 36px !important;
    border-radius: 18px !important;
}

.custom-padding-footer-header-menu > .ant-select-selector {
    padding-left: 18px !important;
    padding-right: 18px !important;
}

.custom-ddl2-sm > .ant-select-selector > .ant-select-selection-item {
    padding-top: 2px !important;
}

.custom-ddl2-md > .ant-select-selector {
    height: 44px !important;
    border-radius: 22px !important;
}

.custom-ddl2-md > .ant-select-selector > .ant-select-selection-item,
.custom-ddl2-md > .ant-select-selector > .ant-select-selection-placeholder {
    padding-top: 5px !important;
}

.custom-ddl2-md > .ant-select-selector > .ant-select-selection-search > .ant-select-selection-search-input {
    height: 90% !important;
}

.custom-ddl2-align-left > .ant-select-selector > .ant-select-selection-item {
    text-align: left;
}

.custom-ddl2-align-center > .ant-select-selector > .ant-select-selection-item,
.custom-ddl2-align-center > .ant-select-selector > .ant-select-selection-search > .ant-select-selection-search-input,
.custom-ddl2-align-center > .ant-select-selector > .ant-select-selection-placeholder {
    text-align: center;
}

.custom-ddl2-align-right > .ant-select-selector > .ant-select-selection-item,
.custom-ddl2-align-right > .ant-select-selector > .ant-select-selection-search > .ant-select-selection-search-input,
.custom-ddl2-align-right > .ant-select-selector > .ant-select-selection-placeholder {
    text-align: right;
} 
/* 
.custom-ddl2 > .ant-select-selection--single > .ant-select-selection__rendered > .ant-select-selection-selected-value {
    font-weight: 500;
    width: 100%;
}



.custom-ddl2-md
    > .ant-select-selection--single
    > .ant-select-selection__rendered
    > .ant-select-selection-selected-value {
    padding-top: 5px;
}

.custom-ddl2-align-left > .ant-select-selection--single > .ant-select-selection__rendered {
    text-align: left;
}

.custom-ddl2-align-center > .ant-select-selection--single > .ant-select-selection__rendered {
    text-align: center;
}

.custom-ddl2-align-right > .ant-select-selection--single > .ant-select-selection__rendered {
    text-align: right;
} 

*/
