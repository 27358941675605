.order-history-details {
    background-color: #F5F5F5;

    .p-d-l-r-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .order-history-details-product-image {
        width: 130px;
        height: 125px;
        padding: 0px;
        object-fit: contain;
        object-position: center;
    }

    .text-order-id {
        padding: 30px 0 30px 0;
    }

    ul {
        margin: 0px;
        padding: 0px;
        list-style-type: none;

        li:not(:last-child) {
            border-color: #9EA9B9;
        }

        li {
            float: left;
            margin: 12px 0;
            // color: rgba(0, 0, 0, 0.45);
            text-align: center;
        }
    }

    .order-history-details-header-details {
        background-color: #F5F5F5;
        font-size: 26px;
        color: black;
        font-weight: bold;
        text-align: center;
    }

    .order-history-details-product-card {
        background-color: #E3E8ED;
        border-radius: 0 0 7px 7px;
        pointer-events: none;
        display: flex;
        align-items: center;
        height: 40px;
    }
}

.order-history-details-content {
    .ant-card {
        border-radius: 10px;
    }

    .descriptions-item-label {
        font-weight: normal;
        font-size: 14px;
        line-height: 1.5715;
        color: #9DA9B9;
        background-color: transparent;
        padding: 0;
        padding-bottom: 4px;
        color: #333;
        line-height: 1.5715;

    }

    .value-item-label {
        font-weight: normal;
        font-size: 14px;
        padding: 0;
        padding-bottom: 4px;
        color: #333;
        text-align: right;
        line-height: 1.5715;
    }

    td {
        text-align: right;
    }

    th {
        text-align: left;
    }

    hr.new4 {
        margin-top: 10px;
        margin-bottom: 10px;
        border: 1px solid black;
    }
}

.block-btn-confirm {
    padding: 20px;
    background-color: white;
    gap: 20px;
    font-size: 16px;

    .btn-confirm {
        button {
            width: 300px;
            height: 44px;
        }
    }
}


.ohd-details {}

.order-history-details-card {
    padding: 1.25rem 1rem;
    height: 100%;
    min-height: 106px;
    max-height: 176px;
    color: black;
    font-size: 16px;
    display: flex;
    align-items: center;

    border-color: #E3E8ED;

    .order-history-details-col-right {
        display: flex;
        justify-content: left;
        align-items: center;
    }
}

.card-cart-items {
    .ant-card-body {
        padding: 0px;
    }
}

.description-title {
    font-size: 16px;
    color: black;
    line-height: 1.25;
}

.description-code {
    font-size: 12px;
    color: #9EA9B9;
    padding-top: 6px;
}

.order-history-details-details-row {
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: #E3E8ED;

    .ant-card-body {
        padding: 1.25rem;
    }
}