.payment-card {
  width: 300px;
  height: 268px;
  margin: 0px 8px 16px 8px;
  border: 1px solid #e1e7ec;
  border-radius: 8px;
}

.payment-card-header {
  border-bottom: 1px solid #e1e7ec;
  background-color: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
