.button-circle {
  cursor: pointer;
}

.button-circle > i {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  font-size: 1.5em;
}

.button-circle > i::before {
  position: relative;
  top: 1px;
}

.button-circle-md {
  width: 42px;
  height: 42px;
  border-radius: 22px;
}

.button-circle-primary > i,
.button-circle-success > i,
.button-circle-danger > i {
  color: #ffffff;
}

.button-circle-primary > div > span,
.button-circle-success > div > span,
.button-circle-danger > div > span {
  filter: drop-shadow(22px 0px #fff) !important;
}

.button-circle-outline-primary {
  border: 1px solid #0da9ef !important;
}

.button-circle-outline-primary > div > span {
  filter: drop-shadow(22px 0px #0da9ef) !important;
}

.button-circle-outline-primary:hover > div > span {
  filter: drop-shadow(22px 0px #fff) !important;
}

.button-circle-outline-secondary {
  border-color: #6c757d !important; 
}

.button-circle-outline-secondary:hover {
  border-color: #e1e7ec !important;
}

.button-circle-secondary {
  border: 1px solid #dbe2e8 !important;
  color: inherit;
  -webkit-filter: brightness(95%);
  filter: brightness(95%);
}

.button-circle-secondary:hover {
  color: inherit;
}

.button-circle-danger {
  border: 1px solid #ff5252 !important;
}

/* For image layout */
.button-circle-image-primary > div > span {
  filter: drop-shadow(16px 0px #fff) !important;
}

.button-circle-image-outline-primary > div > span {
  filter: drop-shadow(16px 0px #0da9ef) !important;
}

.button-circle-image-outline-primary:hover > div > span {
  filter: drop-shadow(16px 0px #fff) !important;
}

/* For image static */
.button-circle-success:hover > img {
  -webkit-filter: contrast(1.2);
  filter: contrast(1.2);
}
