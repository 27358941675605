.react-datepicker-custom
> .react-datepicker__month-container
> .react-datepicker__monthPicker
> .react-datepicker__month-wrapper
> .react-datepicker__month-text {
    width: 6rem;
    height: 3.5rem;
    padding-top: 6px;
}

.react-datepicker-custom
> .react-datepicker__month-container
> .react-datepicker-year-header{
    font-size: 1.5rem;
}
