.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
}
.login-box {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.input-group > input {
  width: 100% !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.spinner-container > div {
  width: 100px !important;
}
