.dashboard-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 10px;
}

.active-users-card {
    background-color: #4285f4;
    color: white;
}

.dashboard-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dashboard-picker>h3 {
    margin: 0;
    margin-right: 10px;
}

.active-users-card>h2 {
    color: white;
    margin: 0;
    margin-bottom: 10px;
}

.dashboard-login {
    text-align: center;
}

.dashboard-logout-button {
    margin-left: 5px;
}

.dashboard-card-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 20px;
}

.dashboard-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
}

.dashboard-graph {
    padding-right: 30px;
}

.no-top-margin {
    padding-top: 0px;
}

.google-login {}