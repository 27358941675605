.btn-copy-to img {
  cursor: pointer;
  max-height: 26px;
  width: auto;
}

.btn-copy-to img:hover {
  filter: brightness(0.8);
}

.btn-copy-to-circle img {
  cursor: pointer;
  max-height: 36px;
  width: auto;
}

.btn-copy-to-circle img:hover {
  filter: brightness(0.8);
}
