.news-detail-style {
  font-size: 14px;
  color: rgb(192, 192, 192);
}

.custom-btn-news-edit {
  background: none;
  color: rgb(80, 198, 233);
  padding: 0px;
  margin: 0px;
  border: none;
}

.custom-date-picker-news {
  .mbsc-ios.mbsc-datepicker-inline {
    border-top: unset;
    border-bottom: unset;
  }

  .mbsc-ios.mbsc-range-control-wrapper .mbsc-segmented {
    margin-left: 25px;
  }

  .mbsc-ios.mbsc-input {
    width: 300px;
  }
}