.sidebar-menu-main {
  position: absolute;
  transition: 0.1s;
  width: 100%;
  left: -250px;
}

.sidebar-menu-country {
  position: absolute;
  transition: 0.1s;
  width: 100%;
  right: -250px;
}

.sidebar-menu-search {
  width: 100%;
}

.sidebar-menu-main > ul > li,
.sidebar-menu-country > ul > li,
.sidebar-menu-search > ul > li {
  height: 50px;
  box-shadow: inset -1em 0 1.5em -0.75em rgba(0, 0, 0, 0.5);
}

.sidebar-menu-offview > ul {
  display: none;
}

.sidebar-menu-header {
  color: #0da9ef;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.sidebar-menu-label {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.sidebar-menu-item {
  padding-left: 20px;
  padding-right: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.sidebar-search-input {
  background-color: transparent;
  color: #fff;
  border: 0;
  height: 40px;
  width: 100%;
}

.sidebar-search-input:focus {
  outline: none;
}
.sidebar-search-input::placeholder {
  color: #fff;
  opacity: 0.6;
}
