.take-a-photo--wrapper {
    background-image: url("./border-id-card.svg");
    background-repeat: no-repeat;
    width: 466px;
    height: 346px;
    padding: 30px 30px 16px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .crop-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: white;
        height: 100%;
        z-index: 99999999;
    }

    &.has-cropped-image {
        padding: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
        background-image: none;
        height: 100%;

        .id-card-box {
            width: 100%;
            height: 100%;
            .id-card {
                display: block;
            }
        }
    }

    .id-card-box {
        display: grid;
        justify-content: center;

        &.loading {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .loadingImage,
            .container {
                height: 100%;
            }

            .camera {
                display: none;
            }
        }

        .camera {
            padding-top: 10px;
            width: 35px;
            margin: auto;
        }

        .id-card {
            width: 100%;
            height: 100%;
            display: grid;
            align-items: center;
        }
    }

    .reactEasyCrop_Container {
        position: relative;
        height: 100%;
    }
}

.modal-crop {
    .ant-modal-footer {
        display: flex;
        justify-content: center;
    }

    .ant-modal-content {
        border-radius: 20px;

        .ant-modal-body {
            .image-preview {
                display: grid;
                grid-template-columns: auto 150px auto;
        
                .rotate {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .ReactCrop {
                width: 100%;
            }
        }
    }
}

.upload-modal-crop {
    margin: 0px auto;
    max-width: 100%;
    top: 0px;
    position: absolute;
    left: 0;
    right: 0;
    width: auto !important;

    .image-preview {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        text-align: center;
        .rotate {
            width: 100%;
            margin: 0 15px;
        }
        .image-crop {
            width: 230px;
            height: auto;
        }
    }

    .ant-modal-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border: 0px;
        height: 65px;
        border-radius: 0px;

        .btn-outline-secondary {
            background-color: white;
        }

        button {
            margin: 0px;

            &:first-child {
                margin-right: 5px;
            }

            &:last-child {
                margin-left: 5px;
            }
        }

        span {
            z-index: 5;
        }
    }

    .ant-modal-body {
        height: 100%;
        padding: 40px 40px 20px 40px;
        text-align: center;
    }

    .ant-modal-content {
        width: 100%;
        height: auto!important;
        border-radius: 0px;
    }

    .crop-footer {
        height: 65px;
        width: 100%;
        position: fixed;
        bottom: 20;
        left: 0;
        background-color: white;
        background-color: white;
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 10px;

        button {
            margin: 0px;
            z-index: 2;
        }
    }
}

.camera {
    cursor: pointer;
}

.background-cover {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.width-detected-box {
    position: relative;
    display: inline-block;
    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    .box-detected {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: absolute;
        border: 2px solid red;
    }
}


@media screen and (max-width: 414px) {
    .take-a-photo--wrapper {
        background-image: url("./border-id-card-354.svg");
        background-repeat: no-repeat;
        width: 300px;
        height: 220px;
        padding: 20px 20px 2px 20px;
        .id-card-box {
            .id-card {
                width: 100%;
                // height: 165px;
                overflow: hidden;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .camera {
                padding-top: 0px;
                width: 20px;
                margin: auto;
            }
        }
    }

    .upload-modal-crop {
        .image-preview {
            .rotate {
                width: 37px;
                margin: 0 15px;
            }
        }
    }

    .upload-id-card--wrapper {
        .take-a-photo--wrapper {
            .id-card-box {
                .id-card {
                    height: 165px;
                    margin-top: 20px;
                }
            }
        }
    }
}


@media screen and (max-width: 320px) {
    .take-a-photo--wrapper {
        background-image: url("./border-id-card-290.svg");
        background-repeat: no-repeat;
        width: 251px;
        height: 188px;
        padding: 20px 20px 2px 20px;
        .id-card-box {
            .id-card {
                width: 210px;
                height: 132px;
                overflow: hidden;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .camera {
                padding-top: 0px;
                width: 20px;
                margin: auto;
            }
        }
    }
}
