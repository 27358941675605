.thumbnails-selc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.thumbnails-selc-item {
    display: inline-block;
    margin: 1px 4px 1px 4px;
    padding: 1px;
    position: relative;
    cursor: pointer;
    outline: 1px solid #dbe2e8;
}

.thumbnails-selc-item:hover{
    outline: 1px solid #40a9ff;
}

.thumbnails-selc-item:hover > img {
    filter: brightness(120%);
}

.thumbnails-selc-item-selected {
    outline: 1px solid #40a9ff;
    box-shadow: 0px 0px 8px #40a9ff;
}

.thumbnails-selc-btn-del {
    color: #ff5252;
    font-size: 15px;
    display:  inline-block;
    position: absolute;
    right: -0px;
    top: -6px;
    z-index: 1;
    cursor: pointer;
    transition: .5s all;
}

.thumbnails-selc-btn-del:hover{
    text-shadow: 0px 0px 1px #ff5252;
}

.thumbnails-have-link {
    background: yellow;
    top: 70%;
    left: 4%;
    position: absolute;
    z-index: 1;
}
