.warning {
    background-color: #ff9800;
    color: white;
}

/* Orange */
.warning:hover {
    background: #e68a00;
    color: white;
}

.warning:disabled {
    background-color: #e68a00;
    color: white;
}

.commit-detail {

    table,
    th,
    td {
        border: 1px solid;
    }

    th {
        width: 20%;
    }
}

.autoship-bg-red {
    background: rgb(240, 20, 20);
}

.export-excel-btn {
    background-color: #00B96B;
    color: #ffffff;
    border-color: #00B96B;
}

.export-excel-btn:hover {
    background-color: #048b53;
    color: #ffffff;
    border-color: #048b53;
}

.export-excel-btn:focus {
    background-color: #00B96B;
    color: #ffffff;
    border-color: #00B96B;
}

.export-excel-btn-disabled {
    background-color: #f5f5f5;
    color: #bfbfbf;
    border-color: #d9d9d9;
}

