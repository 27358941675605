
.tag-input-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 530px;
    border-radius: 4px;
    background-color: #fcfcfb;
    border: 1px solid #ced4da;
    padding: 10px 12px;
    height: 3.4375em;
    animation: 10ms;
    transition: border-color 30;

}.header-unsubscribe {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;

        h2 {
            margin: 0;
            padding: 0;
        }
}

.container-input {
    border-radius: 4px;
    background-color: #fcfcfb;
    border: 1px solid #ced4da;
    font-size: 16px;
    width: 530px;

        .wrapper-tags {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: .3em;
            padding: 5px 5px;
        
                .tag-item {
                    background-color: rgb(218, 216, 216);
                    color: black;
                    // background-color: #DBEBFF;
                    // color: #0075FF;
                    display: inline-block;
                    padding: 3px 5px 3px 10px;
                    border: 5px;
                    font-size: 14px;
                    border-radius: 5px;
                    font-weight: 500;
                
                    .close {
                        height: 18px;
                        width: 18px;
                        background-color: rgb(48, 48, 48);
                        display: inline-block;
                        color: #FFF;
                        border-radius: 50%;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        padding-bottom: 4px;
                        margin-top: 2px;
                        margin-left: 10px;
                    }

                    .close:hover {
                        background-color: rgb(81, 81, 81);
                    }
                    .close:active {
                        background-color: rgb(55, 55, 55);
                    }
                }
        }

        .tag-input {
            flex-grow: 1;
            border: none;
            outline: none;
            background-color: transparent;
            padding: 5px 8px;
            min-width: 0px;
        }
}

.wrapper-tags:focus-within  { 
    box-shadow: rgba(25, 118, 210, 0.25) 0 0 0 0.3rem;
    transition: all .5s ease;
    border-radius: 4px;
}

.div:focus-within {
    .label-tags {
        color: #1976d2;
    }
}

.search-input {
    width: 350px;
    font-size: 14px;
    outline: none;
    padding: 10px 0 10px 15px;
    border-radius: 5px;
    border: 1.5px solid rgb(168, 168, 168);

    &:focus-within {
        border: 1.5px solid #1976d2;
        transition: all .5s ease;
    }
}

.search-field {
    border: none;
    outline: none;
    padding: 0 0 0 12px;
    width: 90%;
}

.tags-filter {
    background-color: transparent;
    width: 10px;
    border: none;
    margin-left: 10px;
    outline: none;
}

.button-create-importCSV {
    display: flex;
    gap: 20px;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    gap: 5px;

        .tag-unsubscribers {
            color: white;
            padding: 5px 10px;
            border-radius: 6px;
            font-size: 12px;
        }

        // .tag-unsubscribers:nth-child(6n + 1) {
        //     background-color: #1877F2;
        // }
        // .tag-unsubscribers:nth-child(6n + 2) {
        //     background-color: #FE5D7C;
        // }
        // .tag-unsubscribers:nth-child(6n + 3) {
        //     background-color: #FFBF3A;
        // }
        // .tag-unsubscribers:nth-child(6n + 4) {
        //     background-color: #B7E250;
        // }
        // .tag-unsubscribers:nth-child(6n + 5) {
        //     background-color: #2CCCE4;
        // }
}