.badge-item {
  cursor: pointer;
  display: inline-block;
  border: 1px solid #bbb;
  color: #999;
  border-radius: 22px;
  font-size: 14px;
  padding: 0;
}

.badge-item:hover {
  -webkit-filter: brightness(75%);
  filter: brightness(75%);
}

.badge-item .badge-item-label {
  color: #999;
  margin: 3px 0px 0px 12px;
}

.badge-item .badge-item-btn {
  color: #bbb;
  padding: 4px 7px 3px 7px;
  margin-right: 4px;
}
