.idv-table table {
    /* min-width: 1000px; */
}

.idv-table thead > tr > th {
    font-size: 16px;
    color: #606975;
    background-color: transparent;
    border-top: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
}

.idv-table tbody > tr > td {
    transition: 0.2s all;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #dee2e6;
}

.idv-float-panel {
    opacity: 0.7;
    transition: 0.3s all;
    background-color: rgb(192, 192, 192, 0.7);
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.idv-float-panel:hover {
    opacity: 1;
    background-color: rgb(192, 192, 192, 0.8);
}

.idv-dialog-detail > .modal-dialog > .modal-content{
    transition: all 1s;
}

.idv-dialog-view-image > .react-viewer-footer > div > .react-viewer-toolbar {
   height: 42px;
}

.idv-dialog-view-image > .react-viewer-footer > div > .react-viewer-toolbar > .react-viewer-btn {
    height: 39px;
    width: 39px;
    margin-right: 2px;
    margin-left: 2px;
 }

 .idv-dialog-view-image > .react-viewer-footer > div > .react-viewer-toolbar > .react-viewer-btn > .react-viewer-icon {
   font-size: 18px;
   margin-top: 10px;
   margin-right: 1px;
 }
