.hrz-ex-pnl > .hrz-ex-pnl-expand {
  opacity: 0;
  max-width: 0px;
  -webkit-transition: 0.5s; /* For Safari 3.1 to 6.0 */
  transition: 0.5s;
}

.hrz-ex-pnl:hover > .hrz-ex-pnl-expand {
  opacity: 1;
  max-width: 100px;
}
