@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.customizer {
  position: fixed;
  right: -260px;
  z-index: 999;
  transition: right 0.1s ease-in-out;

  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}

.customizer.open {
  right: 0;
}

.customizer-toggle {
  position: absolute;
  top: 0px;
  left: -56px;
  width: 56px;
  height: 50px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: #374250;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.customizer-toggle > i {
  transition: opacity 0.35s;
  font-size: 24px;
  line-height: 45px;
}

.customizer-toggle:hover > i {
  opacity: 0.7;
}

.customizer-toggle-visible-delay {
  visibility: visible;
  /* transition-delay: 0.1s; */
}

.customizer-toggle-hidden-delay {
  visibility: hidden;
  /* transition-delay: 0.1s; */
}

.customizer-body {
  width: 260px;
  padding: 20px;
  border-bottom-left-radius: 7px;
  background-color: #374250;
}

.customizer-topbar-title {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 500;
}
.customizer-title {
  margin-bottom: 10px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.customizer-color-switch {
  margin-bottom: 25px;
}
.customizer-color-switch::after {
  display: block;
  clear: both;
  content: "";
}
.customizer-color-switch > a {
  display: block;
  position: relative;
  float: left;
  width: 32px;
  height: 32px;
  margin: 0 10px;
  border-radius: 50%;
  color: #fff;
  text-decoration: none;
  text-align: center;
}
.customizer-color-switch > a::before {
  display: block;
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  transition: border-color 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  content: "";
}
.customizer-color-switch > a::after {
  transition: opacity 0.25s;
  font-family: feather;
  font-size: 18px;
  line-height: 30px;
  content: "\e116";
  opacity: 0;
}
.customizer-color-switch > a.active::before,
.customizer-color-switch > a:hover::before {
  border-color: #fff;
}
.customizer-color-switch > a.active::after {
  opacity: 1;
}
.customizer-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-position: center;
  background-size: 74px;
  background-repeat: no-repeat;
  z-index: 9050;
}
.customizer-backdrop.active {
  display: block;
}
