.input-text-combine-label > div.label {
    border-left: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
}

.input-text-combine-label > input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}


.input-text-combine-label-show-focus {
    box-shadow: 0 0 10px orange !important;
}