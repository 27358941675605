.ant-select-dropdown {
    z-index: 9999;
}

.box-menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px 10px;
}

#scrollarea-invalid {
    overflow-x: auto;
    overflow-y: hidden;
}
#scrollarea-content{
    min-height:101%;
}

.card-style-menu:focus {
    outline: none;
}