@import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css");
/*****************************
USE  to define image path
******************************
Media Queries
1. 400, 480, 640, 767, 1024, 1366
2. use Max-Width
******************************/

/** General */

html {
  font-family: "Maven Pro", sans-serif;
}

body {
  overflow-x: hidden;
  font-family: "Maven Pro", sans-serif;
}

-webkit-scrollbar {
  display: none !important;
}
::-webkit-scrollbar {
  display: none;
}

button {
  font-family: "Maven Pro", sans-serif;
  border-radius: 22px;
}

select {
  font-family: "Maven Pro", sans-serif;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.topbar .topbar-column {
  width: auto;
}

@font-face {
  font-family: db-helvethaica-x;
  src: url(../assets/fonts/db-helvethaica-x-li.ttf);
}

.accordion .card {
  margin-bottom: 5px;
  border-radius: 5px;
}

.accordion .card:first-of-type {
  border-bottom: 1px solid #e1e7ec;
  border-radius: 5px;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 5px;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-radius: 5px;
  border-bottom: 1px solid #e1e7ec;
}

.accordion .card:last-of-type {
  border-radius: 5px;
}

.card-header,
.card-footer {
  background-color: #f5f5f5;
  border-bottom-color: #f5f5f5;
}

.accordion .card:first-of-type {
  border-bottom: 1px solid #e1e7ec;
}

.th-font-large {
  font-size: 22px !important;
  line-height: 1;
}

.th-font {
  font-family: db-helvethaica-x;
}

main {
  background-color: #ffffff;
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 95vw;
}

.modal-dialog.samepayment {
  width: 550px !important;
}

.page-container,
#page-wrap {
  background-color: #ffffff;
  height: 100%;
}

.social-icons {
  display: none;
}

iconHover {
  cursor: pointer;
}

iconHover:hover {
  cursor: pointer;
  color: #0da9ef;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.pills .btn-outline-primary.focus,
.pills .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 !important;
}

.btn-outline-primary:hover {
  border-color: #0da9ef;
}

.dropdown-menu {
  margin: 0.4rem !important;
}

.dropdown-menu.disabled-true {
  display: none;
}

.btn.dropdown-toggle[disabled] {
  background-color: #f5f5f5;
  opacity: 0.85;
}

.reportTab .dropdown-menu {
  width: 225px;
  margin: 0.4rem auto !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle,
.btn-outline-secondary:hover {
  background: transparent;
  color: #606975;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
  background-color: #0aa9ef;
  border-color: #0aa9ef;
  box-shadow: none;
  color: #fff;
}

.btn-outline-secondary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
  border-color: #e1e7ec;
}

.dropdown-menu a.dropdown-item {
  padding-top: 5px;
  padding-bottom: 5px;
}

.checkout-footer {
  padding: 20px;
}

@media only screen and (max-width: 411px) {
  .checkout-footer {
    padding: 14px;
  }
}

.checkout-footer.not-login {
  height: 306px;
  margin-top: 0px;
}

.checkout-footer .btn > i,
.checkout-footer a > i {
  margin-top: -4px;
}

.checkout-footer a.btn.btn-primary span,
.checkout-footer a.btn.btn-primary i {
  color: #fff;
}

.checkout-payment.checkout-footer .btn {
  width: 100%;
  font-weight: bold;
}

.max-height-160 {
  max-height: 160px !important;
}

.hide-filter-section-active {
  display: none;
}

/*Sathya's UI changes*/

.live-button {
  border: 1px solid gray;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 9px !important;
}

.verticalMiddle {
  vertical-align: middle;
}

.fade-in-on-load {
  animation-duration: 2.3s;
  animation-name: fadeInOnLoad;
}

@keyframes fadeInOnLoad {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.transparent-bg {
  background-color: transparent;
}

.ushop-webview-footer-icon {
  font-size: 12;
  font-weight: bold;
}

.border-none {
  border: none;
}

.alignSelf-center {
  align-self: center;
}

.ushop-webview-footer {
  position: fixed;
  bottom: 0;
  z-index: 2222;
  left: 0;
  right: 0;
  text-align: center;
  height: 55px;
  /* border: 1px grey; */
  box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
  -webkit-box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
  -moz-box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
}

.ushop-webview-footer-container {
  height: 55px;
  border-top: 1px solid rgb(225, 231, 236);
  background-color: white;
}

.min-width-200 {
  min-width: 200px !important;
}

.max-height-180 {
  max-height: 180px;
}

.margin-right-10-imp {
  margin-right: 10px !important;
}

.padding-1x {
  padding: 24px;
}

.slick-slide-500.slick-slide {
  height: 500px !important;
}

.padding-left-15 {
  padding-left: 15px;
}

.absolute-center {
  margin: auto;
  left: 0;
  right: 0;
}

.margin-left-1x {
  margin-left: 24px !important;
}

.margin-bottom-3 {
  margin-bottom: 3px !important;
}

.height-30 {
  height: 30px;
  max-height: 30px;
}

.max-width-80x {
  max-width: 80px !important;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-right-null {
  padding-left: 0px;
  padding-right: 0px;
}

.max-width-100x {
  max-width: 100px;
}

.padding-left-1x {
  padding-left: 24px;
}

.padding-left-right-1x {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-left-null {
  padding-left: 0px !important;
}

.padding-010 {
  padding: 0 10% !important;
}

.fbshareicon {
  border-radius: 5px;
  cursor: pointer;
}

.twshareicon {
  border-radius: 5px;
  cursor: pointer;
}

.emailshareicon {
  border-radius: 5px;
  cursor: pointer;
}

.whatsappshareicon {
  border-radius: 5px;
  cursor: pointer;
}

.radio-button-style {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.padding-left-30 {
  padding-left: 30px;
}

.height-60 {
  height: 50px !important;
  max-height: 50px !important;
  min-height: 50px !important;
}

.padding-top-6 {
  padding-top: 6px !important;
}

.padding-top-10 {
  padding-top: 10px;
}

.text-success {
  color: #27ae60;
}

.button-center {
  margin: 0 auto;
  display: block;
}

.notification-icon {
  /*width: 30px;*/
  transition: background-color 0.35s;
  color: #606975;
  font-size: 14px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  padding-top: 4px;
}

.padding-24 {
  padding: 24px;
}

.btn-xs {
  height: 28px;
  padding: 5px 30px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 20px;
}

.genealogy-page-wrapper .btn {
  font-family: "Maven Pro", sans-serif !important;
  line-height: 0 !important;
  letter-spacing: 0 !important;
  font-weight: 100 !important;
  text-transform: unset;
  margin-right: 0px;
}

div#genealogyComponent .btn-show-all-less {
  line-height: 0px;
}

.notification-modal {
  width: 450px;
  background: white;
  position: fixed;
  right: 20px;
  top: 63px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
  z-index: 1000;
}

.width-60 {
  width: 60px;
}

.vertical-center-align {
  position: absolute !important;
  top: 44% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 50% !important;
  height: 50% !important;
}

.vertical-center-align-btn {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.padding-border-top-color {
  padding-top: 0px !important;
  border-top: 0px !important;
}

.min-width-225 {
  min-width: 225px;
}

.padding-15 {
  padding: 15px;
}

.margin-bottom-6 {
  margin-bottom: 6px;
}

.ref-table p {
  font-size: 16px;
}

.mob-cart-img {
  display: none;
}

.order-history p {
  margin-left: 33%;
}

.order-history img {
  margin-left: 33%;
}

.orderid-inline {
  display: inline-block;
}

.order-items .order-history {
  text-align: center;
}

.order-items .col {
  margin: auto;
}

.form-check label {
  text-align: center;
  display: block;
}

.form-check a {
  white-space: nowrap;
}

.form-check-input {
  margin-top: 0.44rem !important;
  margin-left: -2rem !important;
}

.enrollment-page div > h4 {
  text-align: center;
}

.custom-control .custom-control-label {
  padding-left: 5px;
}

.custom-control-label::before,
.custom-control-label::after {
  left: 0;
  top: 2px;
  width: 14px;
  height: 14px;
}

.form-city .pills {
  margin-top: 0;
}

.form-city .dropdown-menu.show {
  max-height: 600px;
  overflow: scroll;
}

.form-bank .pills {
  margin-top: 0;
}

.form-bank .dropdown-menu.show {
  max-height: 600px;
  overflow: scroll;
}

.slick-list .slick-slide .header {
  font-size: 40px;
}

.slick-list .slick-slide .body {
  font-size: 18px;
}

.slick-list .slick-slide .mb_header {
  font-size: 28px;
}

.checkTC {
  width: 320px;
}

.checkTC-VN {
  width: 267px;
}

.TH .checkTC {
  width: 240px;
}

.checkTC label {
  width: 300px;
}

.pills .btn {
  text-transform: capitalize;
}

a.order-button-report:not([href]):not([tabindex]) {
  color: #48a8e9;
}

.order-button-report.active:not([href]):not([tabindex]) {
  color: #f0f0f0;
}

.order-button-report.active {
  background-color: #0aa9ef;
}

.order-button-report.active::before {
  font-family: feather;
  content: "\e042";
  margin-right: 5px;
}

.not-login .custom-control-label::before,
.not-login .custom-control-label::after {
  left: 2px;
}

.scrollable-desktop {
  margin: 0 -15px;
}

.scrollable-desktop button:first-child {
  margin-left: 16px;
}

.scrollable-desktop button:last-child {
  margin-right: 16px;
}

.scrollable {
  overflow: auto;
  white-space: nowrap;
  margin: 0 -15px;
}

.scrollable button:first-child {
  margin-left: 16px;
}

.scrollable button:last-child {
  margin-right: 16px;
}

i.icon-bag {
  top: 4px !important;
}

i.icon-head {
  top: 5px !important;
}

.row.img-footer-japan,
.row.img-footer-vietnam {
  margin-top: 87px;
}

.font-size-genealogy-screen {
  font-size: 16px;
}

.font-size-genealogy-screen-small {
  font-size: 12px;
}

@media only screen and (max-width: 320px) {
  .youtube-vid {
    padding: 15px;
    border-radius: 10px !important;
  }
  .look-forward {
    padding: 15px;
    border-radius: 10px !important;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px;
  }
  .media-border-radius {
    border-radius: 10px !important;
  }
  .image-object-fit {
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  .margin-top-20 {
    margin-top: 08px;
  }
  .small-device-text-right {
    text-align: right;
  }
  .small-device-margin-top-18 {
    margin-top: 18px;
  }
  .border-radius-promotional-pic {
    border-radius: 10px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 70px;
  }
  .single-product-resp-img {
    max-height: 250px;
  }
  .order-history p {
    margin-left: 10%;
  }
  .order-history img {
    margin-left: 10%;
  }
  .agreeTC-center::before,
  .agreeTC-center::after {
    left: auto;
  }
  .checkTC {
    width: 190px;
  }
  .checkTC label {
    width: 210px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 750px) {
  .seminar-side-tabs {
    height: 40px !important;
  }
}

@media only screen and (min-width: 1050px) and (max-width: 2560px) {
  .seminar-side-tabs {
    height: 40px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .order-history p {
    margin-left: 10%;
  }
  .order-history img {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .agreeTC-center::before,
  .agreeTC-center::after {
    left: 50px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 419px) {
  .order-history p {
    margin-left: 20%;
  }
  .order-history img {
    margin-left: 20%;
  }
}

@media only screen and (max-width: 480px) {
  .youtube-vid {
    padding: 15px;
    border-radius: 10px !important;
  }
  .look-forward {
    padding: 15px;
    border-radius: 10px !important;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px;
  }
  .media-border-radius {
    border-radius: 10px !important;
  }
  .image-object-fit {
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .small-device-text-right {
    text-align: right;
  }
  .small-device-margin-top-18 {
    margin-top: 18px;
  }
  .border-radius-promotional-pic {
    border-radius: 10px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 70px;
  }
  .single-product-resp-img {
    max-height: 270px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .youtube-vid {
    padding: 15px;
  }
  .look-forward {
    padding: 15px;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px;
  }
  .promotional-countDown-padding {
    padding: 15px;
  }
  .media-border-radius {
    border-radius: 10px !important;
  }
  .image-object-fit {
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .border-radius-promotional-pic {
    border-radius: 10px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 75px;
  }
  .font-size-genealogy-screen {
    font-size: 3.1vw;
    font-family: db-helvethaica-x;
    letter-spacing: -0.9px;
  }
  #genealogySnapshot {
    padding-left: 0px;
    padding-right: 0px;
  }
  #genealogySnapshot .col-4 {
    flex: 0 0 30.3333333333%;
    max-width: 30.333333%;
  }
  #genealogySnapshot .col-8 {
    flex: 69%;
    max-width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .youtube-vid {
    padding: 15px 0 15px 15px;
  }
  .look-forward {
    padding: 15px 15px 15px 1px;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px 15px 15px 0;
  }
  .promotional-countDown-padding {
    padding: 15px;
  }
  .border-radius-left-top-bottom {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .border-radius-right-top-bottom {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    -webkit-border-top-right-radius: 10px !important;
    -webkit-border-bottom-right-radius: 10px !important;
  }
  .border-radius-promotional-pic {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .event-promotional-border-radius {
    border-radius: 10px !important;
  }
  .image-object-fit {
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .min-height-promotional-pic {
    min-height: 421px;
  }
  .min-height-look-forward-pic {
    min-height: 340px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 80px;
  }
  .global-connected-button-right,
  .global-connected-button-left {
    padding: 5px 15px !important;
  }
  .global-connected-button-left {
    margin-left: 20px;
  }
  .gene-search {
    float: left;
  }
  .slick-initialized .slick-slide .product-card .product-thumb {
    display: block;
  }
}

@media only screen and (min-width: 959px) and (max-width: 1200px) {
  .min-height-promotional-pic {
    min-height: 421px;
  }
  .promotional-countDown-padding {
    padding: 15px;
  }
  .youtube-vid {
    padding: 15px 0 15px 15px;
  }
  .look-forward {
    padding: 15px 15px 15px 1px;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px 15px 15px 0;
  }
  .min-height-promotional-pic {
    min-height: 421px;
  }
  .min-height-look-forward-pic {
    min-height: 430px;
  }
  .border-radius-left-top-bottom {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .border-radius-right-top-bottom {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    -webkit-border-top-right-radius: 10px !important;
    -webkit-border-bottom-right-radius: 10px !important;
  }
  .border-radius-promotional-pic {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 80px;
  }
}

@media only screen and (min-width: 959px) and (max-width: 991px) {
  .border-radius-promotional-pic {
    border-radius: 10px;
  }
  .event-promotional-border-radius {
    border-radius: 10px !important;
  }
  .promotional-countDown-padding {
    padding: 15px;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px 15px 15px 0;
  }
  .responsive-top {
    top: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 2560px) {
  .youtube-vid {
    padding: 15px 0 15px 15px;
  }
  .look-forward {
    padding: 15px 15px 15px 1px;
  }
  .promotional-countDown-left-no-padding {
    padding: 15px 15px 15px 0;
  }
  .border-radius-left-top-bottom {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .border-radius-right-top-bottom {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    -webkit-border-top-right-radius: 10px !important;
    -webkit-border-bottom-right-radius: 10px !important;
  }
  .border-radius-promotional-pic {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .min-height-promotional-pic {
    min-height: 421px;
  }
  .min-height-look-forward-pic {
    min-height: 430px;
  }
  .border-shadow-count {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .border-shadow-delivery {
    -moz-box-shadow: 0px 1px 1px #ccc;
    box-shadow: 0px 1px 1px #ccc;
  }
  .responsive-top {
    top: 80px;
  }
}

.minHeight230 {
  min-height: 230px !important;
}

.unicity-place {
  font-size: 25px;
  color: red;
}

.border-normal-radius {
  border-radius: 10px;
}

.border-radius-left-top-bottom {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bottom-click-button {
  position: absolute;
  margin-left: -100px !important;
  left: 50%;
  width: 200px;
  bottom: 24px;
}

.bottom-click-button-copy {
  top: 0;
}

.bottom-click-button-copy-mobile {
  bottom: 10px !important;
}

.bottom-click-button-space {
  bottom: -20px !important;
}

.navi,
.infoi {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.infoi {
  z-index: 10;
}

.bottom-place-text {
  position: absolute;
  bottom: 65px;
  right: 0;
}

.bottom-place-button {
  position: absolute;
  bottom: 15px;
  right: 0;
}

.margin-right-10 {
  margin-right: 10px;
}

.shopping-summary table td:first-child {
  text-align: right;
}

.shopping-summary table td:nth-child(2) {
  padding-left: 0;
}

@media (max-width: 330px) {
  .element {
    background: #cccccc;
  }
}

@media (max-width: 860px) and (min-width: 767px) {
  .element {
    background: #888888;
  }
}

@media (max-width: 897px) and (min-width: 321px) {
  .element {
    background: #222222;
  }
}

.opacity-0 {
  opacity: 0 !important;
}

.vertical-center-span {
  display: inline-flex;
  align-items: center;
}

.width-225 {
  width: 225px !important;
}

.width-175 {
  width: 175px !important;
}

.dropDownPlace {
  float: right;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
}

.search-style {
  height: 50px !important;
  position: relative !important;
}

.search-style .site-search .search-tools {
  margin-top: 15px !important;
}

.shopping-search {
  padding-right: 35px !important;
  padding-left: 0px !important;
}

.site-search .search-tools .close-search {
  margin-left: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
}

.input-range__label {
  font-family: "Maven Pro", san-serif !important;
}

.lsb-header {
  background: #606975;
  color: #ffffff;
}

.display-block {
  display: block !important;
}

.profile-pic {
  height: 115px;
  width: 115px;
  border-radius: 50%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
}

.profile-pic-sm {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 20px;
  /*margin-bottom: 20px;*/
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 10;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
}

.user-avatar:hover .overlay {
  opacity: 0.9;
}

.iconUpload:hover .overlay {
  opacity: 1;
}

.iconUpload {
  color: #48a8e9;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.fa-upload:hover {
  color: #48a8e9;
  opacity: 1;
}

.over-flow {
  overflow-y: scroll;
  height: 350px;
  left: -20px !important;
  text-align: center !important;
}

.vertical-middle {
  vertical-align: middle !important;
}

.min-width-120 {
  min-width: 120px;
}

.min-width-90 {
  min-width: 90px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.profile-pic-side {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  margin: 0px 0px 0px 0px;
  border: 1px solid white;
  padding: 1px;
}

.wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-height: 1.25em;
  /* fallback */
  max-height: 2.5em;
  height: 2.5em;
  display: block;
}

.minHeight {
  height: 150px !important;
}

.productImage {
  width: auto;
  height: 200px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.margin-top-medium {
  margin-top: 08px !important;
}

.place-text-center {
  text-align: center !important;
  left: -45px !important;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item:hover {
  color: #0da9ef !important;
}

.separator-line {
  position: relative;
  border-left: 1px solid;
  right: -149px;
  z-index: 2;
  border-color: white;
}

/* padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left); */

/* End of general */

/** Utilities */

.hover-shadow-qs {
  transition: all 0.25s ease-in-out;
}

.hover-shadow-qs:hover {
  -webkit-box-shadow: 0px 0px 3px 3px #ccc;
  -moz-box-shadow: 0px 0px 3px 3px #ccc;
  box-shadow: 0px 0px 3px 3px #ccc;
}

.hover-shadow {
  transition: all 0.25s ease-in-out;
}

.hover-shadow:hover {
  /* -webkit-box-shadow: 0px 0px 7px 0px rgba(189, 189, 189, 1);
    -moz-box-shadow: 0px 0px 7px 0px rgba(189, 189, 189, 1);
    box-shadow: 0px 0px 7px 0px rgba(189, 189, 189, 1); */
  -webkit-box-shadow: 0px 0px 3px 3px #ccc;
  -moz-box-shadow: 0px 0px 3px 3px #ccc;
  box-shadow: 0px 0px 3px 3px #ccc;
}

.hover-shadow-primary {
  transition: all 0.25s ease-in-out;
}

.hover-shadow-primary:hover {
  -webkit-box-shadow: 0px 0px 2px 1px #0da9ef;
  -moz-box-shadow: 0px 0px 2px 1px #0da9ef;
  box-shadow: 0px 0px 2px 1px #0da9ef;
}

.focus-shadow-primary {
  transition: all 0.25s ease-in-out;
}

.focus-shadow-primary:focus {
  -webkit-box-shadow: 0px 0px 2px 2px #0da9ef;
  -moz-box-shadow: 0px 0px 2px 2px #0da9ef;
  box-shadow: 0px 0px 2px 2px #0da9ef !important;
}


.img-promotions {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
}

.footer-phone a {
  text-decoration: none;
  color: white;
}

.footer-phone a:hover {
  color: white;
}

.hide {
  display: none !important;
}

.no-margin {
  margin: 0px;
}

.sidebar-hover {
  transition: all 0.1s ease-in-out;
}

.sidebar-div-hover :hover .sidebar-hover {
  color: #0da9ef;
  transition: all 0.1s ease-in-out;
}

.sidebar-hover:hover > span {
  color: #0da9ef;
  transition: all 0.1s ease-in-out;
}

.success-label {
  position: relative;
  z-index: 2;
  color: #3c4246;
  /* color:white; */
  bottom: 1px;
}

.loadingImage {
  min-height: 75px;
  background-position: center;
  background-image: url(../assets/loadding.gif);
  background-repeat: no-repeat;
  background-size: 75px;
}

.loadingImagePH {
  min-height: 75px;
  background-position: center;
  background-image: url(../assets/loaddiing.gif);
  background-repeat: no-repeat;
  background-size: 75px;
}

.loading-image-container {
  width: 75px !important;
  height: 75px !important;
  -webkit-transform: rotateZ(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  transform: rotateZ(0deg) !important;
}

.success-position {
  position: relative;
  right: 30px;
}

.no-padding {
  padding: 0px !important;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.top-0 {
  top: 0px !important;
}

.top-40 {
  top: 40px !important;
}

.padding-top-20 {
  padding-top: 20px;
}

.bg-white {
  background-color: #ffffff;
}

.width-300 {
  width: 300px !important;
}

.width-30 {
  width: 30px !important;
}

.profile-Icon {
  display: inline-block;
  border-radius: 60px;
  box-shadow: rgb(136, 136, 136) 0px 0px 2px;
  position: absolute;
  right: 8px;
  padding: 0px 8px;
  background-color: #fff;
  font-size: 12px;
  line-height: 28px;
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: none;
}

.slick-slide.slick-active {
  z-index: 999;
}

.profile-label {
  position: absolute;
  right: 0;
  top: 0;
}

.inline-block {
  display: inline-block;
}

.push-overlay {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1001;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}

.scroll-to-top-btn {
  border: 0px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  right: 32px !important;
}

.scroll-to-top-btn.visible {
  border: 0px;
  bottom: 40px;
}

.scroll-to-top-btn .fa-arrow-up {
  bottom: 53px;
}

.scroll-to-top-btn.fb-open {
  bottom: 100px;
}

.scroll-to-top-btn.fb-open .fa-arrow-up {
  bottom: 100px;
}

.scroll-to-top-btn.visible.fb-open {
  bottom: 100px;
}

.scroll-to-top-btn.visible.fb-open .fa-arrow-up {
  bottom: 113px;
}

.progress-fixed {
  width: 100%;
  padding-top: 20px;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  background: white;
  transition: 0.5s;
}

.redirect-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.redirect-loading-container-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  width: 100vw;
}

.progress-inner {
  border-radius: 4px;
  position: absolute;
  left: 15px;
  right: 15px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.progress-point {
  border-radius: 30px;
  position: absolute;
  margin-top: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.sub-menu-separator {
  margin-top: 5px;
  margin-bottom: 3px;
  border-top: 1px solid #e1e7ec;
}

.next-btn {
  height: 35px;
  background: #0da9ef;
  border: 0;
  color: #fff;
  width: 100px;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 3px;
  margin-bottom: 10px;
  cursor: pointer;
}

.danger-btn {
  background-color: #ff5252;
  border: 0px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
}

.info-btn {
  background-color: #2ab8f5;
  border: 0px;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  padding-top: 5px;
  margin-right: 5px;
  cursor: pointer;
  padding-bottom: 5px;
}

.primary-btn {
  background-color: #2ab8f5;
  border: 0px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: px;
}

.primary-btn:hover {
  background-color: #2ab8f5;
  border: 0px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
}

.shortcut {
  background-color: white;
  color: black;
}

.order-heading {
  font-size: 25px;
  border-bottom: 1px solid;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
  margin-bottom: 0px;
}

.fa-dollar {
  margin-left: 3.5px !important;
  margin-right: 6.5px !important;
}

.fa-usd {
  color: #2ab8f5;
}

.fa-list-ul {
  color: #2ab8f5;
}

.fa-users {
  color: #2ab8f5;
}

.report-back {
  background-color: transparent;
  border: 1px solid #2ab8f5;
  color: #2ab8f5;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
}

.profileCard {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
  box-shadow: 0 1px 8px 0 rgba(56, 56, 56, 0.2);
}

.orderBtn {
  text-align: center;
  border: 1px solid #2ab8f5;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}

.orderBtnM {
  text-align: center;
  border: 1px solid #2ab8f5;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.successContainer {
  width: 80%;
  /* border: 1px solid #CCC; */
  border-radius: 5px;
  padding: 10px;
  margin-right: -25px;
}

.reportPic {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
  border: 1px solid white;
  border-radius: 50%;
}

.report-table-title {
  text-align: left;
  font-weight: bold;
}

.report-table-data {
  text-align: left;
}

.back-to-gene {
  margin-left: 20px;
  margin-top: 20px;
}

.report-btn {
  background-color: white;
  color: #2ab8f5;
  border-color: #2ab8f5;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
  margin-bottom: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
}

.report-dropdown {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  padding-top: 5px;
  cursor: pointer;
  padding-bottom: 5px;
  margin-bottom: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
}

.report-facet {
  position: absolute;
  padding-top: 4px;
  padding-left: 4px;
}

.details-table > tbody > tr {
  border: 1px solid #ccc;
}

.details-table > tbody > tr > td:first-child {
  border-right: 1px solid #ccc;
}

.report-user-view-table-col {
  padding: 0px;
}

.report-user-view-table > thead > tr {
  border: 1px solid #ccc;
}

.report-user-view-table > tbody > tr {
  border: 1px solid #ccc;
}

.report-user-view-table > tbody > tr > td {
  border-right: 1px solid #ccc;
}

.detail-btn {
  background-color: #2ab8f5;
  border: 0px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 30px;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.hr-style {
  border: 0;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 15px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #2ab8f5, rgba(0, 0, 0, 0));
}

.font-style {
  font-weight: bolder;
}

.report-inline-text {
  display: inline;
  margin: 0px;
}

.inline-text-table {
  display: inline;
  margin: 0px;
}

.dropdown-items {
  cursor: pointer;
  background-color: white;
}

.dropdown-items a:hover {
  background-color: #f5f5f5 !important;
}

.report-select-text {
  margin-top: 4px;
}

.detail-report-user-table > tbody {
  width: 100%;
}

/* .detail-report-user-table>tbody>tr>td {
} */

.detail-report-user-table > tbody > tr > td:nth-child(odd) {
  font-weight: bold;
}

.qs-row .container {
  width: auto;
}

/* .detail-report-user-table > tbody > tr:nth-child(odd){
    background-color: #F2F2F2;
} */

.order-button-report {
  border: 1px solid;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 230px;
  display: block;
  margin: 0 auto;
}

.global-connected-button-left {
  background-color: #f5f5f5;
  border: 1px solid #e1e7ec;
  color: gray;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 5px 47px;
  cursor: pointer;
  max-width: 100% !important;
}

.global-connected-button-middle {
  background-color: #f5f5f5;
  border: 1px solid #e1e7ec;
  color: gray;
  padding: 5px 20px;
  cursor: pointer;
}

.global-connected-button-right {
  background-color: #f5f5f5;
  border: 1px solid #e1e7ec;
  color: gray;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px 47px;
  cursor: pointer;
  max-width: 100% !important;
}

.global-connected-button-active {
  background-color: #2ab8f5;
  border: 1px solid #2ab8f5;
  color: white;
}

.next-btn.disabled,
.next-btn:disabled {
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}

.input-spinner {
  position: absolute;
  top: 45px;
  right: 50px;
}

.form-control.alert,
.form-control:disabled {
  border-radius: 44px;
}

.page-title h1 {
  width: 100% !important;
  color: black !important;
  max-width: 100% !important;
}

.nav-link {
  cursor: pointer;
}

.circle-75 {
  color: #2bd395;
  padding-top: 25px;
  font-weight: bold;
  font-size: 40px;
}

.switch-shop {
  cursor: pointer;
  font-weight: bold;
  width: auto;
}

.switch-shop.active {
  border-bottom: 1px solid #0da9ef;
}

/** End of utilities */

/** Top Navigation */

.route-wrapper .topbar {
  min-height: 40px;
}

/** End of top navigation */

/** Left Side Navigation */

.hamburger-container {
  width: 74px !important;
}

.main-menu {
  transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

.offmenu {
  transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

.menu-cell {
  width: 100%;
  height: 50px;
  display: table !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  cursor: pointer;
}

.menu-inner-cell {
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}

.hello-fa {
  margin-left: 20px;
}

.hello-name {
  font-weight: bold;
  display: inline;
  font-size: 16px;
}

.hello-user {
  margin-left: 10px;
  font-weight: normal;
  display: inline;
  font-size: 16px;
}

.side-bar-login {
  margin-left: 20px;
  font-size: 16px;
}

.active-sidebar {
  cursor: pointer;
  color: #0da9ef;
}

.caret-container,
.menu-inner-cell {
  display: table-cell;
  vertical-align: middle;
}

.caret-container {
  text-align: center !important;
}

.caret-cell,
.menu-cell {
  height: 50px;
  display: table;
}

.menu-cell {
  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.12);
  cursor: pointer;
}

.caret-cell {
  border-left: 1px solid hsla(0, 0%, 100%, 0.12);
  width: 50px;
  float: right;
}

.menu-container,
#menu-container {
  background-color: #374250;
  -webkit-overflow-scrolling: touch;
  color: white;
  width: 250px;
  margin-top: 0px;
}

.topbar {
  background-color: #f5f5f5;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  top: 0;
  bottom: 0;
  z-index: 998 !important;
  padding-right: 15px;
  padding-left: 15px;
}

.slick-prev {
  /* left: 15px !important; */
  width: 20px;
  height: 42px !important;
  padding-right: 40px !important;
  display: block;
  height: 100% !important;
  padding-left: 20px !important;
  left: 0px !important;
}

.slick-prev::before {
  left: 15px !important;
  font-family: "FontAwesome" !important;
  content: "\f104" !important;
  font-size: 40px !important;
}

.slick-next {
  /* right: 25px !important; */
  width: 20px;
  height: 42px !important;
  display: block;
  height: 100% !important;
  padding-right: 30px !important;
  padding-left: 20px !important;
  right: 0px !important;
}

.slick-next::before {
  right: 15px !important;
  font-family: "FontAwesome" !important;
  content: "\f105" !important;
  font-size: 40px !important;
}

.route-wrapper {
  z-index: 2;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
}

/** End of left side navigation */

/** Login */

.lang-currency-switcher-wrap .dropdown-menu {
  left: -104px;
  padding-bottom: 12px;
  overflow: hidden;
  width: 200px;
  z-index: 1001;
  top: 30px;
  display: block;
}

/** End of login */

/** Header */

.navbar {
  min-height: 78px !important;
}

.name-header {
  font-size: 14px !important;
  color: #0da9ef !important;
  padding-top: 10px !important;
}

.download-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.offcanvas-toggle {
  border-right: 0px;
  position: relative !important;
  width: 50px;
  height: 80px;
}

.offcanvas-toggle {
  width: 74px;
  margin-right: 20px;
}

.offcanvas-toggle::before {
  display: none;
}

.box-center {
  text-align: center;
}

.box-center-inner {
  margin: 0 auto;
}

.navbar.navbar-stuck {
  width: 100%;
  z-index: 10;
}

.site-search {
  left: 0;
  top: 0px;
}

.route-wrapper.navbar {
  min-height: 78px;
}

.navbar.navbar-stuck {
  right: 1em;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 25px 0 rgba(47, 56, 68, 0.22);
}

.navbar {
  z-index: 997 !important;
}

.site-branding {
  height: 77px;
  overflow-y: hidden;
}

.lang-currency-switcher-wrap {
  /* margin-right: 20px; */
  margin-left: 0px;
}

.lang-currency-switcher-wrap .lang-currency-switcher.login {
  margin-top: -7px;
}

.lang-currency-switcher-wrap .lang-currency-switcher > .language {
  width: 40px;
  margin-top: -5px;
}

.lang-currency-switcher-wrap .lang-currency-switcher > .currency {
  margin-top: 0px;
}

.language-title {
  margin-left: 20px;
  margin-bottom: 10px;
}

/* Home */

.remove-from-cart {
  color: #ff5252 !important;
  cursor: pointer;
}

.clear-cart-btn:hover {
  color: white !important;
}

.warning-checkout {
  color: red;
}

.viewprod {
  height: 40px !important;
  font-weight: bold;
}

.logo-product {
  height: 70px;
}

.under-logo-title {
  font-size: 30px;
  font-weight: bold;
}

.single-product-case {
  border: 1px solid #e1e7ec;
  border-radius: 5px;
}

.single-product-case > img {
  border-radius: 5px;
  height: auto;
}

.single-product-title {
  font-size: 30px;
  color: black;
}

.single-product-price {
  font-size: 30px;
  color: black;
  font-weight: 500;
}

.service-img {
  /*border: 1px solid #e1e7ec;*/
  width: 100px;
  /*border-radius: 50px;*/
  margin: 0px auto;
  margin-top: 10px;
}

.uniBanner {
  background: url(../assets/bg_test.jpg) 20% 0% / cover !important;
}

.omegaBanner {
  background: url(../assets/omega_bg.jpg) 47% 0% / cover !important;
}

.biosBanner {
  background: url(../assets/bg_bios.png) 47% 0% / cover !important;
}

.hipster-dude {
  background-image: url("http://themes.rokaux.com/unishop/v1.3/img/banners/home01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 450px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.limited-offer {
  color: #ff5252 !important;
  font-weight: 500;
  margin-bottom: 0px;
}

.margin-3 {
  margin: 3px !important;
}

.offer-left {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.pop-brands {
  background-color: #f5f5f5;
}

.all-products {
  color: gray !important;
  background-color: white !important;
  border: 1px solid #e1e7ec !important;
  border-radius: 20px;
  height: 40px;
  min-width: 150px;
  font-weight: 500;
  cursor: pointer;
  padding: 5px 20px;
}

.product-card {
  height: 100%;
}

.suggested-slider .product-card {
  height: auto;
}

.suggested-slider .product-card .product-thumb {
  width: 100%;
  /* height: 200px; */
}

.suggested-slider-mobile .product-card {
  height: auto;
}

.suggested-slider-mobile .product-card .product-thumb {
  width: 100%;
  /* height: 200px; */
}

/* hiding all bullets by default */

.slick-slider.suggested-slider .slick-dots li + li + li + li + li + li + li + li {
  display: none !important;
}

.slick-slider.suggested-slider-mobile .slick-dots li + li + li + li + li {
  display: none !important;
}

/* only displaying the active bullets and the 2 bullets next to it */

/* .slick-slider.suggested-slider .slick-dots li.slick-active,
.slick-slider.suggested-slider .slick-dots li.slick-active + li,
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li,
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li + li
 {
    display: inline-block!important;
} */

/* displaying the last three bullets when slick-active class isn't applied to any li before them  */

/* .slick-slider.suggested-slider .slick-dots li:nth-last-child(1),
.slick-slider.suggested-slider .slick-dots li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li:nth-last-child(4) {
    display: inline-block!important;
} */

/* hiding the last three bullets if slick-active exist before them */

/* .slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(1),
.slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li.slick-active ~ li:nth-last-child(4) {
    display: none!important;
} */

/* specific conditions to always display the last three bullets */

/* .slick-slider.suggested-slider .slick-dots li.slick-active + li + li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li.slick-active + li + li:nth-last-child(1),
.slick-slider.suggested-slider .slick-dots li.slick-active + li:nth-last-child(3),
.slick-slider.suggested-slider .slick-dots li.slick-active + li:nth-last-child(2),
.slick-slider.suggested-slider .slick-dots li.slick-active + li:nth-last-child(1){
    display: inline-block!important;
} */

.product-card .product-thumb {
  margin-bottom: auto;
  margin: auto;
}

.product-image {
  display: flex;
  margin: auto;
  height: 220px;
  align-items: center;
}

.product-card .product-thumb img {
  width: auto;
  margin: auto;
}

.productImage {
  width: auto;
  max-width: 200px;
  height: auto;
  max-height: 200px;
}

.cart-img {
  width: 160px;
  text-align: center;
  padding-left: 5px;
}

.cart-img > img {
  width: auto;
  max-width: 110px;
  height: auto;
  max-height: 90px;
}

.small-right-product {
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.small-right-product > img {
  height: auto;
  max-height: 80px;
}

/*
.product-thumb {
    height: 220px;
}*/

.top-cat-cell > .container-fluid {
  height: 209px;
}

.top-cat-cell > .container-fluid > .row {
  height: 100%;
}

.border-right {
  width: 100%;
}

.left-prod-cat {
  margin: auto;
  vertical-align: middle;
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

.left-prod-cat img {
  height: auto;
  max-height: 179px;
}

.padding-top-10 {
  padding-top: 12px;
}

.right-prod-cat {
  width: 125px;
  margin: auto;
  width: 50%;
  display: flex;
  align-items: center;
}

.right-prod-cat > img {
  border-radius: 7px;
  max-width: 94px;
  max-height: 83px;
}

.top-cat-cell {
  border-radius: 7px;
  border: 1px solid #e1e7ec;
  margin-bottom: 20px;
}

.view-product-btn {
  width: 50% !important;
}

.left-prod-cat > img {
  border-radius: 7px;
}

.cat-shop {
  font-weight: 500;
}

.input-range__track--active {
  background-color: #9da9b9 !important;
  height: 0.4rem !important;
}

.input-range__slider {
  height: 95px;
  background: white !important;
  border: 4px solid #9da9b9 !important;
  width: 1.6rem !important;
  height: 1.6rem !important;
  margin-top: -1rem !important;
  margin-left: -0.8rem !important;
}

.input-range__label-container {
  line-height: 7rem !important;
  font-size: 1.25rem !important;
  font-weight: 300 !important;
}

.range__slider-range::-moz-range-thumb {
  width: 35px !important;
  height: 35px !important;
  background: #4caf50;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.range-slider-container {
  margin-right: 20px;
  margin-left: 20px;
}

.slick-slide {
  background-size: cover !important;
  height: 520px !important;
  background-position: top left !important;
}

.shopping-cart .product-button {
  padding-bottom: 8px;
  width: 230px;
}

.shopping-cart td.product-detail {
  padding-left: 0px;
  width: 700px;
  overflow: hidden;
}

@media screen and (max-width: 1440px) {
  .slick-slide {
    background-size: cover !important;
    background-position: center center !important;
    height: 480px !important;
  }
}

@media screen and (max-width: 1280px) {
  .slick-slide {
    height: 340px !important;
  }
}

@media screen and (max-width: 1024px) and (max-height: 800px) {
  .slick-slide {
    height: 350px !important;
  }
}

@media screen and (max-width: 1024px) and (min-height: 1365px) {
  .slick-slide {
    height: 820px !important;
  }
}

@media screen and (max-width: 960px) {
  .slick-slide {
    height: 620px !important;
  }
}

@media screen and (max-width: 414px) {
  .slick-slide {
    background-size: cover !important;
    background-position: top left !important;
    height: 330px !important;
  }
}

@media screen and (max-width: 375px) {
  .slick-slide {
    background-size: cover !important;
    background-position: top left !important;
    height: 305px !important;
  }
}

@media screen and (max-width: 320px) {
  .slick-slide {
    background-size: cover !important;
    background-position: top left !important;
    height: 260px !important;
  }
}

.slides-spinner {
  width: 100%;
  height: 580px;
}

.gallary-slides {
  width: 100%;
  height: 500px;
}

.img-container {
  background-image: url(../assets/main-bg.jpg);
  width: 100%;
  height: 580px;
  background-position: center;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.sliderImg {
  width: auto;
  height: 315px;
  overflow: hidden;
  z-index: 2;
  background-size: cover;
  position: relative;
  top: 27px;
}

.sliderImgBios {
  width: auto;
  height: 220px;
  overflow: hidden;
  z-index: 2;
  background-size: cover;
  position: relative;
  top: 55px;
}

.sliderImgUni {
  width: auto;
  height: 220px;
  overflow: hidden;
  z-index: 2;
  background-size: cover;
  position: relative;
}

.title-pos {
  position: relative;
  top: 80px;
}

.label-pos {
  position: relative;
  top: 60px;
}

.button-pos {
  position: relative;
  top: 90px;
}

.slider-title {
  font-size: 25px;
}

.slider-label {
  font-family: "Maven Pro", Helvetica, Arial, sans-serif;
}

.slider-button-label {
  text-align: center;
  justify-content: center;
  margin-bottom: 0;
  background: linear-gradient(to bottom right, rgb(58, 129, 88), #92d153);
  padding: 10px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.slider-button-label-2 {
  text-align: center;
  justify-content: center;
  margin-bottom: 0;
  background: linear-gradient(to bottom right, #d64c4e, rgb(69, 50, 119));
  padding: 10px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

/* .gallary-img{
    display: flex;
    flex: 0.5;
    justify-content: flex-start;
    align-items: center;
}
.gallary-btn{
    flex-direction: column;
    display: flex;
    flex: 0.5;
    justify-content: center;
    align-items: flex-start;
}*/

.height100 {
  height: 100%;
}

.gallary-cells {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.gallary-cell-bottom-image {
  max-height: 270px !important;
}

.info-button > img {
  border: 1px solid #e1e7ec;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  color: gray;
  padding: 7px;
  position: absolute;
  right: calc(50% + 65px);
}

.info-button-detail > img {
  border: 1px solid #e1e7ec;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  color: gray;
  padding: 7px;
}

.qtyplusminusbtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 44px;
  overflow: hidden;
  padding: 0px 20px;
  /*position: relative;*/
}

/*.qtyplusminusbtn:hover{
    background-color: #48A8E9;
}*/

.borderRight {
  border-right: 1px solid #e1e7ec;
}

.borderLeft {
  border-left: 1px solid #e1e7ec;
}

.qtyInput {
  border-width: 1px;
  border-style: solid;
  border-color: #e1e7ec;
  border-radius: 5px;
}

.singleProduct .qtyInput {
  margin-right: 0px;
}

.qtyInput .form-control-number-new.x {
  border-radius: 5px;
  text-align: center;
}

.form-control-number-new {
  width: 50px;
  height: 44px;
  /*margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 5px 3px;
    border: 1px solid #dbe2e8;
    border-radius: 22px;*/
  border: none;
  overflow: hidden;
  background-color: #fff;
  color: #606975;
  font-family: Maven Pro, Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.shopping-cart .qtyInput .form-control-number-new {
  border-radius: 5px;
}

.QtyDetail button {
  position: relative;
  /*top: 2px;*/
  height: 40px;
  /* width: 40px; */
  width: 32%;
  max-width: 40px;
  border-radius: 0px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  cursor: pointer;
  border-color: #e1e7ec;
  transition: all 0.3s ease;
  background-color: transparent;
  box-shadow: 0px 0px 0px;
  border: 1px solid #e1e7ec;
}

.QtyDetail button:hover {
  border-color: #48a8e9;
}

.QtyDetail input {
  border-radius: 0px;
  height: 40px;
  border-left: 0px;
  border-right: 0px;
  padding-bottom: 5px;
}

.QtyDetail button > i {
  position: relative;
  right: 5px;
  color: #303a46;
}

.QtyDetail span:first-child button {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.QtyDetail span:last-child button {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.plus-minus {
  color: #424749 !important;
  background-color: white !important;
  border: 1px solid #424749 !important;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.input-detail {
  color: #757777 !important;
  background-color: white !important;
  border: 1px solid #757777 !important;
  border-radius: 5px;
  height: 30px;
  width: 40px;
  text-align: center;
}

.detail-image {
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.add-to-cart {
  color: #0da9ef !important;
  background-color: white !important;
  border: 1px solid #0da9ef !important;
  border-radius: 16px;
  padding: 3px 15px 5px 15px;
  min-width: 120px;
  margin-left: 10px;
  cursor: pointer;
}

.out-of-stock {
  color: gray !important;
  background-color: white !important;
  border: 1px solid #ccc !important;
  border-radius: 16px;
  padding: 3px 15px 5px 15px;
  margin-left: 10px;
  min-width: 120px;
}

.qs-row {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 30px;
  padding-bottom: 3px;
  padding-top: 3px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  box-shadow: 7px 8px 20px -10px rgba(47, 56, 68, 0.22);
}

.qs-title {
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
}

.qs-add-to-cart {
  color: #0da9ef !important;
  background-color: white !important;
  border: 1px solid #0da9ef !important;
  border-radius: 16px;
  width: 100%;
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

.qs-out-of-stock {
  color: gray !important;
  background-color: white !important;
  border: 1px solid #ccc !important;
  border-radius: 16px;
  width: 100%;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 5px;
  padding: 5px;
}

.expand-row {
  height: 20px;
  margin-left: 20px;
  font-size: 20px;
  color: green;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

.view-ba-detail {
  font-weight: 400;
}

.expand-btn {
  padding-bottom: 10px;
  width: 100%;
}

.alpha-down {
  margin: 0px;
  display: inline;
  margin-left: 10px;
  color: black;
  font-size: 17px;
}

.qs-quicknav {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  height: calc(100% - 77px);
  padding-top: 50px;
  z-index: 100;
}

.hidden-remove {
  margin-top: 10px;
}

.qsoption {
  text-align: center;
  cursor: pointer;
}

.qsoption:hover {
  color: #0da9ef;
}

.header-cart {
  margin-top: 12px;
  width: 18px;
  margin-right: 5px;
}

.basket-spin {
  color: #0da9ef;
}

.cart-div {
  float: left;
}

.cart-info {
  margin-top: 8px;
  float: left;
}

.cart-text {
  font-size: 12px;
  text-align: left;
  line-height: 13px;
}

.blue-man {
  color: #0da9ef;
}

.new-profile-login {
  background: white;
  position: fixed;
  right: 20px;
  top: 63px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
  z-index: 1000;
}

.hovbox-name {
  margin: 0px;
}

.hovbox-rank {
  color: #9da9b9;
}

.lessPadding {
  padding: 3px !important;
}

.after-login-hover-menu {
  width: 215px;
  background: white;
  position: fixed;
  /*right: 20px;*/
  top: 63px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 1000;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.new-login {
  width: 250px;
  background: white;
  position: fixed;
  right: 20px;
  top: 63px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
  z-index: 1000;
}

.new-login-ie {
  top: 103px !important;
}

.success-img-ie {
  padding-top: 20px;
  margin: 0 auto;
  max-width: 130px !important;
}

.new-login::selection {
  display: none;
}

.no-blue-highlight:active {
  color: inherit !important;
  background-color: white !important;
}

.no-blue-highlight:hover {
  border-radius: 5px;
  color: inherit !important;
  background-color: white !important;
}

/** End of Header */

/** Enroll */

.referral-link {
  text-align: center;
  margin-top: 10px;
  border: 1px solid #e1e7ec;
  width: 220px;
}

.generated-link {
  background-color: white;
  border: 1px solid #e1e7ec;
  color: #0da9ef;
}

.generated-link:hover {
  background-color: #f2f2f2;
}

.enrollment-page {
  min-height: 50vh;
}

.enroll-btns {
  transition: all 1000ms;
  width: 220px;
  height: 40px;
  font-weight: 500;
}

.quick-shop {
  margin-bottom: 80px;
}

.quick-shop .container {
  width: 100%;
}

.quick-shop .widget-categories > ul > li {
  cursor: pointer;
}

.quick-shop .widget-categories > ul > li > a {
  width: 100%;
  height: auto;
  padding: 15px;
}

.product-detail .widget-categories > ul > li > a:hover,
.quick-shop .widget-categories > ul > li > a:hover {
  background: #f2f2f2;
}

.quick-shop .widget-categories > ul > li > ul {
  margin-left: 40px;
}

.quick-shop .widget-categories > ul > li::before {
  content: unset !important;
  position: absolute;
  left: 0px;
}

.quick-shop .widget-categories > ul > li > ul > li .row {
  margin-top: 20px;
}

.quick-shop .btn-success,
.quick-shop .btn-success:hover,
.product-detail .btn-success,
.product-detail .btn-success:hover {
  background: #0da9ef !important;
}

.quick-shop .input-group .input-group-btn button,
.product-detail .input-group .input-group-btn button {
  font-size: 12px;
  color: white;
}

.quick-shop .btn-danger,
.product-detail .btn-danger {
  background: #c1c1c1 !important;
  border: 1px solid #c1c1c1;
}

.enroll-form {
  min-height: 400px;
}

.enroll-form .quick-shop {
  margin-top: 60px;
}

.quick-shop .input-group-btn {
  margin-top: 20px;
}

.quick-shop .input-group .input-group-addon,
.input-group .input-group-btn {
  position: unset;
}

.product-detail .input-group .input-group-btn {
  padding-top: 15px;
  color: #ffffff;
}

.product-detail .input-group .input-group-btn button {
  background: #000;
}

.widget-categories ul > li > ul > li .row .col-8 > a {
  border-left: 0px;
}

.widget-categories ul > li {
  float: left;
}

.page-header h6 {
  font-size: 28px;
  padding-top: 20px;
  padding-bottom: 40px;
}

.summary {
  list-style: none;
  padding-top: 20px;
}

.summary span {
  color: rgb(157, 157, 157);
}

.summary p {
  font-weight: bold;
  font-size: 20px;
}

.container.container-summary {
  width: 1200px;
}

.widget-categories ul > li::before,
.widget-links ul > li::before {
  content: unset;
}

.product-detail {
  margin-top: 25px;
}

.th-form-login {
  height: 275px;
}

@media only screen and (max-width: 576px) {
  .mobile-center {
    text-align: center;
  }
}

/** End of enroll */

/** Genealogy */

.gene-result-container {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  padding-top: 10px;
  margin-top: 10px;
}

.no-match {
  color: red;
}

.no-bookmark {
  color: red;
  margin-bottom: 15px;
}

.gene-close-search {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  right: 3px;
  top: 0;
  background: #ff5152;
  color: white;
  border: 0px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
}

.genealogy-search-modal {
  top: 180px;
  right: 0;
  left: 0;
  position: absolute;
}

.genealogy-search-mobile-modal {
  top: 30px;
  right: 0;
  left: 0;
  position: absolute;
}

.gene-result-title {
  margin-bottom: 10px;
  font-size: 20px;
}

.gene-result-item {
  text-decoration: none;
}

.time-gene {
  font-size: 14px;
  margin-left: 10px;
}

.new-left-cell {
  display: flex;
  flex-direction: row;
}

.new-left-inner {
  overflow-x: hidden;
  padding-top: 14px;
}

.left-cell-row::after {
  border-bottom: 1px solid #ccc;
}

.new-left-name {
  font-size: 13px;
  margin-left: 4px;
  display: flex;
  align-items: center;
}

.new-left-name:hover {
  color: #57bafc;
}

.new-left-name::-webkit-scrollbar {
  width: 0px;
  /* remove scrollbar space */
  background: transparent;
  display: none;
  /* optional: just make scrollbar invisible */
}

.new-left-name::-webkit-scrollbar-thumb {
  background: transparent;
  display: none;
}

.new-left-arrow {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  padding-right: 5px;
  overflow: hidden;
}

.address-table {
  width: 100%;
  margin: auto;
  border: 0px solid #e1e7ec;
}

.address-table > tbody > tr:hover {
  background: rgba(236, 236, 236, 0.425);
}

.address-table > thead {
  border-top: 1px solid #e1e7ec;
  border-bottom: 2px solid #e1e7ec;
  background-color: white;
  color: #606975;
  padding-top: 10px;
  padding-bottom: 10px;
}

.address-table > tbody > tr {
  border-bottom: 1px solid #e1e7ec;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
}

.address-table > thead > tr > th {
  width: 30%;
  text-align: center;
  color: #2f9ce6;
  font-size: 15px;
  padding: 5px;
}

.address-table > tbody > tr td {
  text-align: left;
  padding: 4px 0px 0 4px;
}

.address-table > tbody > tr td > i {
  cursor: pointer;
  padding: 15px;
}

.address-table .name,
.address-table .address {
  text-align: left;
}

.address-table .edit {
  margin: 0 auto;
}

.address-table .delete {
  width: 12%;
}

.vl {
  border-left: 1px solid #e1e7ec;
  height: 70%;
  vertical-align: middle;
  position: absolute;
  left: 40%;
  top: 15%;
}

.lsb-table {
  width: 100%;
  margin: auto;
  border: 1px solid #e1e7ec;
  border-radius: 5px;
  perspective: 1px;
  border-collapse: separate;
}

.lsb-table > thead {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: white;
  color: #606975;
  padding-top: 10px;
  padding-bottom: 10px;
}

.lsb-table > tbody > tr {
  border-bottom: 1px solid #ccc;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
}

.lsb-table > tbody > tr > td {
  border-right: 1px solid #ccc;
  text-align: center;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
  white-space: nowrap;
}

.lsb-table > tbody > tr > td:first-child {
  color: #49a8e9;
  font-weight: bold;
  text-align: center;
}

.lsb-table > tbody > tr > td:last-child {
  text-align: center;
}

.lsb-data {
  width: 100%;
  margin: auto;
  border: 1px solid #e1e7ec;
  border-collapse: separate;
  border-radius: 7px;
  border-spacing: 0px 10px;
}

.lsb-data > thead > tr {
  color: #ffffff;
  -webkit-box-shadow: 0px 5px 20px -10px #a3a3a3;
  -moz-box-shadow: 0px 5px 20px -10px #a3a3a3;
  box-shadow: 0px 5px 20px -10px #a3a3a3;
}

.lsb-data > thead > tr > th {
  position: relative;
  bottom: 5px;
}

.order-detail-table {
  width: 100%;
  margin: auto;
  border: 1px solid #e1e7ec;
}

.order-detail-table > tbody > tr:hover {
  background: rgba(236, 236, 236, 0.425);
}

.order-detail-table > thead {
  border-top: 1px solid #ccc;
  border-bottom: 2px solid #ccc;
  background-color: white;
  color: #606975;
  padding-top: 10px;
  padding-bottom: 10px;
}

.order-detail-table > tbody > tr {
  border-bottom: 1px solid #ccc;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
}

.order-detail-table > tbody > tr > td {
  border-right: 1px solid #ccc;
  text-align: center;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
  white-space: nowrap;
}

.order-detail-table > tbody > tr > td:first-child {
  /* cursor: pointer; */
  color: #0da9ef;
  font-weight: bold;
}

.order-detail-table > thead > tr > th {
  width: 20%;
  text-align: center;
}

.order-detail-table > tbody:hover td[rowspan] {
  background: rgba(236, 236, 236, 0.425);
}

.kpi-detail-table {
  width: 100%;
  margin: auto;
  border: 1px solid #e1e7ec;
}

.kpi-detail-table > tbody > tr:hover {
  background: rgba(236, 236, 236, 0.425);
}

.kpi-detail-table > thead {
  border-top: 1px solid #ccc;
  border-bottom: 2px solid #ccc;
  background-color: white;
  color: #606975;
  padding-top: 10px;
  padding-bottom: 10px;
}

.kpi-detail-table > tbody > tr {
  border-bottom: 1px solid #ccc;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
}

.kpi-detail-table > tbody > tr > td {
  border-right: 1px solid #ccc;
  text-align: center;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
  white-space: nowrap;
}

.kpi-detail-table > thead > tr > th {
  width: 20%;
  text-align: center;
}

.kpi-detail-table > tbody:hover td[rowspan] {
  background: rgba(236, 236, 236, 0.425);
}

.kpi-special-table {
  width: 100%;
  margin: auto;
  border: 1px solid #e1e7ec;
}

.kpi-special-table > tbody > tr:hover {
  background: rgba(236, 236, 236, 0.425);
}

.kpi-special-table > thead {
  border-top: 1px solid #ccc;
  border-bottom: 2px solid #ccc;
  background-color: white;
  color: #606975;
  padding-top: 10px;
  padding-bottom: 10px;
}

.kpi-special-table > tbody > tr {
  border-bottom: 1px solid #ccc;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
}

.kpi-special-table > tbody > tr > td {
  border-right: 1px solid #ccc;
  text-align: center;
  border-right: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-top: 0px solid #ccc;
  white-space: nowrap;
}

.kpi-special-table > tbody > tr > td:first-child {
  /* cursor: pointer; */
  color: #0da9ef;
  font-weight: bold;
}

.kpi-special-table > thead > tr > th {
  width: 20%;
  text-align: center;
}

.kpi-special-table > tbody:hover td[rowspan] {
  background: rgba(236, 236, 236, 0.425);
}

.close-modal-btn {
  position: absolute;
  right: -15px;
  top: -10px;
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
}

.genealogy-page-wrapper .btn-group {
  top: -3px;
}

.genealogy-modal-button {
  width: 250px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.genealogy-option-modal-top {
  height: 70vh;
}

.genealogy-option-modal-bottom {
  padding: 10px;
  padding-bottom: 20px;
  background: white;
  width: 400px;
  margin: 0 auto;
  border-top: 1px solid #ccc;
  box-shadow: 0px 0px 15px 0px #ccc;
}

.genealogy-option-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  left: 0;
  padding-top: 40vh;
  background-color: rgba(255, 255, 255, 0.2);
}

.detail-star-empty {
  font-size: 17px;
}

.detail-star {
  font-size: 17px;
  color: gold;
}

.refreshBtn {
  margin-right: 5px;
}

.refresh-button.refreshBtn.mb-10 > i {
  top: 1px;
}

.refreshBtn.refresh-button.mb-10.filter-icon > i {
  top: 2px !important;
  font-size: 12px !important;
}

.refresh-button {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.sort-by-ov {
  background-color: #f5f5f5;
  color: gray;
  border: 1px solid #e1e7ec;
  position: relative;
  top: 1px;
  width: auto;
}

.sort-by-ov.hidden-sm-down {
  top: 0px;
}

.sort-by-ov-active {
  background-color: #2ab8f5;
  border: 1px solid #2ab8f5;
  color: white;
}

.detail-save-btn {
  display: inline;
  padding: 0 0 0 3px;
  color: #0000ff;
}

.bookmark-cells {
  color: #0da9ef;
}

.fav-person {
  cursor: pointer;
  padding: 5px 0;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  margin: 0 5px;
  padding: 8px 0;
  margin-bottom: 8px;
}

.left-name {
  color: #606975;
  cursor: pointer;
  float: left;
}

.left-name:hover {
  color: #0da9ef;
}

.modal-header {
  justify-content: center !important;
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 768px) {
  .overwrite-flex {
    flex-direction: column !important;
  }
}

@media (max-width: 992px) {
  .overwrite-flex {
    flex-direction: column !important;
  }
  .row.img-footer-japan,
  .row.img-footer-vietnam {
    margin-top: 0px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .overwrite-flex {
    flex-direction: column !important;
  }
}

.checkout-button {
  background-color: white;
  color: black;
  border: 1px solid #dce2e8;
  padding: 60px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.genealogy-bookmark-modal-bottom {
  overflow: scroll;
  background: white;
  padding: 10px;
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 15px 0px #ccc;
  z-index: 2001;
}

.genealogy-bookmark-modal {
  position: fixed;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  z-index: 2000;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
}

.empty-modal-top {
  height: 10%;
}

.hide-box {
  height: 45px;
  border: 0px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.hide-box i {
  margin: auto;
}

.hide-box:hover {
  color: #0da9ef !important;
}

.hide-box-inner {
  font-size: 30px;
  display: table-cell;
  vertical-align: middle;
}

.double-arrow {
  height: 45px;
  border: 0px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: #c35454 !important;
  font-size: 30px !important;
}

.double-arrow:hover {
  color: #fbc1c1 !important;
}

.double-arrow i {
  margin: auto;
}

.double-arrow-inner {
  font-size: 30px;
  display: table-cell;
  vertical-align: middle;
}

.main-row {
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.select-level-text {
  display: inline;
  margin-right: 10px;
}

.genealogy-select {
  border: 1px solid #ccc;
}

.genealogy-select-container {
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.genealogy-page-wrapper,
.global-container-bottom-padding {
  padding-left: 5px;
  padding-right: 5px;
  background: white;
  padding-bottom: 20px;
  min-height: 500px;
}

.col-shadow {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: 2px 0px 5px -1px #ccc;
  z-index: 1;
  background-color: transparent;
}

.left-cell {
  height: 45px;
  text-align: center;
}

.lvl-cell {
  display: table;
  font-size: 10px;
  cursor: pointer;
}

.left-cell-font:hover {
  color: #0da9ef !important;
}

.left-cell-font {
  color: #606975;
  font-weight: bold;
  font-size: 15px;
}

.left-cell-row {
  height: 45px;
  border-bottom: 1px solid #ccc;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
}

.top-thead {
  border-top: 0px !important;
}

.linearBG {
  background-color: #374250 !important;
  color: white !important;
}

.cool-background {
  color: #606975;
  font-weight: bold;
  font-size: 15px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.gene-search {
  width: 100% !important;
  height: 32px;
  border: 1px solid #e1e7ec;
  border-radius: 15px;
  outline: none;
  padding-left: 15px;
  padding-right: 30px;
  /*margin-bottom: 10px;*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.smaller-text {
  font-size: 12px;
  height: 33px;
  max-width: 100px;
}

.gene-search-button {
  cursor: pointer;
  border: 0px;
  background: transparent;
  height: 36px;
  width: 32px;
  position: absolute;
  right: 20px;
}

.gene-search-button > i {
  margin-bottom: 3px;
}

.vertical-mid {
  vertical-align: middle;
  display: table-cell;
  padding-left: 5px;
  text-align: left;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
}

.name-cell {
  padding: 8px;
}

.refresh-button.refreshBtn.mb-10 > i,
.refreshBtn.refresh-button.mb-10 > i {
  margin: 1px;
}

.genealogy-table {
  width: 100%;
  margin: 0px;
  font-size: 12px;
}

.genealogy-table2 {
  width: 100%;
  margin: 0px;
  font-size: 12px;
}

.genealogy-table > thead > tr > th:nth-child(6) {
  padding-right: 10px;
}

.genealogy-table2 > thead > tr > th:nth-child(6) {
  padding-right: 10px;
}

.genealogy-table > thead > tr > th:nth-child(9) {
  padding-right: 10px;
}

.genealogy-table2 > thead > tr > th:nth-child(9) {
  padding-right: 10px;
}

.genealogy-table > thead > tr > th:nth-child(12) {
  padding-right: 10px;
}

.genealogy-table2 > thead > tr > th:nth-child(12) {
  padding-right: 10px;
}

.genealogy-table > tbody > tr > td:nth-child(4),
.genealogy-table2 > tbody > tr > td:nth-child(4),
.genealogy-table > tbody > tr > td:nth-child(7),
.genealogy-table2 > tbody > tr > td:nth-child(7),
.genealogy-table > thead > tr > th:nth-child(4),
.genealogy-table2 > thead > tr > th:nth-child(4),
.genealogy-table > thead > tr > th:nth-child(7),
.genealogy-table2 > thead > tr > th:nth-child(7) {
  border-right: 1px solid #ccc;
}

.genealogy-table.hasVIP > tbody > tr > td:nth-child(6),
.genealogy-table2.hasVIP > tbody > tr > td:nth-child(6),
.genealogy-table.hasVIP > tbody > tr > td:nth-child(9),
.genealogy-table2.hasVIP > tbody > tr > td:nth-child(9),
.genealogy-table.hasVIP > thead > tr > th:nth-child(6),
.genealogy-table2.hasVIP > thead > tr > th:nth-child(6),
.genealogy-table.hasVIP > thead > tr > th:nth-child(9),
.genealogy-table2.hasVIP > thead > tr > th:nth-child(9) {
  border-right: 1px solid #ccc;
}

.genealogy-table.isGlobal > tbody > tr > td:nth-child(6),
.genealogy-table.isGlobal > tbody > tr > td:nth-child(9),
.genealogy-table.isGlobal > thead > tr > th:nth-child(6),
.genealogy-table.isGlobal > thead > tr > th:nth-child(9) {
  border-right: 1px solid #ccc;
}

.genealogy-table.isGlobal > tbody > tr > td:nth-child(4),
.genealogy-table.isGlobal > tbody > tr > td:nth-child(7),
.genealogy-table.isGlobal > thead > tr > th:nth-child(4),
.genealogy-table.isGlobal > thead > tr > th:nth-child(7) {
  border-right: 0px;
}

.genealogy-table.hasVIP > tbody > tr > td:nth-child(4),
.genealogy-table.hasVIP > tbody > tr > td:nth-child(7),
.genealogy-table.hasVIP > thead > tr > th:nth-child(4),
.genealogy-table.hasVIP > thead > tr > th:nth-child(7),
.genealogy-table2.hasVIP > tbody > tr > td:nth-child(4),
.genealogy-table2.hasVIP > tbody > tr > td:nth-child(7),
.genealogy-table2.hasVIP > thead > tr > th:nth-child(4),
.genealogy-table2.hasVIP > thead > tr > th:nth-child(7) {
  border-right: 0px;
}

.genealogy-table.hasAPV > tbody > tr > td:nth-child(5),
.genealogy-table.hasAPV > tbody > tr > td:nth-child(8),
.genealogy-table.hasAPV > thead > tr > th:nth-child(5),
.genealogy-table.hasAPV > thead > tr > th:nth-child(8),
.genealogy-table2.hasAPV > tbody > tr > td:nth-child(5),
.genealogy-table2.hasAPV > tbody > tr > td:nth-child(8),
.genealogy-table2.hasAPV > thead > tr > th:nth-child(5),
.genealogy-table2.hasAPV > thead > tr > th:nth-child(8) {
  border-right: 1px solid #ccc;
}

.genealogy-table.hasAPV > tbody > tr > td:nth-child(4),
.genealogy-table.hasAPV > tbody > tr > td:nth-child(7),
.genealogy-table.hasAPV > thead > tr > th:nth-child(4),
.genealogy-table.hasAPV > thead > tr > th:nth-child(7),
.genealogy-table2.hasAPV > tbody > tr > td:nth-child(4),
.genealogy-table2.hasAPV > tbody > tr > td:nth-child(7),
.genealogy-table2.hasAPV > thead > tr > th:nth-child(4),
.genealogy-table2.hasAPV > thead > tr > th:nth-child(7) {
  border-right: 0px;
}

.genealogy-table.hasVipApv > tbody > tr > td:nth-child(6),
.genealogy-table.hasVipApv > tbody > tr > td:nth-child(9),
.genealogy-table.hasVipApv > thead > tr > th:nth-child(6),
.genealogy-table.hasVipApv > thead > tr > th:nth-child(9),
.genealogy-table2.hasVipApv > tbody > tr > td:nth-child(6),
.genealogy-table2.hasVipApv > tbody > tr > td:nth-child(9),
.genealogy-table2.hasVipApv > thead > tr > th:nth-child(6),
.genealogy-table2.hasVipApv > thead > tr > th:nth-child(9) {
  border-right: 0px;
}

.expand-icon {
  font-size: 22px;
}

.button-margin-left {
  margin-left: 10px !important;
}

.reviewStar .dv-star-rating > label > i {
  font-size: 25px;
}

.rating-stars-single {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 12px;
}

.rating-stars-shop {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 14px;
}

.expand-icon-container {
  height: 100%;
  text-align: right;
  float: left;
}

.genealogy-table > tbody > tr > td:nth-child(6) {
  padding-right: 10px;
}

.genealogy-table2 > tbody > tr > td:nth-child(6) {
  padding-right: 10px;
}

.genealogy-table > tbody > tr > td:nth-child(9) {
  padding-right: 10px;
}

.genealogy-table2 > tbody > tr > td:nth-child(9) {
  padding-right: 10px;
}

.genealogy-table > tbody > tr > td:nth-child(12) {
  padding-right: 10px;
}

.genealogy-table2 > tbody > tr > td:nth-child(12) {
  padding-right: 10px;
}

.genealogy-table > tbody tr td {
  height: 45px !important;
}

.genealogy-table > tbody > tr > td::after {
  border-bottom: 1px solid #ccc;
}

.genealogy-table > thead > tr > th {
  height: 45px !important;
  text-align: center;
  color: #606975;
  font-weight: bold;
  font-size: 15px;
  border: 0px;
  border-bottom: 1px solid #ccc;
  background: transparent;
}

.genealogy-table2 > thead > tr > th {
  height: 45px;
  text-align: center;
  color: #606975;
  font-weight: bold;
  font-size: 15px;
  border: 0px;
  border-bottom: 1px solid #ccc;
}

.genealogy-table > thead > tr:nth-child(2) > th {
  text-align: center;
}

.genealogy-table2 > thead > tr:nth-child(2) > th {
  text-align: center;
}

/* .genealogy-table>thead>tr>th:hover {
    /* color: #0da9ef !important; 


}*/

.overscroll {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
}

.overscroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}

.genealogy-table > tbody > tr > td {
  width: 6.333333%;
  height: 50px;
  text-align: center;
  border-right: 0px;
  border-left: 0px;
  background: white;
  border-bottom: 1px solid #ccc;
  padding: 2px !important;
}

.genealogy-table2 > tbody > tr > td {
  width: 8.333333%;
  height: 50px;
  text-align: center;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid #ccc;
}

.genealogy-table-col {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.genealogy-table2-col {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.genealogy-table-col::-webkit-scrollbar {
  display: none;
}

.genealogy-table2-col::-webkit-scrollbar {
  display: none;
}

.lastCSS {
  border-bottom: 0px;
  height: 49px;
}

.rankSelect {
  position: relative;
}

.centerSelect {
  text-align: center;
  text-align-last: center;
  -webkit-appearance: none;
  align-content: center;
}

/** End of genealogy */

/* LSB Tab */

.lsb-box,
.orderhistory-box,
.commission-box {
  border: 1px solid #e1e7ec;
  border-radius: 7px;
  padding: 60px 5px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.table-lsb > td {
  vertical-align: middle;
}

.col-head-title {
  background-color: #606975;
  color: #ffffff;
  padding: 5px !important;
  border: 0px !important;
}

.row-col {
  font-size: 15;
  align-content: top;
}

.table-responsive {
  width: 100%;
  margin-bottom: 15px;
  overflow-y: hidden;
}

.no-xscroll {
  overflow-x: hidden;
}

.nohover tbody tr:hover {
  background: none;
}

.small-12 {
  width: 100%;
}

.columns {
  border: 0px solid #000000;
}

.table-responsive > .table-bordered {
  border: 0;
}

/* End of LSB Tab */

/* Commission Statement */

.commission-table {
  width: 100%;
  border-left: 1px solid #ccc;
}

.commission-table > tbody > tr {
  border-bottom: 1px solid #ccc;
}

.commission-table > tbody > tr > td {
  text-align: center;
  border-right: 1px solid #ccc;
}

.select-state {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 50%;
  height: 30px;
  margin: 0 auto;
  margin-bottom: 17px;
  background: #f8f8f8;
  padding: 0px;
  display: block;
}

.table-head {
  text-align: center;
  color: #606975;
  vertical-align: middle;
  font-size: 13px;
  vertical-align: middle;
}

.button-select {
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
}

/* Kpitable */

.kpi-table-header {
  background-color: #606975;
  height: 20px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  color: white;
  text-align: center;
}

.kpi-table-borby {
  text-align: center;
}

.suggested-slider .slick-slide {
  padding: 8px !important;
}

.suggested-slider-mobile .slick-slide {
  padding: 8px !important;
}

/* tr:nth-of-type(even) {
    background-color: #dddddd;
} */

td,
th {
  font-size: 12px;
  text-align: left;
  padding: 8px;
}

.shopping-cart {
  width: 100%;
}

.shopping-cart td,
.shopping-cart th {
  font-size: 14px;
}

table {
  width: 100%;
  align-content: center;
  margin-bottom: 15px;
}

/* Profile */

.profile-link {
  cursor: pointer;
}

.user-info-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: -1px;
  padding-top: 90px;
  padding-bottom: 0px;
  border: 1px solid #e1e7ec;
  border-radius: 7px;
}

.user-cover {
  background-image: url(../assets/profileBG.svg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-position: center;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid #e1e7ec;
}

.info-label {
  display: block;
  position: absolute;
  top: 18px;
  right: 18px;
  height: 26px;
  padding: 0 12px;
  border-radius: 13px;
  background-color: #fff;
  color: #606975;
  font-size: 12px;
  line-height: 26px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.18);
  cursor: default !important;
}

.user-info {
  display: table;
  position: relative;
  width: 100%;
  padding: 0 18px;
  z-index: 5;
  margin-bottom: 20px;
}

.single-product {
  max-height: 300px;
}

.user-info-sm {
  display: table;
  /*position: relative;*/
  width: 100%;
  /*padding-top:25px;*/
  /*padding: 0 18px;*/
  z-index: 5;
  margin-bottom: 5px;
}

.user-avatar {
  position: relative;
  width: 115px;
  display: table-cell;
  vertical-align: top;
  border-radius: 100px;
}

.user-avatar-sm {
  position: relative;
  width: 65px;
  display: table-cell;
  /*vertical-align: top;
    border-radius: 100px;*/
}

.user-avatar > img {
  background-color: white;
  display: block;
  width: 100%;
}

.user-data {
  padding-top: 48px;
  padding-left: 12px;
  display: table-cell;
  vertical-align: top;
}

.user-data-sm {
  padding-top: 10px;
  padding-left: 8px;
  margin-right: 5px;
  /*margin-top20px;
    padding-top: 10px;
    padding-left: 5px;*/
  display: table-cell;
  vertical-align: middle;
}

.user-data > span {
  display: block;
  color: #9da9b9;
  font-size: 13px;
}

.form-group label {
  font-size: 13px;
  font-weight: 500;
  cursor: default;
}

.form-group .btn-group.pills {
  margin-top: 0;
}

/*Success*/

.success-row {
  margin-top: 10px;
}

.success-badge {
  margin-right: 2px;
}

.rank-short-success {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rank-row {
  display: inline-block;
}

.rank-name {
  display: none;
}

.rank-pin {
  cursor: pointer;
}

.rank-pin > p {
  position: relative;
  border: 1px solid #e1e7ec;
  border-radius: 500px;
  width: 60px;
  height: 60px;
  margin: 0 auto;
}

.rank-row .gallary-container {
  height: auto;
}

.success-progress-bar {
  margin-top: 5px;
  height: 20px;
  border-radius: 7px;
  background-color: #f0f0f0;
}

.success-progress-bar-inner {
  transition: all 400ms ease 0ms;
  height: 20px;
  border-radius: 5px;
  background-color: #0da9ef;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
  position: relative;
  bottom: 21px;
}

.leg-choice {
  margin-left: 10px;
  margin-right: 10px;
}

.success-table-container {
  margin-left: -5px;
  margin-right: -5px;
}

.status-page.months-select li {
  display: inline-block;
  padding: 0 20px;
}

.status-button {
  background: none;
  border: 0px;
  border-bottom: 2px solid #f0f0f0;
  padding: 0px 50px;
  cursor: pointer;
}

.status-button.active {
  border-bottom-color: rgb(255, 183, 79);
}

.status-button-commission {
  background: none;
  border: 0px;
  border-bottom: 2px solid #f0f0f0;
  padding: 0px 50px;
  cursor: pointer;
}

.status-button-commission.active {
  border-bottom-color: #48a8e9;
}

.btn-leg {
  padding: 2px 10px;
  padding-bottom: 23px;
  height: 20px;
  color: white;
  text-decoration: none;
  border-radius: 7px;
  position: relative;
  top: 2px;
  cursor: pointer;
  border: 0px;
  right: 5px;
}

.align-stuff {
  text-align: center;
}

.rank-badges .slick-prev:before {
  font-family: "FontAwesome";
  content: "\f104" !important;
}

.rank-badges .slick-next:before {
  font-family: "FontAwesome";
  content: "\f105" !important;
}

.btn-leg:hover {
  color: #cccccc;
}

.btn-leg.ov {
  background-color: #bdd5fc;
}

.btn-leg.leg1 {
  background-color: rgb(80, 198, 233);
}

.btn-leg.leg2 {
  background-color: rgb(255, 183, 79);
}

.btn-leg.leg3,
.btn-leg.leg3hc,
.btn-leg.ov_leg3hc {
  background-color: rgb(255, 82, 82);
}

.btn-leg.apt {
  background-color: #a6ca72;
}

.col-11.success-fix-width {
  flex: 0 0 95%;
  max-width: 95%;
  padding-right: 0;
}

.col-1.btn-fix-width {
  flex: 0 0 5%;
  max-width: 5%;
}

.dateMargin {
  margin-top: 20px;
}

.id-label {
  display: block;
  position: absolute;
  top: 48px;
  right: 33px;
  height: 26px;
  padding: 0 12px;
  color: #606975;
  font-size: 12px;
  line-height: 26px;
}

/** Forms */

.form-control-number {
  width: 50px;
  height: 44px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0 5px 3px;
  border: 1px solid #dbe2e8;
  border-radius: 22px;
  background-color: #fff;
  color: #606975;
  font-family: Maven Pro, Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-group .form-control:not(:last-child) {
  border-bottom-right-radius: 22px;
  border-top-right-radius: 22px;
}

.input-group .form-control {
  width: 100%;
}

.feedback-box {
  margin-top: 10px;
  width: 100%;
  height: 150px;
  text-align: left;
  padding: 0 18px 3px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
  color: #dfdfdf;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  resize: none;
}

.feedback-response p {
  font-size: 15px;
}

.input-box {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  text-align: left;
  justify-content: center;
  padding-right: 80px;
  padding-left: 10px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
  color: #dfdfdf;
  font-size: 14px;
  resize: none;
}

/** Spinkit */

.sk-circle {
  width: 60px;
  height: 60px;
  position: relative;
  color: #48a8e9;
  top: 122px;
  z-index: 6;
  left: 45px;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #48a8e9;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.arrow {
  text-align: center;
}

.bounce {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
}

.animated {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0da9ef;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

/** End of Spinkit */

.remove-review {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  border: none;
  background: transparent;
}

.snapshot-btn {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #5d6976;
}

/* New Slider */

.slider-mobile button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  /*-webkit-transition: all 0.5s ease;*/
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  /*-webkit-transition: all 0.5s ease;*/
  /*transition: all 0.5s ease;*/
  border: none;
  background: #0d9fed;
  border-radius: 5px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 10px 30px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  color: white;
}

.inner button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  /*-webkit-transition: all 0.5s ease;*/
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  /*-webkit-transition: all 0.5s ease;*/
  /*transition: all 0.5s ease;*/
  border: none;
  background: #0d9fed;
  border-radius: 5px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 10px 30px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  color: white;
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.hidden-sm {
  display: block;
}

.visible-sm {
  display: none;
}

.productFilter .visible-sm.show {
  display: block;
  max-height: 3000px;
  opacity: 1;
}

.productFilter .mobileFilter {
  opacity: 0;
  max-height: 0;
}

@media (max-width: 720px) {
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: block;
  }
  .mt-autoship-3x {
    margin-top: 30px;
  }
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper-new {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.slider-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, .1));
    -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1)); */
  /* filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1)); */
  border: none;
  background: #fb8c00;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 10px 0px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  color: #fff;
  width: 150px;
}

.slide {
  height: 60vh;
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));*/
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  -webkit-filter: drop-shadow(0 5px 5px rgba(204, 62, 62, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.2s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  z-index: 10;
  background: url("../assets/slideArrow.svg") no-repeat center center / 16px;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  background: url("../assets/slideArrow.svg") no-repeat center center / 16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translate3d(0px, calc(50% + 0px), 0px);
  transform: rotate(180deg) translate3d(0px, calc(50% + 0px), 0px);
}

.previousButton:hover {
  left: -5px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -5px;
}

.slider-content {
  text-align: center;
  will-change: contents;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* -webkit-transition-duration: 1s ease-in-out; */
}

.slider-content .inner {
  /*padding: 0 70px;*/
  /*box-sizing: border-box;*/
  position: absolute;
  /*width: 100%;*/
  top: 30%;
  left: 65%;
  text-align: center;
  margin-right: 10%;
  /*-webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);*/
  /*will-change: contents;*/
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%);
}

.slider-mobile p {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  /*line-height: 0.7;*/
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  /*margin: 20px auto 30px;*/
  max-width: 640px;
  font-weight: 500;
}

h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  /*margin: 20px auto 30px;*/
  max-width: 640px;
  font-weight: 500;
}

.custom-file-label::after {
  min-width: 62px;
  text-align: center;
}

.custom-file-input:lang(vn) ~ .custom-file-label::after {
  content: "chọn";
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 639px) {
  .global-connected-button-right,
  .global-connected-button-left {
    padding: 5px 20px;
  }
}

/* Slider Animations */

/*.slide img {
    transition: all 0.6s ease;
    -webkit-transform: translate3d(0px, -20px, 0px);
    transform: translate3d(0px, -20px, 0px);
    opacity: 0;
    will-change: contents;
}

.slide button {
    transition: all 0.6s ease;
    -webkit-transform: translate3d(0px, 20px, 0px);
    transform: translate3d(0px, 20px, 0px);
    opacity: 0;
    will-change: contents;
}

.slide p {
    transition: all 0.6s ease;
    -webkit-transform: translate3d(0px, 20px, 0px);
    transform: translate3d(0px, 20px, 0px);
    opacity: 0;
    will-change: contents;
}

.slide section * {
    transition: all 0.6s ease;
}

.slide.animateIn.previous img,
.slide.current img,
.slide.animateIn.next img,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-transition-delay: .9s;
    transition-delay: .9s;
    opacity: 1;
    will-change: contents;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
    will-change: contents;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateOut img {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
    will-change: contents;
}

.slide.animateOut p {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
    will-change: contents;
}

.slide.animateOut section span {
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
}

.slide.animateOut section span strong {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}*/

/* Slick */

.dummyDescription {
  text-align: justify;
  margin-top: 20px;
  margin-right: 25px;
}

.productDescription {
  text-align: justify;
  margin-top: 20px;
  margin-right: 25px;
}

.addressBtn {
  background-color: #3cb0fd;
  -moz-border-radius: 5x;
  -webkit-border-radius: 5px;
  border-radius: 30px;
  border: 1px solid #3cb0fd;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px;
  text-decoration: none;
}

.addressBtn:hover {
  background: #57bafc;
  text-decoration: none;
  border: 1px solid #57bafc;
}

.shopprofileBtn {
  background-color: #3cb0fd;
  -moz-border-radius: 5x;
  -webkit-border-radius: 5px;
  border-radius: 30px;
  border: 1px solid #3cb0fd;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px;
  text-decoration: none;
}

.shopprofileBtn:hover {
  background: #57bafc;
  text-decoration: none;
  border: 1px solid #57bafc;
}

.shopprofileCancelBtn {
  border-radius: 0px;
  cursor: pointer;
  border-color: transparent;
  transition: all 0.3s ease;
  background-color: transparent;
  box-shadow: 0px 0px 0px;
  border: 1px solid transparent;
  color: #2e8fcf;
}

.addressCancelBtn {
  border-radius: 0px;
  cursor: pointer;
  border-color: transparent;
  transition: all 0.3s ease;
  background-color: transparent;
  box-shadow: 0px 0px 0px;
  border: 1px solid transparent;
  color: #2e8fcf;
}

.shopprofileBtn {
  background-color: #3cb0fd;
  -moz-border-radius: 5x;
  -webkit-border-radius: 5px;
  border-radius: 30px;
  border: 1px solid #3cb0fd;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px;
  text-decoration: none;
}

.shopprofileBtn:hover {
  background: #57bafc;
  text-decoration: none;
  border: 1px solid #57bafc;
}

.shopprofileCancelBtn {
  border-radius: 0px;
  cursor: pointer;
  border-color: transparent;
  transition: all 0.3s ease;
  background-color: transparent;
  box-shadow: 0px 0px 0px;
  border: 1px solid transparent;
  color: #2e8fcf;
}

.autoshipBtn {
  background-color: #3cb0fd;
  -moz-border-radius: 5x;
  -webkit-border-radius: 5px;
  border-radius: 30px;
  border: 1px solid #3cb0fd;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 50px;
  text-decoration: none;
}

.autoshipBtn:hover {
  background: #57bafc;
  text-decoration: none;
  border: 1px solid #57bafc;
}

.autoshipCancelBtn {
  border-radius: 0px;
  cursor: pointer;
  border-color: transparent;
  transition: all 0.3s ease;
  background-color: transparent;
  box-shadow: 0px 0px 0px;
  border: 1px solid transparent;
  color: #2e8fcf;
}

.suggested-slider .slick-dots {
  bottom: 100px !important;
  width: auto !important;
  left: 15% !important;
  right: 15%;
  background: none;
  cursor: pointer;
}

.suggested-slider-mobile .slick-dots {
  bottom: 100px !important;
  width: auto !important;
  left: 15% !important;
  right: 15%;
  background: none;
  cursor: pointer;
}

.suggested-slider .slick-dots li {
  margin: 0px !important;
}

.suggested-slider .slick-dots li button::before {
  color: grey !important;
  line-height: 30px !important;
}

.suggested-slider-mobile .slick-dots li {
  margin: 0px !important;
}

.suggested-slider-mobile .slick-dots li button::before {
  color: grey !important;
  line-height: 30px !important;
}

.slick-arrow {
  z-index: 99;
}

.slick-dots {
  width: 100px !important;
  bottom: 0px !important;
  left: calc(50% - 50px) !important;
  height: 45px !important;
  background-color: #606975;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  margin: 0px !important;
}

.slick-dots li button::before {
  color: white !important;
  line-height: 30px !important;
}

.gallary-container {
  height: 580px;
}

/** Toast Notification */

.s-alert-box {
  width: 300px;
  z-index: 999 !important;
}

.s-alert-box::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 8px 10px -4px rgba(0, 0, 0, 0.2);
  content: "";
  z-index: -1;
}

.s-alert-wrapper i {
  height: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  display: table;
  font-size: 20px;
  line-height: 20px;
  margin-top: -11px;
  margin-left: 10px;
}

.s-alert-box-inner td,
.s-alert-box-inner th {
  padding: 8px;
}

.s-alert-box-inner strong,
.s-alert-box-inner p {
  margin-left: 20px !important;
}

.s-alert-close {
  top: 35% !important;
}

.s-alert-box:first-child {
  top: 10px;
}

.s-alert-box.s-alert-success {
  border: 1px solid rgba(67, 217, 163, 0.3) !important;
  background-color: #dcf8ee !important;
  color: #2bd396 !important;
}

.s-alert-success .s-alert-close::before,
.s-alert-success .s-alert-close::after {
  background: #2bd395 !important;
}

.s-alert-box.s-alert-warning {
  border: 1px solid rgba(255, 183, 79, 0.3) !important;
  background-color: #fff6e8 !important;
  color: #ffb140 !important;
}

.s-alert-warning .s-alert-close::before,
.s-alert-warning .s-alert-close::after {
  background: #ffb140 !important;
}

.s-alert-box.s-alert-info {
  border: 1px solid rgba(80, 198, 233, 0.3) !important;
  background-color: #e6f7fc !important;
  color: #39bfe6 !important;
}

.s-alert-info .s-alert-close::before,
.s-alert-info .s-alert-close::after {
  background-color: #39bfe6 !important;
}

.s-alert-box.s-alert-error {
  border: 1px solid rgba(255, 82, 82, 0.3) !important;
  background-color: #ffebeb !important;
  color: #ff5151 !important;
}

.s-alert-error .s-alert-close::before,
.s-alert-error .s-alert-close::after {
  background-color: #ff5252 !important;
}

/** Footer */

.hover-regis {
  color: white;
}

.footer-hover-quicklink:visited {
  text-decoration: none;
  font-weight: 700;
  color: white;
}

.footer-hover-quicklink {
  text-decoration: none;
  font-weight: 700;
  color: white;
}

.footer-hover-quicklink-btn {
  text-decoration: none;
  font-weight: 700;
  color: white;
  background-color: transparent;
  padding: 0px;
  border: none;
}

.footer-hover-quicklink-btn:hover {
  cursor: pointer;
  color: #0da9ef;
  text-decoration: none !important;
}

.footer-hover-quicklink:hover {
  color: #0da9ef;
  cursor: pointer;
  background-position: center;
  text-decoration: none;
  /* background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden; */
}

.hover-quicklink:hover {
  color: #0da9ef !important;
  cursor: pointer;
  background-position: center;
  /* background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden; */
}

.cat-shop {
  padding-bottom: 16px;
}

.item-left {
  text-align: left;
}

.site-footer {
  padding-top: 30px;
}

.app-list {
  text-align: left;
  padding-bottom: 20px;
}

.app-list img {
  width: 60%;
}

.widget li:last-child .market-button {
  margin-right: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border: 0px;
  padding-top: 12px;
}

.input-group .form-control {
  padding-left: 45px;
}

.payments {
  background-image: url("../assets/paymnet_method_new.png");
  background-size: cover;
  background-position: -150px 0px;
  background-repeat: no-repeat;
  height: 60px;
  padding-left: 10%;
  width: 320px;
}

/** News */

.news {
  padding-top: 25px;
}

.img-news {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
}

.gallery-item {
  padding-bottom: 30px;
}

.x {
  border: 0px;
}

/** Burger Menu */

/* Hides everything pushed outside of it */

.site-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.push-wrap,
.offcanvas-menu {
  -webkit-transition: all 100ms ease 0ms;
  -moz-transition: all 100ms ease 0ms;
  -o-transition: all 100ms ease 0ms;
  transition: all 100ms ease 0ms;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.show-nav .push-wrap,
.show-nav .offcanvas-menu {
  -webkit-transform: translate(250px, 0);
  -moz-transform: translate(250px, 0);
  -ms-transform: translate(250px, 0);
  -o-transform: translate(250px, 0);
  transform: translate(250px, 0);
  -webkit-transform: translate3d(250px, 0, 0);
  -moz-transform: translate3d(250px, 0, 0);
  -ms-transform: translate3d(250px, 0, 0);
  -o-transform: translate3d(250px, 0, 0);
  transform: translate3d(250px, 0, 0);
}

.show-nav #sidebar-nav {
  left: -250px;
}

/* Positions the nav fixed below the push wrapper */

.account-link {
  margin-bottom: 0px !important;
  display: block;
  padding: 0px 10px;
  transition: background-color 0.3s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background-color: #313b47;
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.account-link .rank {
  font-size: 9px;
}

#sidebar-nav {
  width: 250px;
  height: 100%;
  position: fixed;
  padding-left: 0;
  left: -250px;
  top: 0;
  background: #374250;
  -webkit-box-shadow: inset -1em 0 1.5em -0.75em rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset -1em 0 1.5em -0.75em rgba(0, 0, 0, 0.5);
  box-shadow: inset -1em 0 1.5em -0.75em rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 1000;
  cursor: pointer;
}

#sidebar-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#sidebar-nav::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
#sidebar-nav::-webkit-scrollbar-thumb {
  background: #ff0000;
}

#sidebar-nav ul li > span {
  display: block;
  padding: 15px 20px;
  transition: color 0.3s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-decoration: none;
}

#sidebar-nav ul li.active > span {
  color: #0da9ef;
}

.lock-scroll-bar {
  overflow-y: hidden !important;
}

#sidebar-nav ul li.has-children .sub-menu-toggle {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  height: calc(100% - 1px);
  transition: background-color 0.3s;
  color: transparent;
  cursor: pointer;
  z-index: 1;
  border-left: 0px;
}

#sidebar-nav ul li.has-children .sub-menu-toggle:hover {
  background-color: transparent;
}

#sidebar-nav ul li.has-children .sub-menu-toggle::before {
  display: block;
  position: absolute;
  top: 50%;
  right: 25px;
  width: 0;
  height: 0;
  margin-top: -5px;
  border-top: 0.35em solid transparent;
  border-bottom: 0.35em solid transparent;
  border-left: 0.4em solid;
  content: "";
}

.offcanvas-menu ul li.back-btn > span::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 8px;
  border-top: 0.32em solid transparent;
  border-right: 0.37em solid;
  border-bottom: 0.32em solid transparent;
  content: "";
  opacity: 0.75;
}

#sidebar-nav .offcanvas-submenu {
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: auto;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

#sidebar-nav .offcanvas-submenu.in-view {
  transform: translate3d(0, 0, 0);
}

.offcanvas-menu li {
  display: block;
}

.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}

.site-menu > ul > li.has-megamenu {
  color: #0da9ef !important;
}

.logo-gradient {
  background-image: linear-gradient(-90deg, #5468ad 12%, #448ed7 55%, #30acea 100%);
}

.logout {
  position: relative;
  width: 100%;
  border-top: 1px solid #e1e7ec;
  height: 40px;
  justify-content: center;
  text-align: left;
  padding-left: 15px;
  cursor: pointer;
}

.logout-sm {
  position: relative;
  width: 100%;
  /*border-top: 1px solid #e1e7ec;*/
  height: 25px;
  justify-content: center;
  text-align: left;
  padding-left: 15px;
  cursor: pointer;
}

.logout-sm:hover {
  position: relative;
  width: 100%;
  /*border-top: 1px solid #e1e7ec;*/
  height: 25px;
  justify-content: center;
  text-align: left;
  padding-left: 15px;
  cursor: pointer;
  color: #0da9ef;
}

.logout:hover {
  position: relative;
  width: 100%;
  border-top: 1px solid #e1e7ec;
  height: 40px;
  justify-content: center;
  text-align: left;
  cursor: pointer;
  color: #0da9ef;
}

.logout-label {
  top: 8px;
  position: relative;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.stuff-transform {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.logout.active {
  border-color: #0da9ef;
  background-color: #0da9ef;
  color: #fff;
  pointer-events: none;
}

.rank-pin {
  padding-bottom: 0%;
}

.rank-badges .slick-slide.slick-active .rank-pin > p {
  border-color: rgb(13, 169, 239);
  color: rgb(13, 169, 239);
}

.gallary-slides.success-page {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
}

.rank-badges .slick-dots {
  top: 88px;
  width: auto !important;
  left: 15% !important;
  right: 15%;
  background: none;
  cursor: pointer;
}

.rank-badges .slick-dots li button::before {
  color: black !important;
}

.rank-badges .slick-dots li button {
  background: none;
}

.rank-badges .slick-dots li button:hover {
  cursor: pointer;
}

.rank-badges .slick-prev {
  left: 0px;
}

.rank-badges .slick-prev:before,
.rank-badges .slick-next:before {
  color: rgb(13, 169, 239);
  font-size: 40px;
}

.rank-badges .slick-next {
  right: 10px;
}

.col-11.success-fix-width {
  flex: 0 0 90%;
  max-width: 90%;
}

/** All about media query */

@media (min-width: 768px) {
  .app-list a {
    min-width: 158px;
  }
  .border-sm-right {
    border-right: 1px solid #dee2e6 !important;
  }
  .productFilter .visible-sm {
    display: block;
  }
  .productFilter .mobileFilter {
    opacity: 1;
  }
}

.container {
  width: 100% !important;
}

@media (min-width: 1367px) {
  .redirect-loading-container-success {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80vh;
    width: 80vw;
  }
  .left-cell-row {
    min-width: 470px;
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .mobile-justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
    margin-top: 23px !important;
  }
  .padding15 {
    padding: 15px;
    padding-top: 0px;
  }
  .marginTop0 {
    margin-top: 0px !important;
  }
  .card-img-top {
    max-height: 250px !important;
  }
}

@media (max-width: 1280px) {
  .promotion {
    text-align: center;
  }
  .promotion > img {
    object-fit: contain;
  }
  .slider-button {
    padding: 10px 15px;
  }
}

@media (max-width: 1200px) {
  .toolbar .cart > .subtotal {
    display: inline !important;
  }
  .sliderImg {
    width: auto;
    height: 315px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 27px;
  }
}

@media screen and (max-width: 2560px) {
  .sliderImg {
    width: auto;
    height: 315px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 27px;
  }
}

@media only screen and (min-width: 1024px) {
  .smaller-text {
    font-size: 13px;
    height: 33px;
    max-width: 84px;
    padding: 5px 10px;
  }
  .global-connected-button-left {
    margin-left: 10px;
  }
  .left-cell-row {
    min-width: 380px;
  }
}

@media only screen and (min-width: 1539px) {
  .left-cell-row {
    min-width: 474px !important;
  }
}

@media only screen and (min-width: 1025px) {
  .smaller-text {
    font-size: 13px;
    height: 33px;
    max-width: 120px;
    padding: 5px 20px;
  }
  .col-11.success-fix-width {
    flex: 0 0 93%;
    max-width: 93%;
  }
  .left-cell-row {
    width: 100% !important;
  }
}

@media screen and (max-width: 1024px) {
  .left-cell-row {
    min-width: 320px;
    width: 100% !important;
  }
  .sliderImg {
    width: auto;
    height: 315px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 27px;
  }
  .slick-list {
    position: initial !important;
  }
  .topbar.route-wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
  header .site-menu {
    display: block !important;
  }
  .status-button {
    padding: 10px;
  }
  .success-badge {
    margin-right: 0px;
  }
  .gallary-container {
    height: auto;
  }
  td.product-detail {
    width: 50% !important;
    padding: 0px;
  }
  td.product-button {
    width: 50% !important;
    padding: 0px;
  }
}

@media screen and (min-width: 1680px) {
  .slick-slide {
    height: 645px !important;
  }
}

@media screen and (min-width: 2100px) {
  .QtyDetail input {
    position: relative;
    bottom: 0px;
  }
  .detail-image {
    margin-left: 21px;
  }
}

@media screen and (min-width: 2300px) {
  .QtyDetail input {
    position: relative;
    bottom: 0px;
  }
}

@media (max-width: 992px) {
  .hipster-dude {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .app-list {
    display: inline;
  }
  .page-container .site-menu {
    display: block !important;
    position: unset;
  }
  .gallary-cells {
    align-items: center;
    justify-content: flex-end;
  }
  .gallary-cell-bottom {
    justify-content: flex-start !important;
  }
  .gallary-cell-bottom-image {
    margin-top: 20px;
  }
  .s-alert-box {
    width: 100%;
    max-width: unset !important;
    left: 0 !important;
    right: 0 !important;
  }
  /*.widget.widget-light-skin {
         text-align: center!important 
    }*/
  .app-list img {
    width: 60% !important;
  }
  main .site-menu {
    display: block !important;
    height: 40px;
  }
  main .site-menu > ul > li > a {
    display: block;
  }
  .qs-row {
    margin-right: 70px;
  }
  .col-11.success-fix-width {
    flex: 0 0 95%;
    max-width: 85%;
  }
  .sort-by-ov {
    width: 52%;
  }
  .product-card .product-thumb {
    display: block;
    width: 100%;
    padding: 24px;
  }
  .slick-initialized .slick-slide .product-card .product-thumb {
    height: 200px;
  }
  .sp-mb {
    padding-left: 0px;
    padding-right: 0px;
  }
  #section {
    padding: 0px 15px !important;
  }
  #section-sm {
    padding: 0px !important;
  }
}

@media screen and (min-width: 421px) and (max-width: 640px) {
  .global-connected-button-right,
  .global-connected-button-left {
    padding: 5px 20px !important;
  }
}

@media screen and (min-width: 640px) {
  .new-left-name {
    font-size: 12px;
  }
  .toolbar {
    right: 15px;
  }
  .detail-btn {
    margin-bottom: 15px;
    white-space: nowrap;
  }
}

@media screen and (min-width: 500px) {
  .gene-search {
    float: left;
    width: 100%;
  }
  .global-connected-button-right,
  .global-connected-button-left {
    padding: 5px 20px;
  }
}

@media screen and (max-width: 480px) {
  .new-left-name {
    font-size: 12px;
  }
  .productDescription {
    text-align: justify;
    margin-top: 20px;
    margin-right: 25px;
    margin-left: 21px;
  }
 
  .report-btn {
    padding: 6px;
    padding-right: 10px;
    text-align: center;
  }
  .shopping-cart-footer > .btn-success {
    margin-right: 0;
  }
  .payments {
    width: 320px;
    background-position-x: -122px;
  }
  .lang-currency-switcher-wrap {
    margin-right: 0px;
    margin-left: 0px;
  }
  .lang-currency-switcher-wrap.login-wrap .dropdown-menu {
    position: fixed;
    top: 225%;
    left: 34%;
    margin-top: -50px;
    margin-left: -100px;
    width: 320px;
  }
  .qs-add-to-cart {
    font-size: 10px;
  }
  .qs-out-of-stock {
    font-size: 10px;
  }
  .qs-quicknav {
    width: 25px;
  }
  .qs-row {
    margin-right: 30px;
  }
  .small-no-pad {
    padding: 0px;
    padding-right: 5px;
  }
  .rank-row {
    position: relative;
    top: -43px;
    left: 0px;
  }
  .rank-row.new-line {
    top: -20px;
  }
  .rank-badges .slick-slide.slick-active {
    top: 43px;
    position: relative;
    left: 0px;
  }
  .font-size-genealogy-screen {
    font-size: 2.1vh;
    width: max-content;
  }
  #qs-row-mob {
    margin-right: 15px !important;
  }
  .qs-row {
    margin-left: 15px !important;
  }
}

@media screen and (max-width: 414px) {
  .QtyDetail button > i {
    position: relative;
    right: 5px;
    bottom: 2px;
  }
  .suggested-slider .slick-dots {
    bottom: 45px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .suggested-slider-mobile .slick-dots {
    bottom: 45px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .button-product-detail {
    margin-left: 20px;
  }
  .single-product-title {
    font-size: 30px;
    color: black;
    margin-left: 20px;
  }
  .single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
  }
  .detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .sliderImgBios {
    width: auto;
    height: 150px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 55px;
  }
  .sliderImgUni {
    width: auto;
    height: 155px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
  }
  .rank-row {
    position: relative;
    top: -43px;
    left: 0px;
  }
  .rank-badges .slick-slide.slick-active {
    top: 43px;
    position: relative;
    left: 0px;
  }
  .profile-pic-side {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    vertical-align: middle;
    padding: 1px;
  }
  .success-position {
    position: relative;
    right: 25px;
  }
  .sliderImg {
    position: relative;
    height: 270px;
  }
  .sort-by-ov {
    width: 52%;
  }
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 30px;
  }
  .wordWrap {
    word-wrap: break-word;
    width: 140px;
  }
  .col-11.success-fix-width {
    flex: 0 0 90%;
    max-width: 87%;
  }
  .t-sm {
    font-size: 79%;
  }
}

@media screen and (max-width: 375px) {
  .QtyDetail button > i {
    position: relative;
    right: 5px;
    bottom: 2px;
  }
  .suggested-slider .slick-dots {
    bottom: 65px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .suggested-slider-mobile .slick-dots {
    bottom: 65px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .button-product-detail {
    margin-left: 20px;
  }
  .single-product-title {
    font-size: 30px;
    color: black;
    margin-left: 20px;
  }
  .single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
  }
  .detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .sliderImgBios {
    width: auto;
    height: 100px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
    top: 55px;
  }
  .sliderImgUni {
    width: auto;
    height: 135px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
  }
  .rank-row {
    position: relative;
    top: -43px;
    left: 0px;
  }
  .rank-badges .slick-slide.slick-active {
    top: 43px;
    position: relative;
    left: 0px;
  }
  .profile-pic-side {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    vertical-align: middle;
    padding: 1px;
  }
  .success-position {
    position: relative;
    right: 25px;
  }
  .sliderImg {
    width: auto;
    height: 270px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
  }
  /*.slider-button {
        border: 1px solid;
        justify-content: center;
        align-items: center;
        align-self: center;
        margin-left: 100px;
        margin-right: 100px;
        position: relative;
        border-color: #4EAD75;
        top: 20px
    }*/

  .sort-by-ov {
    width: 47%;
  }
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 33px;
  }
  .wordWrap {
    word-wrap: break-word;
    width: 120px;
  }
  .EN.order-deadline {
    width: 75%;
  }
}

@media screen and (max-width: 360px) {
  .modal-content {
    min-width: 0px !important;
  }
  .offcanvas-toggle {
    width: 40px;
  }
  .sort-by-ov {
    width: 45%;
  }
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 30px;
  }
}

@media screen and (max-width: 320px) {
  /* .offcanvas-toggle {
        width: 40px;
    } */
  .topbar .topbar-column:first-child > a:not(.social-button),
  .topbar .topbar-column:first-child > span,
  .topbar .topbar-column:first-child > p {
    margin-right: 15px;
  }
  .sort-by-ov {
    width: 37%;
  }
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 20px;
  }
  .genealogy-page-wrapper button.hidden-md-up.btn {
    padding: 0 13px;
  }
}

@media screen and (max-width: 346px) {
  .QtyDetail button > i {
    position: relative;
    right: 5px;
    bottom: 2px;
  }
  .refreshBtn {
    margin-bottom: 10px;
  }
  .detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .single-product-title {
    font-size: 30px;
    color: black;
    margin-left: 20px;
  }
  .single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
  }
  .button-product-detail {
    margin-left: 20px;
  }
}

@media screen and (max-width: 320px) {
  .QtyDetail button > i {
    position: relative;
    right: 5px;
    bottom: 2px;
  }
  .suggested-slider .slick-dots {
    bottom: 100px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .suggested-slider-mobile .slick-dots {
    bottom: 100px !important;
    width: auto !important;
    left: 15% !important;
    right: 15%;
    background: none;
    cursor: pointer;
  }
  .detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .single-product-title {
    font-size: 30px;
    color: black;
    margin-left: 20px;
  }
  .single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
  }
  .button-product-detail {
    margin-left: 10px;
  }
  .sliderImgUni {
    width: auto;
    height: 106px;
    overflow: hidden;
    z-index: 2;
    background-size: cover;
    position: relative;
  }
  .topbar .topbar-column {
    width: auto;
  }
  /*.topbar .topbar-column-left {
        width: 60%;
    }*/
  /*.topbar .topbar-language-column {
        width: 40%;
        float:right;
    }*/
  .sliderImg {
    position: relative;
    height: 250px;
  }
  .drop-login {
    right: 100px;
    width: 250px;
  }
  .drop-login a {
    font-size: 14px;
  }
  .topbar.route-wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }
  .lang-currency-switcher-wrap {
    margin-left: 8px;
  }
  .lang-currency-switcher-wrap .dropdown-menu {
    width: 200px;
    left: -120px;
  }
  .language.lang-currency-switcher-wrap .dropdown-menu {
    width: 100px;
  }
  .slick-list {
    top: 120px;
  }
  .img-container {
    top: -50px;
  }
  .scroll-to-top-btn {
    width: 44px;
    height: 44px;
    right: 32px !important;
  }
  .scroll-to-top-btn.visible {
    bottom: 40px;
    opacity: 1;
  }
  .page-title {
    padding: 15px 10px;
  }
  .menu-container,
  #menu-container {
    width: 250px;
  }
  .enroll-form .quick-shop {
    margin-top: 80px;
  }
  .widget-categories > ul > li {
    margin-left: -15px;
  }
  .quick-shop .widget-categories > ul > li > ul {
    margin-left: 10px;
  }
  .home-row-buttons .global-connected-button-left,
  .home-row-buttons .global-connected-button-right,
  .home-row-buttons .global-connected-button-middle,
  .home-row-buttons .global-connected-button-active {
    padding: 2px 10px;
  }
  .app-list img {
    width: 80%;
  }

  .toolbar {
    right: 5px;
  }
  .payments {
    width: 100% !important;
    background-position-x: -155px;
  }
  .app-list {
    text-align: left;
    display: block;
    padding-bottom: 10px;
  }
  .lang-currency-switcher-wrap.login-wrap .dropdown-menu {
    position: fixed;
    top: 225%;
    left: 34%;
    margin-top: -50px;
    margin-left: -100px;
    width: 300px;
  }
  .rank-row {
    position: relative;
    top: -43px;
    left: 0px;
  }
  .rank-badges .slick-slide.slick-active {
    top: 43px;
    position: relative;
    left: 0px;
  }
  .checkout h5 {
    font-size: 16px;
  }
  .qtyplusminusbtn {
    padding: 0px 16px;
  }
  .wordWrap {
    word-wrap: break-word;
    width: 100px;
  }
}

@media screen and (max-width: 550px) {
  .detail-image {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .single-product-title {
    font-size: 30px;
    color: black;
    margin-left: 20px;
  }
  .single-product-price {
    font-size: 30px;
    color: black;
    font-weight: 500;
    margin-left: 20px;
  }
  .genealogy-table {
    width: 300%;
  }
  .genealogy-table2 {
    width: 300%;
  }
  .genealogy-table > thead > tr > th {
    padding: 0px;
  }
  .genealogy-table2 > thead > tr > th {
    padding: 0px;
  }
  .genealogy-table > tbody > tr > td {
    padding: 0px;
  }
  .genealogy-table2 > tbody > tr > td {
    padding: 0px;
  }
}

.promotion {
  min-height: 480px;
}

@media screen and (max-width: 550px) {
  .promotion {
    min-height: 100px;
  }
}

.edit-button {
  color: #0c9fed;
  background-color: white;
}

.edit-button-hover {
  color: #097cb7;
  background-color: white;
}

/* Shop Profile */

.shopprofile-tab .padding-top-1x {
  padding-top: 20px !important;
}

/* referral link */

.referral-link-tab .padding-top-1x {
  padding-top: 125px !important;
}

.genealogy-page-wrapper .btn-group {
  /* float: right; */
  margin-right: 0 !important;
  margin-top: 0;
  margin-left: 7px;
}

/* BIR styles  */

.bir-link-tab .padding-top-1x {
  padding-top: 125px !important;
}

.genealogy-page-wrapper .btn:hover,
.genealogy-page-wrapper .btn:focus,
.genealogy-page-wrapper .btn:active {
  border-color: transparent;
  box-shadow: none;
}

.genealogy-page-wrapper .btn-info {
  background-color: #2bb8f4;
}

.text-small.btn.btn-info.btn-show-all-less.bordershow-all-less,
.text-small.btn.btn-info.btn-show-all-less {
  background-color: #2bb8f4 !important;
}

.genealogy-page-wrapper .btn-secondary:active,
.genealogy-page-wrapper .btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  background-color: #f5f5f5;
  border-color: transparent;
  box-shadow: none;
  color: #606975;
}

.genealogy-page-wrapper .btn-info:active,
.genealogy-page-wrapper .btn-info:focus,
.btn-info:not(:disabled):not(.disabled):active:focus {
  background-color: #2bb8f4;
  border-color: transparent;
  box-shadow: none;
}

.dropdown-menu::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#3bb3e8), to(#28abe4));
  background-image: -webkit-linear-gradient(bottom, #3bb3e8 0%, #28abe4 100%);
  background-image: linear-gradient(to top, #3bb3e8 0%, #28abe4 100%);
}

@media (max-width: 767px) {
  .referral-link-tab .padding-top-1x {
    padding-top: 15px !important;
  }
  .bir-link-tab {
    padding-top: 15px !important;
  }
}

@media (max-width: 725px) {
  .autoship .cart-img {
    width: auto;
  }
  .mob-cart-img {
    display: block;
  }
  .mob-cart-img img {
    width: auto;
    max-width: 110px;
    height: auto;
    max-height: 90px;
    margin: 8px auto;
  }
  .shopping-cart .product-button label {
    margin: 0px;
    font-family: "Maven Pro", sans-serif;
  }
  /* .cart-img img{
        width: 100%;
        margin-left: 27%;
    } */
}

@media (min-width: 768px) and (max-width: 1021px) {
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 20px;
  }
}

@media (min-width: 1280px) {
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 30px;
  }
  .gene-search {
    max-width: 180px !important;
    float: right;
  }
  .gene-search-button {
    margin-right: -97%;
    max-width: 100% !important;
    float: right;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .genealogy-page-wrapper .btn-xs {
    padding: 5px 10px;
  }
  .gene-search {
    max-width: 180px !important;
    float: right;
  }
  .gene-search-button {
    margin-right: -97%;
    max-width: 100% !important;
    float: right;
  }
}

/* enroll */

.enrollment-page .dropdown-menu {
  min-width: 15rem;
}

.enrollment-page .disabled.dropdown-item:hover {
  color: #000000 !important;
}

@media (min-width: 768px) and (max-width: 897px) {
  .qs-add-to-cart,
  .qs-out-of-stock {
    font-size: 9px;
    padding: 5px 5px;
  }
}

@media (max-width: 897px) and (min-width: 321px) {
  .enrollment-page .qs-add-to-cart,
  .enrollment-page .qs-out-of-stock {
    font-size: 11px;
    padding: 5px 5px;
  }
}

@media (max-width: 330px) {
  .enrollment-page .qs-add-to-cart,
  .enrollment-page .qs-out-of-stock {
    padding: 3px 5px;
  }
}

@media (max-width: 860px) and (min-width: 767px) {
  .enrollment-page .qs-add-to-cart,
  .enrollment-page .qs-out-of-stock {
    font-size: 9px;
  }
  .shopping-cart .product-button {
    width: 21% !important;
  }
  .order-deadline.EN.t-sm {
    font-size: 87%;
  }
}

.enroll-qs-row .container {
  width: auto;
}

.enroll-qs-row {
  margin-right: 30px;
  padding-bottom: 5px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .enroll-qs-row {
    margin-right: 70px;
  }
}

@media screen and (max-width: 480px) {
  .enroll-qs-row {
    margin-right: 30px;
  }
}

.enroll-profile-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
  box-shadow: 0 1px 8px 0 rgba(56, 56, 56, 0.2);
}

.view-ba.profileCard {
  width: auto;
}

@media (max-width: 330px) {
  #btn-leave-enroll-cancel,
  #btn-leave-enroll-ok {
    font-size: 10px;
  }
}

.enroll-summary {
  line-height: 1.8;
}

.enroll-summary h5 {
  padding-left: 5px;
}

.enroll-summary table {
  width: auto;
}

.enroll-summary td {
  vertical-align: top;
  font-size: inherit;
  padding: 0px 0 0 5px;
}

.enroll-summary tr td:first-child {
  white-space: nowrap;
}

.co-info td.company {
  width: 125px;
}

td.text-muted.spouse {
  min-width: 121px;
}

@media screen and (max-width: 767px) {
  .enroll-summary td {
    min-width: 135px;
    word-break: break-all;
  }
  .maintainLabel {
    font-size: 10vw;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

body.show-nav ::-webkit-scrollbar {
  display: none;
}

.youtube-foreground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*transform: translate(-50%, -50%);*/
  background: rgba(0, 0, 0, 0.001) url(../assets/screen.png);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.button-center {
  display: flex;
  align-items: center;
}

.filter {
  font-size: 24px;
  position: absolute;
  padding-bottom: 20px;
  right: 30px;
  color: #999999;
  text-decoration: none;
  -webkit-appearance: button;
  background: none;
  border: 0px;
}

.filter-on {
  font-size: 24px;
  position: absolute;
  padding-bottom: 20px;
  right: 30px;
  color: #25b0f3;
}

.filter-off {
  font-size: 24px;
  position: absolute;
  padding-bottom: 20px;
  right: 30px;
  color: #999999;
}

.maintainanceBg {
  background-image: url("../assets/coming_soon.jpg");
}

.mobileBannerContent {
  text-align: right;
  margin-bottom: 8%;
  margin-right: 10%;
}

.mobileFilter {
  opacity: 0;
  max-height: 0;
}

.show {
  opacity: 1;
  /*max-height: 3000px;*/
}

/*Sathya UI - II*/

.fb_dialog .fb_dialog_mobile .fb_customer_chat_bubble_animated_no_badge .fb_customer_chat_bubble_pop_in {
  bottom: 50pt !important;
}

.bottom-50 .slick-dots {
  bottom: 50px !important;
}

.no-border-radius {
  border-radius: 0px !important;
}

.no-padding-imp {
  padding: 0px !important;
}

.no-margin-imp {
  margin: 0px !important;
}

.list-pills:first-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.spinner-align {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
}

.display-none {
  display: none !important;
}

/** Youtube Loader CSS **/

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-css {
  left: calc(50% - 37px);
  top: calc(48% - 37px);
  position: absolute;
}

.lds-spinner {
  position: relative;
}

.lds-spinner div {
  left: 96px;
  top: 42px;
  position: absolute;
  -webkit-animation: lds-spinner linear 1s infinite;
  animation: lds-spinner linear 1s infinite;
  background: #ffffff;
  width: 8px;
  height: 36px;
  border-radius: 20%;
  -webkit-transform-origin: 4px 58px;
  transform-origin: 4px 58px;
}

.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.916666666666667s;
  animation-delay: -0.916666666666667s;
}

.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -0.833333333333333s;
  animation-delay: -0.833333333333333s;
}

.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.tab-text-desktop {
  font-size: 14px !important;
}

.tab-text-mobile {
  font-size: 11px !important;
  padding: 10px 12px !important;
}

.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.666666666666667s;
  animation-delay: -0.666666666666667s;
}

.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.583333333333333s;
  animation-delay: -0.583333333333333s;
}

.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.416666666666667s;
  animation-delay: -0.416666666666667s;
}

.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.333333333333333s;
  animation-delay: -0.333333333333333s;
}

.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.166666666666667s;
  animation-delay: -0.166666666666667s;
}

.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.083333333333333s;
  animation-delay: -0.083333333333333s;
}

.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.lds-spinner {
  width: 75px !important;
  height: 75px !important;
  -webkit-transform: translate(-37.5px, -37.5px) scale(0.375) translate(37.5px, 37.5px);
  transform: translate(-37.5px, -37.5px) scale(0.375) translate(37.5px, 37.5px);
}

/** Youtube Loader CSS Ends**/

.youtube-logo {
  bottom: 17px;
  right: 17px;
  position: absolute;
  width: 108px;
}

/* Show/Hide Password*/

.showPass {
  float: right;
  margin-top: -30px;
  margin-right: 10px;
  font-size: 1.2em;
}

/** Firefox specific CSS **/

@-moz-document url-prefix() {
  .enrollment-page .dropdown-toggle::after {
    margin-top: -23px !important;
    float: right !important;
  }
  .floatLeft {
    float: left;
  }
}

.currency {
  padding: 4px 0 4px 12px;
  border-left: 1px solid #e1e7ec;
  font-size: 14px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-control.custom-checkbox {
  display: inline-block;
}

.loaderLogin .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.tbTrip > table > tbody > tr > th {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}

th.thTitle {
  background-color: #374250 !important;
}

th.thMonth {
  background-color: #838a92 !important;
}

.tbTrip {
  /* Rectangle: */
  border: 0.5px solid #e1e7ec;
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  perspective: 1px;
  border-radius: 5px;
  border-collapse: separate;
}

.download-btn {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  height: 5px;
  line-height: 5px;
  color: #ffffff;
  width: 100px;
  font-size: 16px;
  font-weight: 100;
  padding: 15px;
  background-color: #0da9ef;
  border: solid #0da9ef 1px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.downloadRow {
  border: 0.2px solid #e1e7ec;
  background: #ffffff;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-perspective: 1px;
  perspective: 1px;
  border-radius: 5px;
  border-collapse: separate;
}

.seminar-btn {
  /* Rectangle 4: */
  background: #37a0e2;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 94.5px;
}

.tbTrip > table > tbody > tr > td {
  font-size: 18px;
  font-weight: bold;
  color: #606975;
}

.th-commission-dropdown {
  min-width: 155px;
  border-radius: 5px;
}

.th-shipping-dropdown {
  min-width: 160px;
  border-radius: 5px;
}

.th-commission-dropdown .icon-circle-check,
.th-shipping-dropdown .icon-circle-check {
  margin-bottom: 5px;
  padding-right: 3px;
}

/************ SWITCH ***********/

.switchButton {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  /* top: 10px; */
  margin-right: 15px;
}

.switchButton input {
  display: none;
}

.topbar .topbar-column:first-child > a:not(.social-button),
.topbar .topbar-column:first-child > span,
.topbar .topbar-column:first-child > p {
  margin-right: 15px !important;
}

/************ SWITCH BUTTON SMALL***********/

.switchButtonSmall {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 19px;
  /* top: 10px; */
  margin-right: 5px;
}

.switchButtonSmall input {
  display: none;
}

.sliderButtonSmall {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderButtonSmall:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderButtonSmall {
  background-color: #2196f3;
}

input:focus + .sliderButtonSmall {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .sliderButtonSmall:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.sliderButtonSmall.round {
  border-radius: 34px;
}

.sliderButtonSmall.round:before {
  border-radius: 50%;
}

input:checked + .sliderButtonSmall:after {
  font-family: feather;
  font-size: 17px;
  content: "";
  padding-left: initial;
  top: 2px;
  position: absolute;
  left: 9px;
  color: white;
}

.switch-confirm-container td {
  padding: 0px;
}

@media (max-width: 414px) {
  .switch-confirm-container {
    text-align: left;
    padding-left: 0px;
  }
  .switch-confirm-container .custom-control {
    padding-left: 0px !important;
  }
}

.sliderButton {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderButton:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderButton {
  background-color: #2196f3;
}

input:focus + .sliderButton {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .sliderButton:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.sliderButton.round {
  border-radius: 34px;
}

.sliderButton.round:before {
  border-radius: 50%;
}

/* input:checked+.sliderButton:after {
    font-family: feather;
    font-size: 17px;
    content: "\e116";
    padding-left: initial;
    top: 2px;
    position: absolute;
    left: 9px;
    color: white;
} */

.dropdown-button .btn-primary:hover {
  background-color: #0da9ef;
  border-color: #e1e7ec;
}

.dropdown-button {
  background-color: #f5f5f5;
  border-color: #e1e7ec;
}

p.limit_age {
  padding-top: 40px;
  padding-left: 8px;
}

.confirm-box p {
  min-height: 85px;
}

@media (max-width: 576px) {
  p.limit_age {
    padding-top: 0;
  }
  .confirm-box p {
    min-height: auto;
  }
}

@media (min-width: 577px) {
  .border-sx-right {
    border-right: 1px solid #dee2e6;
  }
}

.cart-button {
  align-items: center;
  display: flex;
}

.thMonth {
  background-color: #838a92 !important;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:focus {
  box-shadow: none;
}

.btn-danger:not(:disabled):not(.disabled):active {
  border-color: #bd2230;
}

.checkout-page.page-title {
  margin-bottom: 0;
}

.checkout-page.page-title .column:first-child {
  padding-right: 0;
}

.alert-primary {
  color: #606975;
}

.alert-primary span {
  cursor: pointer;
}

#frmPaymentShopping .form-control {
  font-size: 13px;
}

.expiry-date .dropdown-menu {
  min-width: auto;
  text-align: center;
  width: 87%;
}

.expiry-date .btn-outline-primary {
  width: 100%;
  text-align: center;
  font-weight: normal;
}

.expiry-date .btn-outline-primary {
  color: #606975;
  border: 1px solid #dbe2e8;
}

.expiry-date .btn-outline-primary:not(:disabled):not(.disabled):active,
.expiry-date .btn-outline-primary:hover {
  color: #606975;
  background-color: rgb(255, 255, 255);
  border-color: #dbe2e8;
}

.expiry-date .btn-outline-primary:focus {
  box-shadow: none;
}

.expiry-date .pills {
  margin-top: 0;
}

.expiry-date .month .pills {
  margin-left: 0;
}

/*** OTP ***/

.request-otp-link-disabled {
  border: none;
  background: none;
}

.request-otp-link-enabled {
  border: none;
  color: #0097fa;
  text-decoration: underline;
  cursor: pointer;
  background: none;
}

.resend-otp-done {
  color: #0097fa;
}

.pay-bankwire {
  width: 600px;
  margin: 0 auto;
}

.maxWidth100 {
  max-width: 100% !important;
}

.posRelative {
  position: relative;
}

.bottomMargin16 {
  margin: 0px 0px 16px 0px;
  right: 0;
}

.timeGeneogy {
  text-align: center;
  padding-bottom: 20px !important;
  font-weight: bold;
}

.iconGeneogy {
  text-align: center !important;
}

.btn-show-all-less {
  height: 32px;
  padding: 0px 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.bordershow-all-less {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.btnF {
  float: none !important;
}

.tableSe {
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px solid #dddddd;
}

.tableSe:nth-last-child(1) {
  border-bottom: none;
}

.expand-iconGeo {
  font-size: 15px;
  padding-right: 10px;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
  .iconGeneogy {
    text-align: right !important;
  }
  .timeGeneogy {
    text-align: left;
    padding-bottom: 0px;
    font-weight: normal;
  }
  .tableSe {
    padding-bottom: 5px;
    padding-top: 5px;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  /*.tableSe:nth-child(1){
        border-top: none;
    }
    .tableSe:nth-child(2){
        border-top: none;
    }*/
  .tableSe:nth-last-child(2n) {
    border-right: none !important;
  }
  .tableSe:nth-last-child(2n + 1) {
    border-right: none !important;
  }
  .tableSe:nth-last-child(2) {
    border-bottom: none !important;
  }
}

/* // Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .timeGeneogy {
    text-align: left;
    padding-bottom: 0px;
    font-weight: normal;
  }
  .iconGeneogy {
    text-align: right !important;
  }
}

.fa-4 {
  font-size: 1.2em;
}

.embed-shadow {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

.embed-shadow :hover {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0), 2px 5px 0 rgba(0, 0, 0, 0) !important;
  border-radius: 10px !important;
}

.bar:hover {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

.embed-responsive .embed-responsive-16by9 .embed-shadow :hover {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

.shadow-em {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
}

/* .user-info-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: -1px;
    padding-top: 90px;
    padding-bottom: 0px;
    border: 1px solid #e1e7ec !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
} */

.MenuCard {
  /* border: 1px solid #CCC; */
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  box-shadow: 0 1px 8px 0 rgba(56, 56, 56, 0.2);
}

.logout-b {
  position: relative;
  width: 100%;
  height: 40px;
  justify-content: center;
  text-align: left;
  cursor: pointer;
  border-top: 1px solid #ccc !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px !important;
  border-bottom: 0px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.logout-b:first-child {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 0px 5px;
  justify-content: center;
  text-align: left;
  cursor: pointer;
  border-top: 1px solid #ccc !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom: 1px solid #ccc;
}

.logout-b.active {
  /* border-color: #0da9ef; */
  background-color: #0da9ef;
  color: #fff;
  pointer-events: none;
  border-top-left-radius: 5px !important;
  border: 1px solid #ccc !important;
  border-top-right-radius: 5px !important;
}

.logout-b:nth-last-child(odd) {
  position: relative;
  width: 100%;
  height: 40px;
  justify-content: center;
  text-align: left;
  padding: 0px 5px;
  cursor: pointer;
  border-top: 0px solid #ccc !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px !important;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.logout-b.active:last-child {
  /* border-color: #0da9ef; */
  background-color: #0da9ef;
  color: #fff;
  pointer-events: none;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

@media only screen and (min-width: 750px) and (max-width: 980px) {
  .seminar-side-tabs {
    height: 60px !important;
  }
  .logout-b:nth-last-child(odd) {
    position: relative;
    width: 100%;
    height: 60px;
    padding: 0px 5px;
    justify-content: center;
    text-align: left;
    cursor: pointer;
    border-top: 0px solid #ccc !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px !important;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
}

.sp-desk {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.menu-child .logout-b:nth-last-child(odd) {
  position: relative;
  width: 100%;
  height: 40px;
  justify-content: center;
  text-align: left;
  cursor: pointer;
  border-top: 0px solid #ccc !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px !important;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  display: none !important;
}

.sem-hide {
  display: none !important;
}

.tbTrip-table {
  border: 0px solid #e1e7ec !important;
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  -webkit-perspective: 1px;
  perspective: 1px;
  border-radius: 5px;
  border-collapse: separate;
}

.cart-img-shopprofile {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.logout-n:first-child {
  border-top: 0px solid #e1e7ec !important;
}

.join-date-group > div {
  display: inline;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: block !important;
  height: 0;
  outline: 0;
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-grabbing {
  cursor: grabbing !important;
}

.cursor-wait {
  cursor: wait !important;
}

.radio-btn-c {
  margin: 0 auto !important;
  padding-top: 20px;
}

/* .nav-link {
    display: block;
    padding: 0px !important;
  } */

.btn .btn-outline-primary .dropdown-menu {
  border-color: #e1e7ec;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2);
}

.btn-outline-primary {
  border-color: #0da9ef !important;
  background-color: #fff !important;
  color: #0da9ef !important;
}

.btn-outline-primary:hover {
  border-color: #0da9ef !important;
  background-color: #0da9ef !important;
  color: #fff !important;
}

.btn-outline-danger {
  border-color: #ff5252 !important;
  background-color: transparent;
  color: #ff5252 !important;
}

.btn-outline-danger:hover {
  background-color: #ff5252 !important;
  color: #ffffff !important;
}

.nav-link {
  display: block;
  padding: 0px !important;
}

.nav-item {
  margin-right: 12px;
}

.nav-tabs .nav-link {
  border: 0px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin-bottom: 10px;
}

/* .icon-circle-check {
    content: "" !important;
  } */

span .icon-circle-check {
  content: "\e042" !important;
}

.dropdown-menu {
  margin: 0.1rem !important;
  border-color: #e1e7ec !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2) !important;
  width: 96%;
}

select option {
  margin: 0.4rem !important;
  border-color: #e1e7ec !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2) !important;
}

.scrollable {
  overflow: auto;
  white-space: nowrap;
  margin: 0 -15px;
}

.icon-circle-check {
  display: none !important;
}

.active .icon-circle-check {
  display: inline-block !important;
}

.btn-group {
  width: 100%;
}

.dropdown-menu {
  border-color: #e1e7ec;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2);
  width: auto;
}

.dropdown-menu-g {
  border-color: #e1e7ec;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2);
  width: 100% !important;
}

.dropdown-menu-g {
  border-color: #e1e7ec;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 7px 22px -5px rgba(47, 56, 68, 0.2);
}

.dropdown-menu-g .dropdown-item {
  padding-right: 20px;
  padding-left: 20px;
  transition: color 0.3s;
  color: #606975;
  text-decoration: none;
}

.dropdown-menu-g .dropdown-item:hover,
.dropdown-menu-g .dropdown-item.active,
.dropdown-menu-g .dropdown-item:focus,
.dropdown-menu-g .dropdown-item:active {
  background: 0;
}

.dropdown-menu-g .dropdown-item:hover {
  color: #0da9ef;
}

.dropdown-menu-g .dropdown-item.active {
  color: #0da9ef;
}

.dropdown-menu-g a.dropdown-item {
  font-weight: 500;
}

.dropdown-toggle::after {
  margin-top: 1px;
  margin-left: 0.3em;
  vertical-align: 0.2em;
}

.btn.dropdown-toggle::after {
  vertical-align: 0.2em;
  right: 14px;
  position: absolute;
  top: 17px;
  bottom: 0;
}

.pills .btn.dropdown-toggle {
  position: relative;
}

.show .dropdown-menu-g {
  -webkit-animation: dropdown-show 0.25s;
  animation: dropdown-show 0.25s;
}

.site-menu ul > li > a {
  padding: 0 15px !important;
  transition: color 0.3s;
  color: #606975;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.custom-nav-link {
  margin-bottom: 0px !important;
  height: 34px;
  padding: 4px 10px !important;
  border-radius: 0px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav li .show {
  background: #0097fa !important;
  color: white !important;
  padding: 0px 10px 0px 10px !important;
  border-radius: 5px !important;
}

.nav li .th-shipping-dropdown .show .btn {
  background: #0097fa !important;
  color: white !important;
  width: 230px !important;
  max-width: 100%;
  border-radius: 5px !important;
}

.selected {
  background: #0da9ef !important;
  color: white !important;
}

.nav.save-cart li.th-shipping-dropdown {
  width: 180px !important;
  font-size: 12px;
}

.th-shipping-dropdown {
  width: 179px !important;
  border-radius: 5px !important;
}

.th-shipping-dropdown-vn-default {
  width: 200px !important;
  border-radius: 5px !important;
}

.th-shipping-dropdown-vn {
  width: 260px !important;
  border-radius: 5px !important;
}

.btn-rank-slider {
  width: 100px !important;
  border-radius: 5px !important;
}

.btn-rank-slider-desktop {
  width: 80px !important;
  border-radius: 5px !important;
  margin-bottom: 2px;
}

@media (max-width: 768px) {
  .offcanvas-wrapper {
    min-height: 90vh;
  }
}

/* Build cart SG */

@media (min-width: 767px) {
  .form-control-sg {
    display: block;
    width: 50% !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 0 auto !important;
    border-radius: 20px;
  }
  .lsb-colum {
    width: 153px;
  }
}

@media (min-width: 991px) {
  .form-control-sg {
    display: block;
    width: 75% !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 0 auto !important;
    border-radius: 20px;
  }
}

@media (max-width: 767px) {
  .form-control-sg {
    display: block;
    width: 100% !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 0 auto !important;
    border-radius: 20px;
  }
}

.shopping-cart-footer.checkout-footer {
  padding: 0 22px !important;
}

/* @media (max-width: 414px) {
    .scrollable-position-m {
        margin: auto 0 !important;
        text-align: center;
    }
 } */

.react-datepicker {
  font-size: 1.3rem !important;
}

@media (max-width: 767px) {
  .bottom-click-button-copy-jpn {
    top: 0px !important;
    right: 50px !important;
  }
  .hidden-alert-lg {
    display: none !important;
  }
  .drp-m {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (min-width: 767px) {
  .bottom-click-button-copy-jpn {
    top: 0px !important;
  }
  .ref-aling {
    text-align: center !important;
  }
  .hidden-alert-xs {
    display: none !important;
  }
}

.btn-pricelevel-width {
  width: 100% !important;
  text-transform: capitalize;
}

#admin_cal_list {
  width: 100% !important;
}

.custom-map-card {
  border-radius: 0px 0px 7px 7px !important;
  border-top-width: 0px !important;
}

.cus-icon {
  font-family: FontAwesome !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.disable-selection {
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer */
  -khtml-user-select: none;
  /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none;
  /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none;
  /* Disable Android and iOS callouts*/
}

.fab {
  font-family: "FontAwesome" !important;
  font-weight: 900;
}

@media screen and (max-width: 480px) {
  .small-no-pad {
    padding: 0 10px 0 0;
  }
}

.height-250 {
  height: 250px !important;
}

.smaller-dropdown {
  height: 250px !important;
  overflow: scroll;
}

@media only screen and (min-width: 780px) and (max-width: 2560px) {
  .absolute-middle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.padding-left-8x {
  padding-left: 8px !important;
}

.checkbox-label-font-top {
  font-weight: bold !important;
  margin-top: -1px !important;
  padding-left: 10px !important;
}

.pills-full-width {
  width: "100%";
  text-align: "left";
}

.pills-minimum-height {
  width: "100%";
  text-align: "left";
}

.facebookMessenger {
  bottom: 45px;
  display: block;
  position: fixed;
  right: 32px;
  width: 44px;
  height: 44px;
  transition: bottom 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.3s, background-color 0.3s,
    border-color 0.3s;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  line-height: 39px;
  opacity: 1;
  z-index: 2000;
  text-align: center;
  text-decoration: none;
}

.delivery-method {
  margin: 0 auto !important;
}

#admin_cal_list-th {
  width: 90% !important;
}

.iframe-placeholder {
  background: url("https://www.huelva.es/portalCiudadano/portal/images/loading.gif") 0px 0px no-repeat;
  background-size: 10%;
  background-position: center center;
  background-color: #000 !important;
}

/* MKUNG */

.quickenroll-page {
  margin-top: 30px;
  min-height: 50vh;
  margin-bottom: 30px;
}

.quickenrolltablepro {
  white-space: nowrap;
}

.btnSelect {
  height: 40px !important;
  line-height: 40px !important;
  cursor: pointer;
}

.qs-row-header {
  background-color: #ffffff;
  border-bottom-color: #ffffff;
}

.qeCard {
  margin-bottom: 8px !important;
  margin-left: 0px !important;
  min-height: 100px;
}

.qecollapse {
  margin-top: 20px;
}

@media (min-width: 480px) {
  .qeCard {
    min-height: 85px !important;
  }
  .qecollapse {
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  .qeCard {
    min-height: 65px !important;
  }
}

.qecollapse {
  border-top: 1px solid #e1e7ec;
}

.qe-title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0px;
}

.qenroll-summary {
  border-top: 1px solid rgb(225, 231, 236);
  padding-top: 20px;
}

.qenroll-summary {
  line-height: 1.8;
}

.qenroll-summary h5 {
  padding-left: 5px;
}

.qenroll-summary table {
  width: auto;
}

.qenroll-summary td {
  vertical-align: top;
  font-size: inherit;
  padding: 0px 0 0 5px;
}

.qenroll-summary tr td:first-child {
  white-space: nowrap;
}

.exsIMG {
  margin-bottom: 30px;
}

.signature-wrapper {
  position: relative;
  min-height: 170px;
}

.signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  width: 550px;
  border: 1px solid #eeeeee;
}

.failedPage {
  margin-bottom: 45px;
}

/* END MKUNG */

.accordion .quickenroll[data-toggle="collapse"]::after {
  content: none !important;
  border: none !important;
  display: none;
}

.accordion .quickenroll[data-toggle="collapse"].collapsed::after {
  content: none !important;
  border: none !important;
  display: none;
}

.quickenroll-arrow-parent {
  position: relative;
  color: rgb(220, 220, 220);
  cursor: pointer;
  font-size: 25px;
  margin: 0px;
  padding: 0px;
  margin-left: -10px;
  margin-right: 10px;
}

.quickenroll-arrow-child {
  position: absolute;
  top: 75%;
  left: 50%;
  margin: -15% 0 0 -25%;
  height: 30%;
  width: 50%;
}

.quickenroll p {
  margin-top: 0;
}

.quickenroll-button {
  position: relative;
  top: 0%;
  left: 0%;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .quickenroll-button {
    position: relative;
    top: 25%;
    left: 0%;
    width: 100%;
  }
}

/* UNIPOWER PAGE */

.unipower-container {
  text-align: center;
  margin: 0 auto;
}

/* GENEALOGY NEW TABLE */

.td-in-table {
  vertical-align: middle !important;
  max-width: 100px;
  overflow: hidden;
  height: 50px;
  border-bottom: 1px solid #e1e7ec;
  border-top: 0px !important;
  width: 70px;
  text-align: center;
}

.table-genealogy {
  table-layout: fixed;
}

.table-genealogy td {
  overflow-y: hidden;
  overflow-x: scroll;
}

.table-genealogy > tbody > tr > td:first-child {
  width: 30%;
  overflow: hidden;
}

.table-genealogy > tbody > tr > td:last-child {
  width: 70%;
  overflow: hidden;
}

.table-genealogy {
  margin-bottom: 0 !important;
}

.table-genealogy th {
  border-bottom: 0px !important;
}

.table-genelogy-ba-column,
.table-genelogy-ba-column td,
.table-genelogy-point-column,
.table-genelogy-point-column td,
.table-genealogy td {
  margin-bottom: 0 !important;
}

.table-genealogy-balist {
  max-width: 100%;
  overflow: scroll hidden;
}

.table-genealogy-balist > table {
  width: 500%;
}

.table-genealogy-pointlist {
  max-width: 100%;
  overflow: scroll hidden;
}

.table-genealogy-pointlist > table {
  width: auto !important;
}

.table-genealogy-month-period {
  background: #000;
  color: white;
  border: 0px !important;
  vertical-align: middle !important;
  text-align: center;
}

.no-border {
  border: 0 !important;
}

.no-border td {
  border-left: 0 !important;
  border-right: 0 !important;
}

.no-border-top {
  border-top: 0px !important;
}

.table-genealogy-period-header {
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  height: 30px;
}

.table-genealogy-ba-name span:hover {
  cursor: pointer;
  color: rgb(42, 184, 245);
}

.table-genealogy-ba-name span {
  vertical-align: middle;
  padding-right: 20px;
  padding-left: 10px;
}

.table-genealogy-balist-inner {
  margin-bottom: 0px;
}

.table-arrow {
  font-size: 30px;
}

.show-detail {
  display: inline-block;
  font-size: 14px;
  color: red;
}

.table-layout-fixed {
  table-layout: fixed !important;
}

.table-layout-auto {
  table-layout: auto !important;
}

.table-genealogy-period-header-vn {
  text-align: center;
}

@media only screen and (max-device-width: 1024px) and (max-device-height: 800px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .table-genealogy > tbody > tr > td:first-child {
    width: 250px;
    max-width: 250px;
  }
  .table-genealogy-period-header {
    width: 65px !important;
  }
  .table-genealogy-period-header-vn:first-child {
    width: 80px !important;
    text-align: center;
  }
}

@media only screen and (max-device-width: 823px) and (max-device-height: 375px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .table-genealogy > tbody > tr > td:first-child {
    width: 250px;
    max-width: 250px;
  }
  .table-genealogy-period-header {
    width: 65px !important;
  }
  .table-genealogy-period-header-vn:first-child {
    width: 90px !important;
  }
}

@media only screen and (max-device-width: 667px) and (max-device-height: 375px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .table-genealogy > tbody > tr > td:first-child {
    width: 200px !important;
    max-width: 200px !important;
  }
  .table-genealogy-period-header {
    width: 50px !important;
  }
}

@media only screen and (max-device-width: 568px) and (max-device-height: 320px) {
  .table-genealogy > tbody > tr > td:first-child {
    width: 150px !important;
    max-width: 150px !important;
  }
  .table-genealogy > tbody > tr > td:last-child {
    width: 75%;
  }
  .table-genealogy-month-period {
    width: auto !important;
  }
}

@media only screen and (max-width: 823px) {
  .table-genealogy > tbody > tr > td:last-child {
    overflow: auto;
    max-width: 1000px;
  }
  .table-genealogy-period-header {
    width: 60px !important;
  }
  .table-genealogy-period-header-vn {
    width: 75px !important;
  }
  .table-genealogy-month-period {
    width: 250px;
  }
}

@media only screen and (max-width: 812px) {
  .table-genelogy-point-column {
    table-layout: fixed;
  }
  .table-genealogy > tbody > tr > td:last-child {
    overflow: auto;
    max-width: 1000px;
  }
  .table-genealogy-period-header {
    width: 70px !important;
  }
  .table-genealogy-period-header-vn {
    width: 75px !important;
  }
  .table-genealogy-month-period {
    width: 250px;
  }
}

@media only screen and (max-width: 568px) {
  .table-genealogy-balist {
    width: 400px;
  }
  .table-genelogy-ba-column {
    width: 174px;
  }
  .lsb-colum {
    width: 130px !important;
  }
}

@media only screen and (max-width: 414px) {
  .table-genealogy-balist {
    width: 300px;
  }
  .table-genelogy-ba-column i {
    margin-right: 20px;
  }
  .show-detail {
    float: left;
  }
}

@media only screen and (max-width: 375px) {
  .font-size-genealogy-screen {
    font-size: 2vh;
    width: max-content;
  }
  .table-genelogy-ba-column {
    width: 120px;
  }
  .table-genelogy-ba-column i {
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 320px) {
  .font-size-genealogy-screen {
    font-size: 2.5vh;
    width: max-content;
  }
  .table-genelogy-ba-column {
    width: 100px;
  }
}

.widget ul a {
  margin-bottom: 12px;
  text-decoration: none !important;
}

.item-left a {
  text-decoration: none !important;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle !important;
  border-top: 1px solid #dee2e6;
}

.shopprofile-ta .form-control-number {
  padding: 0;
}

.table-bttom-border-50 {
  height: 50px;
  border-bottom: 1px solid rgb(225, 231, 236);
}

.table-hover-pointer:hover tbody tr {
  cursor: pointer;
}

.padding-top-none {
  padding-top: 0px !important;
}

.min-width-240 {
  min-width: 240px;
  width: 240px;
}

.custom-pill-height {
  max-height: 220px;
  overflow-y: auto;
}

.custom-pill-item {
  position: "absolute";
  transform: "translate3d(-100px, 20px, 0px)";
  will-change: "transform";
}

.login-logo {
  margin: 30px;
  height: 60px;
}

.success-icon {
  font-size: 25px;
  color: green;
}

.failure-icon {
  font-size: 25px;
  color: red;
}

.disable-icon {
  font-size: 25px;
  color: gray;
}

.min-width-100 {
  min-width: 100px;
}

.text-no-wrap {
  white-space: nowrap;
}

.inline-button-icon {
  margin-top: -4px !important;
  margin-right: 5px;
}

.margin-none-auto {
  margin: 0 auto;
}

.padding-none {
  padding: 0px !important;
}

.margin-none {
  margin: 0px !important;
}

.link-button {
  color: #0da9ef;
  text-decoration: underline;
  text-transform: none;
  padding-left: 0;
  border: none;
}

.product-thumb .product-img {
  height: 100px !important;
  width: auto !important;
  margin: 0px auto;
}

.form-inline > * {
  margin: 5px 3px;
}

.primary-button-min-120 {
  min-width: 140px;
}

.custom-btn {
  height: 36px;
  border-radius: 6px;
  padding: 0 18px !important;
  font-size: 14px;
  line-height: 36px;
}

.btn.dropdown-toggle::after {
  right: 10px;
  top: 15px;
}

/* .form-control:not(textarea) {
  height: 36px;
  border-radius: 10px;
} */

.custom-dropdown-btn {
  border: 1px solid #dbe2e8 !important;
  color: #606975 !important;
  text-align: left;
  font-size: 14px !important;
  line-height: 1.5;
  text-transform: none;
  letter-spacing: unset;
  font-weight: 100;
}

.custom-dropdown-btn:hover {
  background-color: transparent !important;
  border: 1px solid #dbe2e8 !important;
  color: #606975 !important;
}

/* .form-control:not(textarea) {
  height: 44px;
} */

/* The switch - the box around the slider */

.c-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
}

/* Hide default HTML checkbox */

.c-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */

.c-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.c-slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .c-slider {
  background-color: #2196f3;
}

input:focus + .c-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .c-slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */

.c-slider.round {
  border-radius: 23px;
}

.c-slider.round:before {
  border-radius: 50%;
}

/* 
.form-control:not(textarea) {
  height: 44px !important;
} */

.react-datepicker__time-container {
  width: 85px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
}

.form-group > #newsFormMenuType {
  position: relative !important;
}

.custom-file-label,
.custom-file-label::after {
  border-radius: 10px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-minus-20 {
  margin-left: -20px !important;
}

.transparent-custom-btn {
  border: none;
  margin: 0px;
  padding: 0px;
  height: 30px;
  line-height: 30px;
}

.radio-button-align-container {
  justify-content: space-between;
}

.browsed-img-container {
  max-width: 250px;
}

.browsed-img-container > img {
  max-height: 300px;
  min-height: 300px;
}

.custom-dropdown-item,
.custom-dropdown-item-sm {
  width: 100% !important;
  transform: translate3d(0px, 44px, 0px) !important;
  max-height: 300px !important;
  overflow: auto !important;
}

.custom-dropdown-item-sm {
  top: -15px !important;
}

.custom-dropdown-toggle-sm {
  top: -8px !important;
}

.custom-dropdown-toggle-sm::after {
  margin-top: -2px !important;
}

.custom-dropdown-toggle-md {
  top: 0px !important;
}

.custom-dropdown-toggle-md::after {
  margin-top: 0px !important;
}

.drop-down-toggle {
  border-radius: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-bottom: 3px;
}

.btn.dropdown-toggle::after {
  top: 19px !important;
}

.form-control {
  border-radius: 22px;
}

.custom-color-picker {
  position: absolute;
  z-index: 222;
  -webkit-box-shadow: 10px 10px 6px -6px #777;
  -moz-box-shadow: 10px 10px 6px -6px #777;
  box-shadow: 10px 10px 6px -6px #777;
}

.custom-color-picker-btn {
  margin: 0px !important;
  height: 44px !important;
}

/* toggle button css */

.switch-container {
  position: absolute;
}

input[type="checkbox"].switch {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"].switch + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 999px;
  background-color: grey;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type="checkbox"].switch + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child(1) {
  margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child(2) {
  margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
  width: 60px;
  background-position: 0 0;
  background-color: #3b89ec;
}

input[type="checkbox"].switch + div {
  width: 60px;
  height: 30px;
}

input[type="checkbox"].switch:checked + div {
  background-color: #0da9ef;
}

input[type="checkbox"].switch + div > div {
  float: left;
  width: 28px;
  height: 28px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 1px;
  margin-left: 1px;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: #ffffff;
}

input[type="checkbox"].bigswitch.switch + div > div {
  width: 44px;
  height: 44px;
  margin-top: 1px;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(30px, 0, 0);
  transform: translate3d(30px, 0, 0);
}

.custom-notification-popover {
  width: 500px;
  height: auto;
  min-height: 300px;
}

.dropdown-error {
  border-color: red !important;
}

.red-border {
  border-color: red !important;
}

.border-success {
  border-color: #28a745 !important;
}

.form-control.alert,
.form-control:disabled {
  border-radius: 10px !important;
}

.width-100 {
  width: 100%;
}

.accordion [data-toggle="collapse"]::after {
  right: 20px;
}

.dropdown-button-center {
  height: 36px;
  align-self: center;
  line-height: 36px;
}

.dropdown-button-center.btn.dropdown-toggle::after {
  top: 16px !important;
}

.padding-right-12 {
  padding-right: 12px;
}

.border-radius-6 {
  border-radius: 6px;
}

.padding-left-right-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.height-36 {
  height: 36px !important;
}

button {
  border-radius: 22px !important;
}

.btn-circle {
  width: 30px;
  height: 30px;
  border-radius: 15px !important;
  text-align: center;
  line-height: 1.42857;
}

.btn-circle-icon {
  font-size: 12px;
  padding: 0;
}

.btn-circle-color {
  font-size: 14px;
}

.widget-categories ul > li.has-children.expanded > ul,
.widget-links ul > li.has-children.expanded > ul {
  max-height: 9999px;
}

.hover-primary:hover {
  color: #0da9ef !important;
  cursor: pointer !important;
}

.arrow-bullet::before {
  font-family: FontAwesome;
  content: "\f0dd";
  color: #606975;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.arrow-bullet-right::before {
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Safari 3-8 */
  transition: transform 0.2s linear;
}

.arrow-bullet-down::before {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Safari 3-8 */
  transition: transform 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Safari 3-8 */
}

.custom-control-sm {
  height: 36px !important;
}

.unishop {
  font-family: Maven Pro, Helvetica, Arial, sans-serif;
  position: relative;
}

.unishop-h1 {
  font-size: 16px;
  color: #9da9b9 !important;
  text-transform: uppercase;
}

.unishop-h2 {
  font-weight: 500;
  font-size: 16px;
  color: #606975 !important;
}

.unishop-h3 {
  font-weight: 500;
  font-size: 14px;
  color: #606975 !important;
}

.grayscale-image {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.badge-btn {
  cursor: pointer;
  display: inline-block;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;

  padding: 0.2em 0.6em 0.3em 0.6em;
  border-radius: 10rem;
}

.badge-btn:hover {
  -webkit-filter: contrast(200%);
  filter: contrast(200%);
}

.badge-btn-primary {
  color: #ffffff !important;
  background-color: #0da9ef !important;
}

.badge-btn-secondary {
  color: #606975 !important;
  background-color: #f5f5f5 !important;
  border-color: #e1e7ec9f !important;
}

.badge-btn-info {
  color: #ffffff !important;
  background-color: #50c6e9 !important;
}

.badge-btn-danger {
  color: #ffffff !important;
  background-color: #ff5252 !important;
}

.position-center {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.position-vertical-center {
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.wrapping-text-ellipsis {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.overlay-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.text-normal {
  color: "#aaaaaa";
}

.text-status-active {
  color: #28a745;
}

.text-status-inactive {
  color: #aaaaaa;
}

.border-top-radius-1 {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.symbol-ban-1 {
  color: gray;
  opacity: 0.6;
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}

.symbol-ban-2 {
  color: #fff;
  opacity: 0.6;
  text-shadow: -1px 0 gray, 0 1px gray, 1px 0 gray, 0 -1px gray;
}

.font-outline-primary {
  color: #fff !important;
  text-shadow: -1px 0 #0da9ef, 0 1px #0da9ef, 1px 0 #0da9ef, 0 -1px #0da9ef;
}

.focus-outline-none:focus {
  outline: 0;
}

.text-decoration-none:hover {
  text-decoration: none !important;
}

.checkbox-md > label::before,
.checkbox-md > label::after {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.checkbox-xl > label::before,
.checkbox-xl > label::after {
  cursor: pointer;
  width: 36px;
  height: 36px;
}

.hover-brightness-75:hover {
  filter: brightness(.75);
}

.hover-saturate-2:hover {
  filter: saturate(2);
}

.hover-saturate-6:hover {
  filter: saturate(6);
}

.btn-outline-info > i {
  color: #50c6e9;
  transition: .5s all;
}

.btn-outline-info:hover > i {
  color: white;
}

.card-style-menu {
  padding: 24px;
  background: white;
  border: 1px solid;
  border-color: rgb(225, 231, 236);
}

.input-changed,
.input-changed:focus {
  box-shadow: 0 0 10px #43d9a3 !important;
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  
  border-top: 20px solid #f00;
}

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  
  border-left: 60px solid green;
}

.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; 
  
  border-right:10px solid blue; 
}

.custom-date-picker-product-v2 .mbsc-ios.mbsc-datepicker-inline {
  border-top: unset;
  border-bottom: unset;
}
.custom-date-picker-product-v2 .mbsc-ios.mbsc-range-control-wrapper .mbsc-segmented {
  margin-left: 0px;
}

.custom-date-picker-product-v2 .mbsc-datepicker-tab-wrapper {
  justify-content: flex-start;
}
.switch-cart-qty .ant-switch-checked {
  background-color: #1890ff;
}

.custom-scrollbar-1::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

.custom-scrollbar-1::-webkit-scrollbar
{
  width: 6px;
	background-color: #F5F5F5;
}

.custom-scrollbar-1::-webkit-scrollbar-thumb
{
	background-color: #B2BABB;
}

.modal-dialog-responsive-md {
  transition: all .5s;
  width: 100%;
}

.modal-dialog-responsive-xl {
  transition: all .5s;
  width: 100%;
}

@media (min-width: 768px) { 
  .modal-dialog-responsive-md {
    width: 75%;
  }

  .modal-dialog-responsive-xl {
    width: 75%;
  }
}
@media (min-width: 992px) { 
  .modal-dialog-responsive-md {
    width: 50%;
    min-width: 768px;
  }
}

.box-shadow-changed,
.box-shadow-changed:focus {
  box-shadow: 0 0 6px orange !important;
}

/*[BEGIN] custom-antd-table */
.custom-antd-table > thead > tr{
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
}

.custom-antd-table > thead > tr > th{
  font-size: 16px;
  font-weight: 500;
  color: #606975;
  padding-top: 10px;
  padding-right: 7px;
  padding-bottom: 10px;
  padding-left: 7px;
}

.custom-antd-table-header-alt > thead > tr > th {
  background-color: #1890FF;
  border: 1px solid #FFF;
  color: #FFF;
}

.custom-antd-table > tbody > tr{
  border-bottom: 1px solid #dee2e6;
}

.custom-antd-table > tbody > tr > td{
  font-size: 14px;
  font-weight: 500;
  color: #606975;
  padding-top: 12px;
  padding-right: 8px;
  padding-bottom: 12px;
  padding-left: 8px;
}


.menu-box.active {
  /* border-color: #0da9ef; */
  background-color: #0da9ef;
  color: #fff;
  pointer-events: none;
  border: 1px solid #ccc !important;
}

.menu-box {
  padding: 0px 5px;
  position: relative;
  width: 100%;
  height: 40px;
  justify-content: center;
  text-align: left;
  cursor: pointer;
  border-top: 1px solid #ccc !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px !important;
  border-bottom: 0px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.menu-box:last-child {
  border-bottom: 1px solid #ccc !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px !important;
}

.menu-box:first-child {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

/*[END] custom-antd-table */