.pdpa-card {
    border: 1px solid rgb(184, 184, 184);
    // width: 150px;
    // margin: 0px 8px 16px 8px;
    // // border: 1px solid #e1e7ec;
    // border-radius: 8px;
}

.pdpa {
    .ant-btn-upload-excel {
        background-color:rgb(236, 165, 43) ;
        color: white;
    }
    .ant-btn-upload-excel:disabled {
        background-color: rgb(245, 245, 245);
        color: rgb(184, 184, 184);
    }
}