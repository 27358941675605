div[class="calendar visible"],
div[class="calendar"] {
  margin-top: 30px;
}

.date-range-picker {
}

.date-range-picker-placeholder {
  color: #606975;
  font-size: 16px;
  font-weight: lighter;
}

.date-range-picker-placeholder > span[class="label"] {
  border-bottom: 2px solid transparent;
}

.date-range-picker-placeholder:hover > span[class="label"] {
  border-bottom: 2px solid #606975;
}

.date-range-picker-placeholder > span[class="value"] {
  border-bottom: 2px solid #0da9ef;
  color: #0da9ef;
}

.full-date-picker-container {
  /* margin-top: 11px; */
}
