.text-red-500 {
    color: red;
}

.text-green-500 {
    color: green;
}

.text-blue-500 {
    color: blue;
}

