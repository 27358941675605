.table-short-url {
    .ant-table-footer {
        padding: 0px 11px 0px 0px;
    }

    .btn-delete-url {
        display: flex;
        justify-content: flex-end;
    }
}
