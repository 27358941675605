.multi-progress-bar {
    width: 60%;
}

@media (min-width: 768px) {
    .multi-progress-bar {
        width: 50%;
    }
}

@media (min-width: 992px) {
    .multi-progress-bar {
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .multi-progress-bar {
        width: 30%;
    }
}
