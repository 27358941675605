.dict-card {
  width: 390px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 7px;
  background-color: white;
}

.dict-input-changed,
.dict-input-changed:focus {
  box-shadow: 0 0 10px #43d9a3 !important;
}
