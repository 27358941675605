.ddl-noborder-toggle {
  border-radius: 0px !important;
  margin: 0;
  padding-left: 10px;
  padding-right: 22px;
}

.ddl-noborder-toggle::after {
  display: none;
}

.ddl-noborder-toggle:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.ddl-noborder-toggle-display {
  color: #606975;
  font-size: 16px;
  font-weight: lighter;
}

.ddl-noborder-toggle-display > span[class="label"] {
  border-bottom: 2px solid transparent;
}

.ddl-noborder-toggle-display:hover > span[class="label"] {
  border-bottom: 2px solid #606975;
}

.ddl-noborder-toggle-display > span[class="value"] {
  border-bottom: 2px solid #0da9ef;
  color: #0da9ef;
}

.ddl-noborder-menu {
  width: 100% !important;
  top: -5px !important;
  left: 9px !important;
}
