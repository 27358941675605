.img-showcase > .slide,
.img-showcase > .slide::before {
    height: 0px;
}

.img-showcase-thumbnails-container {
    overflow: auto;
}

.img-showcase-item {
    cursor: pointer;
}

.img-showcase-item:hover > .img-showcase-barrier {
    opacity: 0.5;
}

.img-showcase-item:hover > .img-showcase-barrier > .img-showcase-item-icon-view {
    opacity: 0.5;
}

.img-showcase-barrier {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    transition: 0.5s all;
}

.img-showcase-item-icon-view {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 6em;
    opacity: 0;
    text-shadow: 0px 0px 20px #fff;
    transition: 0.5s all;
}
