.btn-to-top-container {
  position: fixed;
  bottom: 14px;
  right: 14px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 9999;
}

.btn-to-top-transition {
  opacity: 0.8;
}

.btn-to-top-container:focus {
  outline: transparent;
}
