.btn-action-seminar {
    margin-bottom: 15px;
    button {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.hr-info {
    border: 3px solid black;
    background: black;
    margin: 20px 0px;
}

.form-info {
    .badge-btn-danger {
        margin: 0px !important;
    }
}